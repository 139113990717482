.tipModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
}

.tipModalContainer {
    background-color: #f8f8f8;
    padding: 60px;
    border-radius: 28px;
}

.tipModalContainerCancelBtn {
    display: flex;
    justify-content: flex-end;
}

.tipModalContainerHeader {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    padding: 10px;
}

.tipModalContainerDescription {
    font-weight: 500;
    font-size: 23px;
    padding: 10px;
}

.tipModalOptionsContainer {
    padding: 10px;
    display: flex;
    padding-top: 30px;
}

.tipModalOption {
    background: #6084ff;
    box-shadow: 0px 4px 150px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    filter: drop-shadow(0px 4px 100px rgba(0, 0, 0, 0.16));
    width: 168px;
    height: 168px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tipModalOption + .tipModalOption {
    margin-left: 30px;
}

.tipModalOption-percentage {
    font-size: 48px;
    color: #fff;
    font-weight: 700;
}

.tipModalOption-amount {
    color: #fff;
    margin-top: 10px;
    opacity: 0.8;
}
