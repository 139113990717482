.tableSelectContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.tableModalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.tableSelectWelcomeHeader {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #20b04b;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.tableSelectWelcomeDescription {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #454545;
    margin: 10px;
    max-width: 60%;
}

.tableSelectInputContainer {
    width: 160px;
    box-shadow: 0px 6.32386px 20.7784px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-top: 20px;
}

.tableSelectInputContainerSafari {
    transform: scale(0.5);
    -webkit-transform: scale(0.5, 0.5);
}

.tableSelectInputHeader {
    background: #20b04b;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    color: #fff;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 33px;
}

.TableSelectInputFieldContainer {
    background-color: #fff;
    padding: 20px;
    display: flex;
    justify-content: center;
    border-radius: 0px 0px 10px 10px;
}

.tableSelectInputField {
    border: 0;
    border-bottom: 1.22685px solid #bfbfbf;
    width: 108px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 72px;
    line-height: 116px;
    letter-spacing: -0.03em;
    color: #1c4055;
    -webkit-appearance: none;
}

.tableSelectNextButton {
    background: #1c4055;
    width: calc(160px - 40px);
    border-radius: 30px;
    padding: 20px;
    display: flex;
    justify-content: center;
    font-family: Poppins;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
}

.tableSelectNextButtonDisabled {
    background-color: #d8d8d8;
    transition: 0.3s ease-in-out;
}
