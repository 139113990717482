.orderDetailsTrigger {
    margin: 30px;
    color: #333;
    cursor: pointer;
    transition: 0.3s ease;
    font-size: 12px;
    font-weight: 500;
}

.orderDetailsTrigger:hover {
}

.orderDetailsContainer {
    margin-top: 20px;
}

.discountCodeTextInput {
    padding: 10px;
    border: 1px solid #aaa;
    border-radius: 10px;
    font-family: 'Roboto Mono';
    width: 100px;
}

.discountCodeContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9001;
    background-color: #fff;
}

.enterDiscountCodeButton {
    display: flex;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 5px;
    padding: 10px;
    /* background-color: #469F5E; */
    /* box-shadow: 0 0 30px rgba(0,0,0,0.2); */
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    text-align: left;
    font-weight: 500;
    cursor: pointer;

    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease;
    font-size: 12px;
    font-weight: 500;
    padding: 10px;
    color: #555;
}

.discountCodePrompt {
    padding: 20px;
}

.discountCodeInput {
    width: calc(100% - 20px);
    background-color: #f7f7f7;
    border: 0;
    border-radius: 5px;
    border-bottom: 5px solid #dadada;
    font-size: 18px;
    font-family: 'Roboto Mono';
    padding: 10px;
    margin-bottom: 38px;
    text-align: center;
}

.discountCodeLabel {
    margin-top: 10px;
    margin-bottom: 10px;
}

.discountCodePromptHeader {
    font-size: 32px;
    font-weight: 600;
    margin-top: 50px;
}

.margin-zero {
    margin: 0;
}
