.guestSelectGroupContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.guestSelectGuestContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px;
    align-items: center;
}

.guestSelectEditTableBtn {
    /* background-color: #DBDBDB; */
    color: #103c54;
    padding: 5px;
    font-size: 11px;
    border-radius: 3px;
    display: inline-block;
    margin-left: 10px;
}

.guestSelectCircle {
    width: 96px;
    height: 96px;
    background-color: #dbdbdb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 36px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
}

.guestSelectName {
    margin-top: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
}

.addGuestModal {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #4f4f4f;
}

.addGuestModalContainer {
    min-width: 250px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
}

.addGuestInput {
    background: #ffffff;
    border: 1px solid #ececec;
    border-radius: 10px;
    width: 160px;
    text-align: center;
    padding: 5px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* identical to box height */

    color: #1c4055;
}
