.kiosk-upsell-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #00000099 0% 0% no-repeat padding-box;
}

.kiosk-upsell-modal {
    width: calc(100% - 10%);
    background: #f1f1f1 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 99px #00000029;
    border: 1px solid #707070;
    border-radius: 28px;
    margin-bottom: 200px;
}

.kiosk-upsell-inner-container {
    padding: 80px 40px 48px 40px;
}

.kiosk-upsell-modal-header {
    text-align: center;
    font: normal normal bold 36px/44px Montserrat;
    letter-spacing: 0px;
    color: #3d3d3d;
    margin-bottom: 60px;
}

.kiosk-upsell-items {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
}

.kiosk-upsell-individual-item {
    width: calc(33% - 15px);
    background-color: #fff;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 60px #00000029;
    border-radius: 10px;
}

.kiosk-upsell-ii-inner-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.kiosk-upsell-ii-brand-logo {
    width: 85px;
    height: 85px;
    object-fit: contain;
    border-radius: 50%;
    margin-bottom: 5px;
}

.kiosk-upsell-ii-name {
    text-align: center;
    font: normal normal bold 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #3d3d3d;
    margin-top: 15px;
    margin-bottom: 30px;
    height: 40px;
}

.kiosk-upsell-ii-price {
    font: normal normal bold 15px/19px Montserrat;
    letter-spacing: 0px;
    color: #7b7b7b;
}

.kiosk-upsell-ii-image {
    width: 160px;
    height: 160px;
    object-fit: contain;
}

.kiosk-upsell-ii-button {
    background: #6084ff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 100%;
    margin-top: 20px;

    text-align: center;
    font: normal normal bold 18px/22px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    padding-top: 15px;
    padding-bottom: 15px;
}

.kiosk-upsell-ii-button-dark {
    background: #232c4c 0% 0% no-repeat padding-box;
}

.kiosk-upsell-ii-button + .kiosk-upsell-ii-button {
    margin-top: 10px;
}

.kiosk-upsell-no-thanks {
    display: inline-block;
    margin-top: 40px;
    font: normal normal bold 26px/32px Montserrat;
    letter-spacing: 0px;
    color: #6084ff;
    border-bottom: 5px solid #6084ff;
}

.kiosk-upsell-no-thanks-container {
    display: flex;
    justify-content: center;
}

.kiosk-upsell-carousel {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.kiosk-upsell-chevron {
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #00000029;
    border-radius: 50px;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upsell-chevron-flipped {
    transform: rotate(180deg);
}

.upsell-chevron-grayed {
    opacity: 0;
}
