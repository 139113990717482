.button {
    color: #2d2d2d;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.5s ease;
}

.disabled {
    pointer-events: none;
    background-color: #eee;
}

.button:hover {
    cursor: pointer;
    background-color: '#ddd';
}

.button:active {
    background-color: '#eee';
}

.rightArrow {
    height: 7px;
}

.rightAlign {
    display: flex;
    flex-direction: row;
}

.priceText {
    margin-right: 10px;
    font-size: 17px;
}

.button3 {
    flex-grow: 1;
    /* Padding for interior items*/
    /* Border */
    /* border: 1px solid #DFDFDF;
    border-radius: 11px;
    box-sizing: border-box; */
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 13px;
    padding-bottom: 13px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    transition: 0.3s ease;
}

.button3:hover {
    background-color: #eee;
}

.button3 + .button3 {
    border-top: none;
}

.buttonText {
    font-family: 'Lato';
    font-size: 18px;
    font-weight: 600;
    color: #647577;
}

.buttonGroupContainer {
    width: 100%;
}

@media only screen and (min-width: 700px) {
    .buttonGroupContainer {
        width: auto;
    }
    .button {
        max-width: 700px;
        width: 700px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px 10px 0px 0px;
    }
    .button + .button {
        /* border: 1px solid #ccc; */
        border-radius: 0px 0px 0px 0px;
    }
    .button:last-of-type {
        border-radius: 0px 0px 10px 10px;
    }
    .button:last-of-type.button:first-of-type {
        border-radius: 10px 10px 10px 10px;
    }
    .button3 {
        border-radius: 10px 10px 0px 0px;
    }
    .button3 + .button3 {
        /* border: 1px solid #ccc; */
        border-radius: 0px 0px 0px 0px;
    }
    .button:last-of-type {
        border-radius: 0px 0px 10px 10px;
    }
    .button3:last-of-type.button3:first-of-type {
        border-radius: 10px 10px 10px 10px;
    }
}
