.ErrorBoundaryPage {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ErrorBoundaryContainer {
    max-width: 75vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ErrorOops {
    font-size: 35px;
    font-family: 'Roboto';
}

.RefreshButton {
    font-size: 18px;
    padding: 12px;
    font-weight: 400;
    border: 1.5px solid lightgrey;
    border-radius: 10px;
    margin-bottom: 15px;
    cursor: pointer;
}

.RefreshButton:hover {
    background-color: lightgrey;
    color: white;
}

.ErrorMessage {
    color: grey;
    font-size: 12px;
}

@media only screen and (min-width: 700px) {
    .ErrorOops {
        font-size: 60px;
        font-family: 'Roboto';
    }
}
