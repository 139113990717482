.foh-container {
    position: fixed;
    bottom: 60px;
    right: 0;
    z-index: 999999999;
    margin: 10px;
    padding: 15px;
    background-color: blue;
    color: #fff;
    border-radius: 50px;
}

.foh-container-expanded {
    position: fixed;
    z-index: 99999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    overflow-y: auto;
}
