.pom-container {
    position: sticky;
    bottom: env(safe-area-inset-bottom);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    position: fixed;
    bottom: 0;
    z-index: 999;
    height: 29px;
    width: 100%;
}

.pom-logo {
    height: 18px;
    padding-top: 3px;
    opacity: 1;
    width: 100%;
}

.pom-inset-bottom {
    position: fixed;
    bottom: 0;
    height: env(safe-area-inset-bottom);
    background-color: #fff;
}
