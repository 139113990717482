.campaignPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10vh;
}

.campaignListContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 50vw;
    width: 85vw;
    margin-bottom: 30px;
}

.campaignListContainer_first {
    border-top: 0;
}

.campaignListContainer_not_first {
    border-top: 2px solid grey;
}

.campaignListTitle {
    align-self: flex-start;
    text-align: left;
    margin: 25px 0;
    font-size: 13px;
}

h1 {
    margin: 0px;
    margin-bottom: 5px;
}

p {
    margin: 0px;
}

/* Tablet css */
@media only screen and (min-width: 700px) {
    .campaignListContainer {
        padding: 30px 0;
        width: 70vw;
        max-width: 850px;
    }

    .campaignListTitle {
        margin: 10px 0 20px 0;
        font-size: 17px;
    }
}
