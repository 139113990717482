.verifyPhoneNumContainer {
    padding: 20px;
}

.verifyPhoneNumberLabel {
    font-size: 18px;
    margin-top: 10px;
    margin-top: 10px;
}

.verifyPhoneNumberLabel + .verifyPhoneNumberLabel {
    margin-top: 20px;
}

.twoFA-button {
    background-color: #469f5e;
    margin-top: 20px;
    border-radius: 5px;
    padding: 15px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    min-height: 30px;
    max-height: 30px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease;

    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    margin-left: 20px;
    margin-right: 20px;
}

.twoFA-button-cancel {
    background-color: #db4c40;
}

.twoFA-button:hover {
    opacity: 0.8;
}

.phoneNumberStep2BackArrow {
    margin: 20px;
    width: 30px;
}

.phoneNumberBlue {
    color: #0066ff;
    font-weight: 600;
}

.twofa-loader,
.twofa-loader:after {
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.twofa-loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 5px solid rgba(255, 255, 255, 0.2);
    border-right: 5px solid rgba(255, 255, 255, 0.2);
    border-bottom: 5px solid rgba(255, 255, 255, 0.2);
    border-left: 5px solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

.twofa-loader__black {
    /* border-top: 5px solid rgba(255, 255, 255, 0.2);
  border-right: 5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  border-left: 5px solid rgba(0, 0, 0, 0.6); */

    border-top: 5px solid rgba(0, 0, 0, 0.6);
    border-right: 5px solid #ddd;
    border-bottom: 5px solid #ddd;
    border-left: 5px solid #ddd;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media only screen and (min-width: 700px) {
    .twoFAContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .twoFAFixedWidth {
        max-width: 700px;
        min-width: 700px;
    }
    .twoFA-button {
        margin-left: 0px;
        margin-right: 0px;
    }
}
