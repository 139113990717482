.topHeaderTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 20px;
    color: #050606;
    margin-left: 13px;
    padding-right: 10px;

    text-size-adjust: auto;
    width: 100%;
}

.v2HeaderGKLogo {
    max-width: 60vw;
    height: 100%;
}

@media only screen and (min-width: 768px) {
    .v2HeaderGKLogo {
        max-height: 30px;
    }
}
