.receipt-container {
    padding: 30px;
}

.rating-container {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.rating-control {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.address-container {
    font-family: 'Roboto';
    font-size: 13px;
    font-weight: 300;
    text-align: center;
    padding-top: 30px;
}

.rating-icons {
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.rating-icon {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    margin-right: 5px;
}

.rating-icon > a {
    color: inherit;
}

.receiptLogoContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.receiptLogo {
    display: block;
    height: 80px;
    width: calc(80%);
    margin: 10px;
    object-fit: contain;
    margin-bottom: 40px;
}

.receipt-powered-by {
    margin-top: 20px;
    font-weight: 400;
    font-size: 12px;
    color: #333;
    text-align: center;
}

@media (max-width: 640px) {
    .rating-control {
        flex-direction: column;
    }
}
