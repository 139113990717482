.v3ContactUsContainer {
    background-image: url('/assets/landing/v3/content-bg.svg');
    background-size: cover;
    background-position: 0% 0%;
    background-size: 100%;
}
.v3ContactHomeContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 8%;
    padding-right: 8%;
    width: auto;
    flex-shrink: 1;
}
.v3ContactUsSide {
    display: flex;
    flex-direction: column;
    width: auto;
    margin: auto;
}
.v3ContactUsTag {
    font-size: 48px;
    font-weight: 700;
    color: #ffffff;
    padding-bottom: 16px;
}
.v3ContactUsText {
    font-size: 18px;
    width: auto;
    max-width: 460px;
    font-weight: 400;
    color: #ffffff;
    font-family: 'Inter';
    padding-bottom: 48px;
}
.v3ContactImage {
    align-items: center;
    width: 90%;
}

.v3ContactUsForm {
    margin-left: 32px;
    display: flex;
    width: 48%;
    height: auto;
    border-radius: 15px;
    background-color: #ffffff;
}
.v3CompleteContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
}
.v3CompleteText {
    font-size: 24px;
    padding: 8px 12px;
    width: auto;
    text-align: center;
    font-weight: 500;
    color: #36b37e;
    font-family: 'Inter';
    padding-bottom: 54px;
}
.v3CompleteIcon {
    display: flex;
    align-items: center;
}
.v3ContactBrandsBackground {
    backdrop-filter: blur(25px) saturate(150%);
    background-color: rgba(255, 255, 255, 0.65);
}

.v3BrandsContainer {
    max-width: 1200px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}
.desktopHidden {
    display: none;
}

@media only screen and (max-width: 850px) {
    .v3ContactImage {
        display: none;
        align-items: center;
        width: 90%;
    }
    .v3ContactHomeContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 8%;
        padding-right: 8%;
        width: auto;
        flex-shrink: 1;
    }

    .v3ContactUsContainer {
        background-image: url('/assets/landing/v3/contact-bg-mobile.png');
    }
    .v3ContactUsForm {
        margin: auto;
        display: flex;
        width: auto;
        min-width: 300px;
        height: auto;
        border-radius: 15px;
        background-color: transparent;
    }
    .v3ContactUsTag {
        font-size: 34px;
        font-weight: 700;
        color: #ffffff;
        padding-bottom: 16px;
        text-align: center;
    }
    .v3ContactUsText {
        font-size: 16px;
        width: auto;
        margin: auto;
        max-width: 300px;
        text-align: center;
        font-weight: 400;
        padding-bottom: 0;
    }
    .v3CompleteIcon {
        display: flex;
        align-items: center;
        background: url('/assets/landing/v3/complete-icon-white.svg');
        height: auto;
    }
    .v3CompleteText {
        padding-top: 8px;
        font-size: 18px;
        width: auto;
        text-align: center;
        font-weight: 500;
        color: #fff;
        font-family: 'Inter';
        padding-bottom: 54px;
    }
    .v3CompleteContainer {
        height: auto;
        width: 300px;
    }
    .v3BrandsContainer {
        display: none;
    }
    .mobileHidden {
        display: none;
    }
}
