.loadingScreenTopContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.loadingScreenContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vh;
}
