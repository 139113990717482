.dataBlock {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.dataBlockHeader {
    font-weight: 700;
    color: #272727;
    font-size: 26px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.dataBlockMany {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.gk-restaurant-location-container {
    width: calc(100% - 40px);
    padding: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    color: #333;
}

.gk-restaurant-header {
    font-size: 22px;
}

.gk-restaurant-name {
    font-size: 28px;
    font-weight: 900;
}

.gk-restaurant-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;

    margin-top: 10px;
    cursor: pointer;
}

.gk-restaurant-info-item {
    display: inline-block;
    border-radius: 5px;
    text-align: left;
    font-size: 18px;
    color: rgb(58, 120, 212);
    line-height: 1.5em;
    letter-spacing: 0.03em;
    font-weight: 500;
    text-decoration: underline;
}
.gk-restaurant-info-item + .gk-restaurant-info-item {
    margin-top: 20px;
}

.dataBlockItemContainer {
    position: relative;
    display: inline-block;

    min-width: calc(50vw - 15px);
    width: calc(50vw - 15px);
    height: 150px;

    margin-bottom: 5px;
    cursor: pointer;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    border: 0;
}

.dataBlockImage {
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;

    border-radius: 10px;

    background-size: cover;
    background-position: 50% 50%;
    border: 0;
}

.dataBlockGradient {
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0px;
    border-radius: 5px;
    z-index: 2;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 20.96%,
        rgba(20, 26, 26, 0.5) 70%
    );
    border: 0;
}

.dataBlockMenuText {
    position: absolute;
    max-width: 100%;
    bottom: 10px;
    left: 0;
    padding: 10px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    z-index: 2;
}

.ghostKitchenLogoButton {
    cursor: pointer;
}

.ghostKitchenLogoButtonComingSoon {
    opacity: 0.5;
}

.ghostKitchenLogoButtonComingSoonText {
    padding: 20px;
    font-weight: 700;
    font-size: 36px;
    border-radius: 20px;
    z-index: 999;
    color: #52b16a;
    text-align: left;
    margin-top: 30px;
    width: calc(100vw - 40px);
}

.gkLogoContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
}

.gkImage {
    width: 106px;
    height: 106px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    margin: 5px;
}

.gkImageComingSoon {
    filter: saturate(0%);
}

.logoViewHeaderImage {
    position: absolute;
    height: 200px;
    width: 100%;
    object-fit: cover;
    background-size: cover;
    border: 0;
    outline: none;
}

.logoViewHeaderText {
    display: none;
}

.logoViewHeaderExpansion {
    border: 0;
    visibility: hidden;
    max-height: 200px;
    position: relative;
}

/* New Blur Design for dataBlock */

.dataBlockItemContainer {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
}

.dataBlockGradient {
    display: none;
}

.dataBlockMenuText {
    backdrop-filter: saturate(200%) blur(25px) brightness(90%) contrast(100%);
    border-radius: 10px;
    max-width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;

    left: auto;
    bottom: auto;
    bottom: 10px;
    left: 0;

    text-align: left;
}

.featuredCampaigns {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    padding: 0 10px;
}

/* Tablet css */
@media only screen and (min-width: 700px) {
    .logoViewDesktopContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .logoViewHeaderText {
        display: block;
        position: absolute;
        top: 180px;
        text-align: center;
        width: 100%;
        font-size: 48px;
        font-weight: 500;
        left: 0;
    }

    .textGlow {
        /* text-shadow: rgba(0,0,0,1) 0 0 35px; */
        display: inline-block;
        color: #fff;
        backdrop-filter: saturate(200%) blur(100px) brightness(100%)
            contrast(100%);
        border-radius: 30px;
        padding: 15px;
        padding-left: 23px;
        padding-right: 23px;
    }

    .logoViewHeaderImage {
        height: 300px;
        /* filter: brightness(50%); */
    }

    .logoViewHeaderExpansion {
        max-height: 300px;
    }

    .dataBlockMany {
        justify-content: center;
    }
    .dataBlockItemContainer {
        min-width: 200px;
        width: 200px;
        margin: 5px;
    }
    .gkLogoContainer {
        justify-content: flex-start;
    }
    .gkImage {
        width: 168px;
        height: 168px;
    }

    .dataBlockItemContainer {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
        margin: 13px;
        transition: 0.5s ease;
    }

    .dataBlockItemContainer:hover {
        box-shadow: 0px 0px 30px rgba(25, 25, 25, 0.8);
        margin: 13px;
    }

    .dataBlockGradient {
        display: none;
    }

    .dataBlockMenuText {
        backdrop-filter: saturate(200%) blur(25px) brightness(90%)
            contrast(100%);
        border-radius: 10px;
        max-width: calc(100% - 20px);
        margin-left: 10px;
        margin-right: 10px;
    }

    .dataBlock {
        display: flex;
        justify-content: center;
    }

    .dataBlockMany {
        max-width: 1200px;
    }
}
