a {
    text-decoration: none;
}

.landmark {
    width: 250px;

    border-radius: 5px;

    font-family: Helvetica, Arial, sans-serif;
    transform: translate(-67%, -75px);
}

.landmark h1 {
}

.landmark section {
}

.landmark section p {
}

.landmark:after {
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    margin-left: 50%;
    border-top: 20px solid #ffffff;
}

.mapContainer {
    flex-grow: 5;
}

.restaurantList {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    top: 0;
    left: 0;
    background-color: #fff;
    height: 100vh;
    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.29);
    overflow: auto;
    /* cursor: pointer; */
    align-items: center;
    min-width: 350px;
    max-width: 500px;
    width: 20%;
}

.restaurantList img {
    padding: 52px 0;
    max-width: 180px;
}

.restaurantList h1 {
    font-size: 23px;
    margin: 10px;
    margin-bottom: 0px;
    margin-top: 20px;
    font-weight: 600;
    color: #fff;
}

.restaurantList p {
    color: #555;
    font-size: 15px;
    margin-top: 0;
    font-weight: 500;
    margin-left: 10px;
    margin-bottom: 0;
    color: #20b04b;
}

.markerView .title {
    background-color: #238e44;
    border-radius: 8px;
    color: #ffffff;
    font-size: 14px;
    padding: 10px 15px;
    position: relative;
}

.markerView .title::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #238e44;
}

.markerView .details {
    display: none;
}

.markerView.highlight .details {
    display: block;
}

.markerView.highlight .title {
    display: none;
}

.restaurantItem {
    width: calc(100% - 60px);
    margin: 10px;
    background-color: #ffffff;
    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.restaurantItemAnnotation {
    margin: 0;
    width: 100%;
}

.restaurantItemAnnotation::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #ffffff;
}

.restaurantItem + .restaurantItem {
    margin-top: 0;
}

.restaurantItem h1 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #207e2b;
}

.restaurantItem section {
    font-size: 13px;
    color: #555;
    font-weight: 400;
    margin-top: 5px;
}

.restaurantItem:first-of-type {
    margin-top: 20px;
}

.restaurantLocationPickerContainer {
    display: flex;
    height: 100vh;
    width: 100%;
}

.restaurantLocationActionContainer {
    display: flex;
}

.restaurantLocationActionButton {
    width: calc(50% - 5px);
    text-align: center;
    margin-top: 10px;
    background-color: #f9f9f9;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    margin-right: 5px;
    color: #207e2b;
    font-weight: 500;
    padding: 5px;
    transition: 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}
.radiusList {
    position: relative;
    background-color: #ffffff;
    width: 100px;
    margin-left: 10px;
}

.restaurantLocationOrderButton {
    width: calc(50% - 5px);
    text-align: center;
    margin-top: 10px;
    background-color: #207e2b;
    border-radius: 5px;
    margin-right: 5px;
    color: #ffffff;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.restaurantLocationOrderButton2 {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    background-color: #207e2b;
    border-radius: 5px;
    margin-right: 5px;
    color: #ffffff;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: 0.2s ease-in-out;
    display: block;
    align-items: center;
    justify-content: center;
}

.restaurantLocationActionButton:hover {
    opacity: 0.6;
}

.restaurantLocationActionButton:last-of-type {
    margin-right: 0;
}

.restaurantSearchbarContainer {
    width: calc(100% - 30px);
    display: flex;
    position: relative;
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid rgb(200, 200, 200);
}

.restaurantSearchbarContainer .deliveryType {
    margin-left: 0;
    position: relative;
}
.restaurantSearchbarContainer .deliveryList {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    background-color: white;
    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.restaurantDropdownButtonClosed {
    height: 43px;
    text-align: center;
    align-items: center;
    border-style: solid;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-width: 1px 0px 1px 1px;
    border-color: #eaeaea;
    font-weight: 500;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin: auto;
    width: 105px;
}

.restaurantDeliveryButton2 {
    height: 43px;
    width: 105px;
    text-align: center;
    align-items: center;
    border-style: solid;
    border-top-left-radius: 5px;
    border-width: 1px;
    border-color: #eaeaea;
    font-weight: 500;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.restaurantDeliveryButton3 {
    height: 33px;
    width: 105px;
    text-align: center;
    align-items: center;
    border-style: solid;
    border-bottom-left-radius: 5px;
    border-width: 1px;
    border-color: #eaeaea;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.searchRadiusButton {
    height: 43px;
    text-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: #eaeaea;
    font-weight: 500;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin: auto;
}

.searchRadiusButton2 {
    height: 33px;
    align-items: center;
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-color: #eaeaea;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.restaurantDeliveryButton2:hover {
    background-color: rgba(32, 126, 43, 0.1);
}

.restaurantDeliveryButton3:hover {
    background-color: rgba(32, 126, 43, 0.1);
}

.searchRadiusButton2:hover {
    background-color: rgba(32, 126, 43, 0.1);
}

.useLocation {
    padding-top: 5px;
    color: #797979;
    cursor: pointer;
    text-decoration: underline;
    width: calc(100% - 30px);
}

.dayOfTheWeekText {
    float: left;
    width: 35%;
}

.openHoursText {
    width: 120%;
}

.openHoursSection {
    border-top: 1px solid rgb(200, 200, 200);
    border-bottom: 1px solid rgb(200, 200, 200);
    padding-top: 2px;
    padding-bottom: 5px;
}

.noResultsTag {
    font-size: 16px;
    color: black;
    font-weight: 500;
    padding-top: 16px;
}
.noResultsText {
    font-size: 16px;
    color: grey;
}
.gkLogo {
    max-width: 100%;
    max-height: 100%;
    display: flex;
}
.commingSoonText {
    background-color: #f9f9f9;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    color: #666666;
    font-weight: 600;
    margin-top: 10px;
    border: 1px solid #eaeaea;
    flex: 1;
}
.restaurantSearchbarButton {
    /* float: left; */
    width: 105px;
    background-color: #ffffff;
    z-index: 1;
}

.restaurantDistanceText {
    font-size: 13px;
    color: #a6a6a6;
    float: right;
    padding-top: 3px;
}
.openHoursContianer {
    border-top: 1px solid rgb(200, 200, 200);
    border-bottom: 1px solid rgb(200, 200, 200);
    padding-top: 2px;
    padding-bottom: 5px;
}
.currentTimeText {
    font-weight: 500;
    color: black;
}
.noResultsContainer {
    padding: 18px 18px;
}

.markerTag {
    background-color: #238e44;
    border-radius: 8px;
    color: #ffffff;
    font-size: 14px;
    padding: 10px 15px;
    position: relative;
}

.markerTag::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #238e44;
}

@media only screen and (max-width: 600px) {
    .restautantsAddressText {
        display: flex;
        width: 85%;
    }
    .backIconContainer {
        background-color: #fff;
        height: auto;
        position: relative;
        box-shadow: 0px -10px 8px rgba(0, 0, 0, 0.2);
        padding: 16px 12px 6px;
    }
    .headerAndMapContainer {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        position: -webkit-sticky;
        position: sticky;
        display: flex;
        flex-direction: column;
        left: 0;
        top: 0;
        height: 100%;
    }
    .useLocationRoundButton {
        position: absolute;
        width: 41px;
        height: 41px;
        left: 32px;
        bottom: 5%;
        border-radius: 50%;
        z-index: 100;
        background: #ffffff;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    }
    .noResultsContainer {
        padding: 32px 18px;
    }
    .noResultsTag {
        font-size: 16px;
        color: black;
        font-weight: 500;
        padding-top: 0;
        padding-left: 0;
        padding-bottom: 8px;
    }
    .noResultsText {
        font-size: 14px;
        color: grey;
        padding-left: 0;
    }

    .restaurantList img {
        display: none;
    }

    .restaurantList p {
        display: none;
    }

    .mapContainer {
        display: flex;
        position: sticky;
        position: -webkit-sticky;
        height: 100%;
    }
    .mobileSearchContent {
        margin: auto;
        padding: 18px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .restaurantList {
        flex-grow: 1;
        width: 100%;
        max-width: 100%;
        background-color: #fff;
        height: 100vh;
        box-shadow: 0px 0px;
        /* cursor: pointer; */
        display: flex;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
    }

    .restaurantList img {
        padding: 20px;
        max-width: 180px;
    }

    .restaurantList h1 {
        font-size: 23px;
        margin: 10px;
        margin-bottom: 0px;
        margin-top: 20spx;
        font-weight: 600;
        color: #fff;
    }

    .restaurantList p {
        color: #555;
        font-size: 15px;
        margin-top: 0;
        font-weight: 500;
        margin-left: 10px;
        margin-bottom: 0;
        color: #20b04b;
    }

    .restaurantItem {
        justify-content: center;
        align-content: center;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        padding: 14 px;
        margin: 0;
        box-shadow: 0px 0px;
        border-radius: 0 px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        flex: 0 0 auto;
    }

    .restaurantItem + .restaurantItem {
        margin-top: 0;
    }

    .restaurantItem h1 {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        color: #207e2b;
    }

    .restaurantItem section {
        font-size: 14px;
        color: #555;
        font-weight: 400;
        margin-top: 5px;
    }

    .restaurantItem:first-of-type {
        margin-top: 0px;
    }

    .restaurantItem:last-of-type {
        margin-bottom: 0px;
    }

    .restaurantLocationPickerContainer {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        height: 100vh;
        top: 0;
        left: 0;
        max-width: 100%;
    }

    .restaurantLocationActionContainer {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }

    .restaurantLocationActionButton {
        width: calc(50% - 10px);
        height: 45px;
        text-align: center;
        margin-top: 10px;
        background-color: #f9f9f9;
        border: 1px solid #eaeaea;
        border-radius: 5px;
        margin-right: 5px;
        color: #207e2b;
        font-weight: 500;
        padding-top: 5px;
        padding-bottom: 5px;
        transition: 0.2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 3x 3px 10px rgba(0, 0, 0, 0.2);
    }
    .restaurantOrderButtonContainer {
        display: flex;
        width: 100%;
        height: auto;
        margin: auto;
    }

    .restaurantLocationOrderButton {
        width: calc(50% - 5px);
        text-align: center;
        margin-top: 10px;
        background-color: #207e2b;
        border-radius: 5px;
        margin-right: 5px;
        color: #ffffff;
        font-weight: 500;
        padding-top: 5px;
        padding-bottom: 5px;
        transition: 0.2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 3x 3px 10px rgba(0, 0, 0, 0.2);
    }

    .restaurantLocationOrderButton2 {
        width: 100%;
        height: 45px;
        text-align: center;
        margin-top: 10px;
        background-color: #207e2b;
        border-radius: 5px;
        margin-right: 0px;
        color: #ffffff;
        font-weight: 500;
        padding-top: 5px;
        padding-bottom: 5px;
        transition: 0.2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 3x 3px 10px rgba(0, 0, 0, 0.2);
    }

    .restaurantLocationActionButton:hover {
        opacity: 0.6;
    }

    .restaurantLocationActionButton:last-of-type {
        margin-right: 0;
    }

    .restaurantDropdownButtonClosed {
        height: 20px;
        width: 50%;
        float: left;
        text-align: center;
        font-size: 75%;
        border-style: solid;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-width: 0px;
        border-color: #eaeaea;
        font-weight: 500;
        font-size: 14px;
        color: #606060;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    .restaurantDeliveryButton2 {
        height: 33px;
        text-align: center;
        font-size: 75%;
        border-style: solid;
        border-top-left-radius: 5px;
        border-width: 1px;
        border-color: #eaeaea;
        font-weight: 500;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    .restaurantDeliveryButton3 {
        height: 33px;
        text-align: center;
        font-size: 75%;
        border-style: solid;
        border-bottom-left-radius: 5px;
        border-width: 1px;
        border-color: #eaeaea;
        font-weight: 500;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    .searchRadiusButton {
        height: 33px;
        text-align: center;
        font-size: 75%;
        border-style: solid;
        border-width: 1px;
        border-color: #eaeaea;
        font-weight: 500;
        padding-top: 5px;
        padding-bottom: 5px;
        display: block;
        justify-content: center;
        cursor: pointer;
    }

    .restaurantDeliveryButton2:hover {
        background-color: rgba(32, 126, 43, 0.1);
    }

    .restaurantDeliveryButton3:hover {
        background-color: rgba(32, 126, 43, 0.1);
    }

    .useLocation {
        padding: 11px;
        color: #797979;
        cursor: pointer;
        text-decoration: underline;
    }

    .dayOfTheWeekText {
        float: left;
        width: 35%;
        color: #555;
    }

    .openHoursText {
        width: 80%;
        color: #555;
        margin: auto;
    }

    .logoBar {
        flex-grow: 0;
        top: 0;
        left: 0;
        background-color: #fff;
        height: 5vh;
        /* cursor: pointer; */
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding-top: 18px;
        justify-content: space-between;
    }
    .logoBarMapOpen {
        flex-grow: 0;
        top: 0;
        left: 0;
        background-color: #fff;
        height: 45px;
        /* cursor: pointer; */
        z-index: 100;
        position: sticky;
        position: -webkit-sticky;
        flex-direction: row;
        align-items: center;
        padding-top: 18px;
        justify-content: center;
        width: auto;
        display: flex;
        float: left;
        padding-bottom: 12px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    }
    .gkLogoContainer {
        width: 20%;
        display: flex;
        margin: auto;
        padding: 0px;
    }
    .noResultsContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
    }
    .mobileListLogo {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
    .searchIcon {
        margin-left: 14px;
        color: #b4b4b4;
    }
    .useLocationIcon {
        color: #207e2b;
        padding-right: 14px;
    }
    .useLocationMobileList {
        color: #797979;
        cursor: pointer;
        max-height: 100%;
        width: auto;
        display: flex;
        padding-right: 14px;
    }
    .restaurantAnimationContainer {
        margin-top: 8px;
        background-color: #fff;
        padding-bottom: 52px;
    }

    .restaurantAnntationToday {
        font-weight: 500;
        color: #000;
        padding-top: 12px;
    }
}
