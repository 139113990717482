.categoryTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 10px 5px 20px;
}

.categoryItemContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: scroll;
    overflow-y: hidden;
    padding-left: 20px;
    padding-bottom: 5px;
    flex-basis: 0;
}

.categoryItemLabel {
    color: #20b04b;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 2px;
}

.menuCategory:first-of-type {
    margin-top: 20px;
}

@media only screen and (min-width: 768px) {
    .categoryItemContainer {
        overflow: hidden;
    }
}
