.tableCodeContainer {
    padding: 20px;
    padding-top: 20px;
}

.tableCodePromptText {
    font-family: 'Asap';
    font-size: 18px;
    font-weight: 800;
    color: #3c3c3c;
}

.tableCodeInputContainer {
    margin-top: 10px;

    display: flex;
    justify-content: space-between;
}

.tableCodeInput {
    /* To override styling */
    border: none;

    /* Begin */
    background-color: #e9e9e9;
    /* border: 1.2px solid #777; */
    box-sizing: border-box;
    border-radius: 5px;
    width: 17%;
    color: #333;

    border-radius: 5px;
    text-align: center;

    font-family: 'Roboto Mono';
    font-size: 23px;

    text-transform: capitalize;
}

.tableCodeSubmitButton {
    width: 17%;
    transition: 0.1s;
}

.tableCodeSubmitButton:hover {
    opacity: 0.5;
}

.tableCodeErrorMessage {
    font-family: Roboto;
    color: red;
    font-size: 14px;
    margin-top: 10px;
}

.welcomeSplash {
    height: 400px;
    padding: 20px;
    padding-top: 50px;

    font-family: Roboto;
    color: #fefefe;

    background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.4) 30.31%,
            rgba(255, 255, 255, 0) 100%
        ),
        url('/assets/home-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.welcomeTitle {
    font-family: 'Asap';
    font-weight: bold;
    font-size: 36px;
    color: #fefefe;

    max-width: 75%;
}

.welcomeDetail {
    font-family: 'Roboto Mono';
    font-weight: 500;
    font-size: 23px;
    margin-top: 30px;
    font-weight: 700;
    line-height: 1.5;
}

.splashActionButton {
    margin-top: 50px;
    width: 195px;
    padding: 10px;

    background: rgba(0, 0, 0, 0.5);

    border: 1px solid #fefefe;
    color: #fefefe;
    box-sizing: border-box;
    border-radius: 7px;
    text-align: center;

    font-weight: bold;
    font-size: 16px;
}
