.fof-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
}

.fof-number {
    font-size: 96px;
    font-weight: 700;
    opacity: 0.3;
}

.fof-text {
    font-size: 24px;
    opacity: 0.67;
    text-align: center;
}

.fof-logo {
    margin-top: 120px;
    width: 150px;
}
