.locationSelectorButton {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 40px;
    height: 40px;
    /* background: #fff; */
    cursor: pointer;
    z-index: 99999;
}

.locationSelectorContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}

.location {
    padding: 10px;
    border: 1px solid #fff;
    margin: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

.selectLocationButton {
    padding: 5px;
    background-color: #fff;
    color: #000;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.fullscreen-bg__video-wall {
    width: 100%;
    left: 0;
}

.videoContainer {
    position: fixed;
    z-index: 999;
    background-color: #000;
    width: 100vw;
    height: 100vh;
}

.tv-wall-notifications {
    position: fixed;
    top: 10px;
    left: 10px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #888;
    border-radius: 10px;
    min-width: 60px;
    z-index: 99999;
    text-align: center;
    cursor: pointer;
}

.videoContainerEmpty {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: #000;
    width: 100vw;
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

@keyframes fadeOut {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.curtains {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    animation: 0.5s ease-out fadeOut;
}

.hide-curtains {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 999;
}

.downloadProgress {
    background-color: rgba(18, 18, 18, 0.8);
    position: fixed;
    top: 10px;
    left: 10px;
    width: calc(100vw - 20px);
    border-radius: 10px;
    z-index: 99999;
    max-height: 70vh;
    overflow-y: auto;
}

.downloadProgress-item {
    color: #fff;
    padding: 10px;
    font-family: 'Roboto Mono';
    font-size: 13px;
}

.progressBar {
    border-radius: 10px;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.innerProgressBar {
    background-color: rgb(52, 196, 83);
    height: 5px;
    border-radius: 10px 10px 10px 10px;
}
