@keyframes idle-pop-in {
    0% {
        opacity: 0;
        transform: scale(0.7);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes paymentErrorPing {
    0% {
        background-color: #ffa600;
    }
    50% {
        background-color: #ff0000;
    }
    100% {
        background-color: #ffa600;
    }
}

.kiosk-logo {
    position: fixed;
    bottom: 30px;
    right: 0;
    z-index: 99999999;
    width: 380px;
    opacity: 0.6;
}

.paymentMethodError {
    position: fixed;
    display: block;
    top: 10px;
    right: 10px;
    z-index: 9999999;
    padding: 20px;
    border-radius: 50px;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
}

.paymentMethodErrorCircle {
    min-width: 30px;
    min-height: 30px;
    border-radius: 30px;
    background-color: rgb(219, 81, 46);
    margin-right: 10px;
    animation: 1s ease-in-out paymentErrorPing infinite;
}

.idlePromptContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    background-color: rgba(0, 0, 0, 0.5);
}

.hide {
    display: none;
}

.idlePrompt {
    padding: 30px;
    text-align: center;
    background-color: #fff;
    box-shadow: 10px 10px 80px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    animation: 0.5s ease-in-out idle-pop-in;
    align-items: center;
}

.idlePrompt p {
    font-size: 36px;
    font-weight: 600;
    color: #d68c45;
}

.idlePrompt section {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    min-height: 100px;
    padding: 20px;
    margin: 20px;
    font-size: 64px;
    font-weight: 800;
    border: 13px solid #d68c45;
    border-radius: 100px;
    color: #d68c45;
}

.idlePromptButtonContainer {
    display: flex;
    margin-top: 2em;
    align-items: center;
    justify-content: center;
}

.idlePromptButton {
    width: 280px;
    background-color: #d68c45;
    margin: 20px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 15px;
    border-radius: 5px;
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    transition: 0.05s ease-in-out;
}

.idlePromptButton:active {
    transform: scale(0.9);
}
