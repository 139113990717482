.brand-select-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 30px;
    /* margin-top: 2px; */
}

.brand-select-header {
    margin: 30px;
    font-size: 63px;
    font-family: 'Roboto';
    color: rgb(64, 83, 64);
}

.brand-select-logo {
    width: 150px;
    height: 150px;

    object-fit: contain;

    margin: 10px;
    margin-bottom: 30px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    /* border: 1px solid #c2c2c2; */

    border-radius: 50%;

    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
    /* transition: 0.05s ease-in-out; */
}

.allergy-notice-modal-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.3);
}
.allergy-notice-modal {
    padding: 30px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    max-width: 600px;
    margin-top: -250px;

    color: #333;
}
.allergy-notice-modal-close {
}
.allergy-notice-modal-header {
    display: flex;
    justify-content: space-between;
}
.allergy-notice-modal-title {
    font-weight: 600;
    font-size: 30px;
}
.allergy-notice-modal-body {
    margin-top: 25px;
}

.category-chevron {
    position: absolute;
    height: 80px;
    width: 200px;
    margin-top: -1px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(255, 255, 255, 0.9);

    transition: 0.3s ease-in;

    border-radius: 0 10px 0 0;
}

.brand-coming-soon {
    position: absolute;
    font-size: 13px;
    font-weight: 600;
    background-color: #2d4964;
    color: #fff;

    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 25px;
    z-index: 1;

    margin-left: 20px;
    margin-top: 130px;
}

.allergies-msg {
    margin-left: 30px;
    margin-right: 30px;
    padding: 20px;
    box-shadow: 0 5px 10px 0px #ccc;
    border-radius: 10px;
    display: inline-block;
}

.allergies-msg-container {
    display: flex;
    justify-content: flex-end;
}

.category-chevron-icon {
    transform: rotate(90deg);
    width: 20px;
    opacity: 0.6;
}

.category-chevron-down {
    margin: 0;
    position: absolute;
    bottom: 520px;
    z-index: 999;

    border-radius: 0 0 10px 0;
}

.paymentTerminalDisconnectedPrompt {
    position: fixed;
    height: 80vh;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 38px;
    font-weight: 700;
    color: rgb(180, 26, 26);
}

.category-chevron-icon-down {
    transform: rotate(270deg);
    width: 20px;
}

@media (min-aspect-ratio: 16/9) {
    .brand-select-header {
        font-size: 36px;
        text-align: center;
    }
    .brand-select-logo {
        width: calc(10vw - 50px);
        height: calc(10vw - 50px);
    }
    .brand-coming-soon {
        font-size: 15px;
        padding: 13px;
        padding-left: 18px;
        padding-right: 18px;
    }
    .category-chevron {
        width: 350px;
    }
    .category-chevron-icon {
        width: 10px;
    }
    .category-chevron-down {
        bottom: 160px;
    }
}
