.v2CampaignBlock {
    height: 200px;
    width: 260px;
    background-color: #eee;
    border-radius: 9px;
    display: flex;
    font-family: 'Poppins';
    cursor: pointer;
}

.v2CampaignBlockContainer {
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    flex-wrap: nowrap;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #444;
}

.v2CampaignBlockLabel {
    font-weight: 700;
    font-size: 11px;
    margin-bottom: 8px;
}

.v2CampaignBlockName {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120.5%;
}

.v2CampaignBlockInnerContainer {
    padding: 15px;
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .v2CampaignBlockContainer {
        flex-direction: row;
        flex-wrap: wrap;
        overflow-y: unset;
    }
}
