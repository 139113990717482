.hcf-container {
    padding: 20px;
}

.hcf-title {
    font-size: 32px;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
}

.hcf-logo {
    margin: 20px;
    width: calc(100% - 40px);
}

.hcf-error {
    color: rgb(177, 4, 4);
}
