.dineSelectContainer {
    width: 85%;
    margin-left: 7.5%;
    height: 100%;
}

.dineSelectLogo {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    height: 100px;
    object-fit: contain;
}

.dineSelectOptionsContainer {
    display: flex;
    justify-content: space-between;
}

.dineSelectOption {
    background-color: #f2f2f2;
    border-radius: 15px;
    padding: 10px;
    width: 100%;
    min-height: 170px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: 0.3s ease;
    color: #717171;
}
.dineSelectOption:hover {
    background-color: #0084ff;
    color: #fff;
}

.dineSelectOption + .dineSelectOption {
    margin-left: 20px;
}

.dineSelectInnerContainer {
    background-color: #e3e3e3;
    border-radius: 10px;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 9;
    transition: 0.3s ease;
}

.dineSelectIcon {
    width: 60px;
    height: 60px;
}

.dineSelectLabel {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 18px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.codeInputContainer {
    animation: 0.3s ease-in fadeIn;
    margin-top: 20px;
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    align-items: center;
}

.codeInputTextField {
    width: auto;
    min-width: 0;
    border: 0;
    background-color: #fff;
    background: #ffffff;
    border: 3.5px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 15px;
    padding-left: 22px;
    flex-grow: 3;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 106.69%;
    /* or 19px */
    letter-spacing: -0.005em;
    color: #939393;
    outline: none;
}

.codeInputSubmitButton {
    flex-grow: 1;
    background: #0084ff;
    min-width: 42px;
    min-height: 42px;
    max-width: 42px;
    max-height: 42px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    cursor: pointer;
    transition: 0.3s ease;
}
.codeInputSubmitButton:hover {
    opacity: 0.8;
}

.codeInputSubmitButtonArrow {
    width: 20px;
    height: 20px;
}

.selectedBack {
    background-color: #52abff;
}
.selectedInner {
    background-color: #0084ff;
}
.selectedLabel {
    color: #fff;
}

.animateSlow {
    transition: 1s ease-out;
}
