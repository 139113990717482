.menuContainer {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, 25vw);
    justify-content: space-between;
    margin-top: 30px;
    padding-bottom: 60px;
}

@media only screen and (min-width: 1024px) {
    .menuContainer {
        grid-template-columns: repeat(auto-fill, 20vw);
    }
}

@media only screen and (min-width: 1440px) {
    .menuContainer {
        grid-template-columns: repeat(auto-fill, 15vw);
    }
}

@media only screen and (min-width: 2560px) {
    .menuContainer {
        grid-template-columns: repeat(auto-fill, 10vw);
    }
}

.logoAndPhoneCallContainer {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-left: 2%;
}
