@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
.HeaderText {
    font-family: 'Poppins';
    font-size: 14;
    font-weight: 700;
    color: white;
}
.HeaderContainer {
    background-color: rgb(32, 32, 32);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 16px 5px 16px;
}
.HeaderConfirmTextWhite {
    font-family: 'Poppins';
    font-size: 13;
    font-weight: 700;
    color: white;
    cursor: pointer;
}
.HeaderConfirmTextDisabled {
    font-family: 'Poppins';
    font-size: 13;
    font-weight: 700;
    color: white;
    opacity: 30%;
}
.ModifierRow {
    padding: 10px 0px 10px 10px;
    border-bottom: 1px;
    border-color: #fff;
    border-bottom-style: solid;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: 0.1s ease-in;
}
.ModifierRow:hover {
    background-color: rgb(243, 243, 243);
    cursor: pointer;
}
.ModifierDetails {
    background-color: #fff;
    width: 100%;
}
.SettingGroup {
    /* width: 100%; */
    background-color: white;
}
.SettingHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f5f5f7;
    cursor: pointer;
}
.SettingHeader:hover {
    background-color: #e9e9e9;
}
.CenterVertically {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.SubHeaderContainerText {
    padding: 40px 0px 16px 16px;
    font-family: 'Poppins';
    font-size: 13;
    font-weight: 700;
    border-bottom: 1px;
    border-color: rgb(226, 226, 226);
    border-bottom-style: solid;
}
.ModifierRowText {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: rgb(49, 49, 49);
}
.SelectButtonContainer {
    padding: 3px 16px 0px 16px;
}
.SpecialInstructionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 24px 32px 0px 32px;
}

@media only screen and (min-width: 768px) {
    .ModifierRowText {
        font-size: 16px;
    }
}
