.yourOrderContainer {
    padding-left: 20px;
    margin-right: 30px;
}
.yourOrderTitle {
    font-size: 24px;
    font-family: 'Roboto';
    font-weight: 400;
    color: #758688;

    margin-top: 30px;
    margin-bottom: 30px;
}

.yourOrderDescription {
    font-family: 'Lato';
    font-size: 16px;
    font-style: italic;
    color: #757575;

    margin-bottom: 60px;
}

.yourOrderItemContainer {
    margin-bottom: 20px;
}

.yourOrderItemRow {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.yourOrderItemModifier {
    margin-left: 20px;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 300;
}

.thumbIcon {
    display: inline-block;
    margin-left: 5px;
}

.thumbsUp {
    color: green;
}

.thumbsDown {
    color: red;
}

.PAGItem {
    font-size: 14px;
    color: #758688;
}

.originalPrice {
    text-decoration: line-through;
}

.yourOrderItemPriceContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

/* .yourOrderItemLabel {

}

.yourOrderItemPrice {
    
} */

@media only screen and (min-width: 700px) {
    .yourOrderContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .yourOrderTitle {
        min-width: 700px;
    }
    .yourOrderDescription {
        min-width: 700px;
    }
    .yourOrderItemRow {
        min-width: 700px;
    }
}
