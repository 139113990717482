.Container {
    /* display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    align-items: center; */
}

.seperator {
    margin: 40px;
}

.greeting {
    margin-top: 30px;
    margin-bottom: 80px;
    padding-left: 20px;
    margin-right: 30px;
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 18px;
    font-style: italic;
    color: #758688;
}

.restaurantLogo {
    margin-left: 20px;
    width: 100px;
}

.restaurantName {
    margin-left: 20px;
    margin-top: 10px;
    font-family: 'Roboto';
    font-size: 34px;
    font-weight: 800;
    color: #fefefe;
    line-height: 1;
}

.instructionsText {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    color: #fefefe;
    margin-left: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.guestListContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}
