@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
.ErrorBoundaryPage {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ErrorBoundaryContainer {
    max-width: 75vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.ErrorOops {
    font-size: 35px;
    font-family: 'Roboto';
}

.RefreshButton {
    font-size: 18px;
    padding: 12px;
    font-weight: 400;
    border: 1.5px solid lightgrey;
    border-radius: 10px;
    margin-bottom: 15px;
    cursor: pointer;
}

.RefreshButton:hover {
    background-color: lightgrey;
    color: white;
}

.ErrorMessage {
    color: grey;
    font-size: 12px;
}

@media only screen and (min-width: 700px) {
    .ErrorOops {
        font-size: 60px;
        font-family: 'Roboto';
    }
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    touch-action: manipulation;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModal__Overlay {
    z-index: 9999;
}
.backButtonLabel {
    font-size: 20px;
    color: #758688;
    cursor: pointer;
}

.imgLogo {
    height: 30px;
    width: 50vw;
    object-fit: contain;
    object-position: 0% 50%;
    cursor: pointer;
}

.imgLogoLg {
    height: 90px;
}

.backButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.backButtonLogoHilton {
    justify-content: center;
}

.guestNameRow {
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.guestNameLabel {
    font-weight: 400;
    font-size: 18px;
    font-weight: 300;
    color: #758688;
}

.guestNameIcon {
    margin-left: 7px;
    height: 15px;
}

.thinBackButton {
    height: 8px;
    cursor: pointer;
}

.fixedHeader {
    width: 100%;
    position: fixed;
    top: 0px;

    -webkit-backdrop-filter: blur(25px) saturate(250%);

            backdrop-filter: blur(25px) saturate(250%);
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 999;
}

.header {
    padding: 17px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dark {
    background-color: #1c1c1e;
    color: #fefefe;
}

.logo {
    font-family: 'Asap';
    font-weight: 700;
    font-size: 29px;
    text-transform: uppercase;
}

.backBtn {
    margin-right: 15px;
    cursor: pointer;
}

.tableCodeBackBtn {
    display: inline-block;
    margin-right: 15px;
    border: 1px solid #b2b2b2;
    border-radius: 11px;
    padding: 8px;
    padding-left: 15px;
    color: #1a1a1a;
    transition: 0.1s;
}

.tableCodeBackBtnDark {
    color: #fefefe;
}

.tableCodeBackBtn:hover {
    opacity: 0.5;
}

.tableCodeText {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 13px;
    text-decoration: none;
}

.guestNameTextDark {
    color: #fefefe;
}

.guestNameText {
    margin-left: 10px;
    margin-right: 5px;
    font-size: 14px;
    font-weight: 700;
}

.menuSandwichBtn {
    float: right;
    margin: 5px;
}

.restartOrderButton {
    display: absolute;
    top: 0;
    right: 0;
    background-color: #3bad4d;
    padding: 10px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    border-radius: 100px;
    cursor: pointer;
    transition: 0.5s ease;
}
.restartOrderButton:hover {
    opacity: 0.8;
}

.tableCodeContainer {
    padding: 20px;
    padding-top: 20px;
}

.tableCodePromptText {
    font-family: 'Asap';
    font-size: 18px;
    font-weight: 800;
    color: #3c3c3c;
}

.tableCodeInputContainer {
    margin-top: 10px;

    display: flex;
    justify-content: space-between;
}

.tableCodeInput {
    /* To override styling */
    border: none;

    /* Begin */
    background-color: #e9e9e9;
    /* border: 1.2px solid #777; */
    box-sizing: border-box;
    border-radius: 5px;
    width: 17%;
    color: #333;

    border-radius: 5px;
    text-align: center;

    font-family: 'Roboto Mono';
    font-size: 23px;

    text-transform: capitalize;
}

.tableCodeSubmitButton {
    width: 17%;
    transition: 0.1s;
}

.tableCodeSubmitButton:hover {
    opacity: 0.5;
}

.tableCodeErrorMessage {
    font-family: Roboto;
    color: red;
    font-size: 14px;
    margin-top: 10px;
}

.welcomeSplash {
    height: 400px;
    padding: 20px;
    padding-top: 50px;

    font-family: Roboto;
    color: #fefefe;

    background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0.4) 30.31%,
            rgba(255, 255, 255, 0) 100%
        ),
        url('/assets/home-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.welcomeTitle {
    font-family: 'Asap';
    font-weight: bold;
    font-size: 36px;
    color: #fefefe;

    max-width: 75%;
}

.welcomeDetail {
    font-family: 'Roboto Mono';
    font-weight: 500;
    font-size: 23px;
    margin-top: 30px;
    font-weight: 700;
    line-height: 1.5;
}

.splashActionButton {
    margin-top: 50px;
    width: 195px;
    padding: 10px;

    background: rgba(0, 0, 0, 0.5);

    border: 1px solid #fefefe;
    color: #fefefe;
    box-sizing: border-box;
    border-radius: 7px;
    text-align: center;

    font-weight: bold;
    font-size: 16px;
}

.spacing38 {
    height: 38px;
}

.spacing60 {
    height: 60px;
}
.spacing128 {
    height: 128px;
}

.v3Header {
    display: flex;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    width: auto;
    justify-content: center;
    -webkit-backdrop-filter: blur(15px) saturate(350%);
            backdrop-filter: blur(15px) saturate(350%);
    background-color: rgba(255, 255, 255, 0.8);
}

.v3HeaderInnerContainer {
    padding: 20px;
    max-width: 1200px;
    display: flex;
    align-items: center;
    flex: 1 1;
}

.v3HeaderLogo {
    height: 30px;
    display: flex;
}

.v3HeaderSplitRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.v3HeaderRow {
    display: flex;
    align-items: center;
}

.v3HeaderLink {
    margin-left: 30px;
    font-weight: 500;
    margin-right: 30px;
    font-family: 'Inter';
    color: #4d4d4d;
    cursor: pointer;
}

.v3HeaderLink:hover {
    color: #0451cb;
}

/*for texts part*/
.v3HeaderProductContent {
    border-radius: 10px;
    transition: 0.1s ease-in;
    padding-bottom: 10px;
}

.v3HeaderProductContent .v3HeaderProductSplitViewSide {
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    width: 70%;
    color: #333333;
    transition: 0.1s ease-in-out;
}
.v3HeaderProductContent:hover {
    background-color: #ECF8FF;

}
.v3HeaderProductContent .v3HeaderProductSplitViewRight {
    font-size: 14px;
    float: left;
    display: flex;
    padding-top: 8px;
    align-content: center;
    width: 60px;
    justify-content: center;
}

/* the dropdown product element*/

.v3HeaderDropdownProductImg {
    margin: auto;
    width: auto;
    height: auto;
}

.v3HeaderDropdownProductTag {
    font-size: 14px;
    font-weight: 700;
    font-weight: bold;
    text-align: left;
    vertical-align: top;
}

.v3HeaderDropdownProductDescr {
    font-size: 14px;
    text-align: left;
    vertical-align: top;
}

.v3HeaderLink:hover {
    color: #0451cb;
}

.arrow {
    border: solid #4d4d4d;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 10px;
    margin-bottom: 2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

/*
add dropdown for header products section here
hide by default, show when hover
*/

.v3HeaderDropdownSpace {
    height: 24px;
}
.v3HeaderDropdownBg {
    margin: auto;
    width: 100vw;
    cursor: pointer;
    background: #ffffff;
    box-shadow: 0px 7px 16px rgb(0 0 0 / 16%);
}


.v3HeaderDropdownContent {
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.v3HeaderDropdownHide {
    visibility: hidden;
    left: 0;
    position: fixed;
    opacity: 0;

    transition: height 0.2s, opacity 0.2s, visibility 0.2s ease-in;
    -webkit-transition: height 0.2s, opacity 0.2s, visibility 0.2s ease-in;
}

.v3HeaderDropdownShow {
    left: 0;
    position: fixed;
    visibility: visible;

    display: flex;
    flex-direction: column;
    opacity: 1;
    height: auto;

    transition: height 0.2s, opacity 0.2s, visibility 0.2s ease-in-out;
    -webkit-transition: height 0.2s, opacity 0.2s, visibility 0.2s ease-in-out;
}

.v3HeaderDropdownRow {
    padding-bottom: 16px;
    display: flex;
    max-width: 650px;
    flex-direction: row;
    grid-column-gap: 45px;
    -webkit-column-gap: 45px;
            column-gap: 45px;
    margin: auto;
    padding-left: 26px;
}

.v3HeaderProductContent {
    display: flex;
    flex: 1 1;
    justify-content: center;
    width: auto;
    margin-left: auto;
    margin-right: auto;
}

/*for texts part*/
.v3HeaderProductSplitViewSide {
    padding-top: 7px;
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    grid-column-gap: 7px;
    -webkit-column-gap: 7px;
            column-gap: 7px;
    width: 210px;
    color: #333333;
}
.v3HeaderProductSplitViewRight {
    font-size: 14px;
    float: left;
    display: flex;
    padding-top: 8px;
    vertical-align: top;
    align-content: center;
}

/* the dropdown product element*/

.v3HeaderDropdownProductTag {
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    vertical-align: top;
    padding-bottom: 7px;
}

.v3HeaderDropdownProductDscr {
    font-size: 14px;
    text-align: left;
    vertical-align: top;
    max-width: 250px;
    font-family: 'Inter';
}

.v3SectionContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.v3ContentContainer {
    max-width: 1100px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    /*padding-bottom: 100px;*/
}

.v3HeroContainer {
    width: auto;
    max-width: 100%;
    background-image: url(/assets/landing/v3/hero-decor.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% 0%;
}

.v3HeroSplit {
    display: flex;
    align-items: center;
    width: auto;
    width: auto;
    padding: 0 3%;
    max-width: 1230px;
    margin: auto;
}

.v3HeroSplitSide {
    flex: 1 1;
}
.v3HeroTextSide {
    flex: 1 1;
    padding-left: 32px;
}

.v3HeroText {
    font-size: 38px;
    font-weight: 700;
    color: #232323;
    padding-top: 8%;
}

.v3HeroDescription {
    font-size: 18px;
    font-weight: 500;
    color: #525252;
    font-family: 'Inter';
    width: 80%;
}

.v3ButtonContainer {
    display: inline-block;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: linear-gradient(
        119.65deg,
        #6bbecb 6.31%,
        #4770db 35.28%,
        #4151cc 63.28%,
        #1b42a7 95.14%
    );
    border-radius: 10px;
    font-family: 'Circular Std';
    font-weight: 500;
    cursor: pointer;
}

.v3ButtonInnerConatiner {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.v3ButtonChevron {
    margin-left: 10px;
}

.v3HighlightImage {
    width: 100%;
    image-rendering: -webkit-optimize-contrast;
}

.v3CenteredTagline {
    text-align: center;
    font-size: 16px;
    color: #4d4d4d;
}

.v3CenteredHeader {
    text-align: center;
    font-size: 36px;
    color: #232323;
    font-weight: 600;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 20px;
}

.v3CenteredDescription {
    font-family: 'Inter';
    font-size: 24px;
    max-width: 60%;
    color: #4d4d4d;
    text-align: center;
    margin: auto;
}

.v3BrandsList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.v3BrandLogo {
    width: 138px;
    max-height: 50px;
    object-fit: contain;
    image-rendering: -webkit-optimize-contrast;
    margin: 35px;
}

.v3HomepageBackground {
    background: url('/assets/landing/v3/desktop-landing-bg.png') no-repeat;
    background-size: cover;
    background-position: 0% 0%;
}

.v3TabbedContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.v3TabLabelContainer {
    display: flex;
}

.v3TabLabel {
    display: inline-block;
    color: #969696;
    font-size: 18px;
    font-weight: 500;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: 0.1s ease-in-out;
    cursor: pointer;
    margin: 5px;
}

.v3TabLabelSelected {
    background: #daddf1;
    border-radius: 10px;
    color: #4051cb;
}

.v3ProductSplitView {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10%;
    max-width: 805px;
    width: auto;
    margin: auto;
    height: 500px;
}

.v3ProductSplitViewSide {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
}

.v3ProductSplitViewRight {
    align-items: center;
    padding-right: 10%;
}

.v3LandingProductImage {
    width: 193px;
    margin: auto;
    image-rendering: -webkit-optimize-contrast;
}

.v3LandingProductTagline {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 12px;
}

.v3LandingProductName {
    font-size: 32px;
    font-weight: 600;
    padding-bottom: 14px;
}

.v3Landingv3LandingProductDescription {
    font-family: 'Inter';
    font-size: 16px;
    color: #555;
    padding-bottom: 10px;
    /* width: 80%; */
}

.v3LandingProductLink {
    color: #4051cb;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    text-align: left;
}

.v3LandingProductChevron {
    width: 7px;
    margin-left: 3px;
}

.v3ProductHidden {
    display: none;
}

.v3StoreHighlightSpacing {
    height: 130px;
}

.v3ReviewManyContainer {
    display: flex;
    align-items: center;
}

.v3ReviewContainer {
    padding: 30px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
}

.v3ReviewStar {
    width: 16px;
}

.v3ReviewPerson {
    display: flex;
    align-items: center;
}

.v3ReviewPosition {
    color: #8a8a8a;
}

.v3ReviewPersonDescription {
    font-family: 'Inter';
    margin-left: 10px;
}

.v3ReviewPersonImage {
    width: 60px;
}

.v3ReviewName {
    font-weight: 600;
}

.v3ReviewText {
    color: #8a8a8a;
    margin-top: 5px;
    margin-bottom: 5px;
}

.v3ReviewContainer {
    margin: 10px;
}

.v3IntegrationsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.v3IntegrationPartner {
    height: 64px;
    image-rendering: -webkit-optimize-contrast;
}

.v3ReadyToGetStartedContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.v3ReadyHeader {
    font-size: 48px;
    font-weight: 600;
}

.v3Footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.v3FooterContainer {
    max-width: 1200px;
    flex: 1 1;
    padding-left: 30px;
    padding-right: 30px;
}

.v3FooterBorder {
    border-bottom: 1px solid #dbdbdb;
}

.v3FooterRow {
    display: flex;
}

.v3FooterGroup {
    margin: 20px;
    margin-right: 60px;
    margin-left: 0;
}

.v3FooterGroupHeader {
    font-size: 10px;
    color: #9f9f9f;
}

.v3FooterLink {
    font-size: 16px;
    color: #232323;
    margin-top: 8px;
    margin-bottom: 8px;
}

.v3CopyrightFooter {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #9f9f9f;
    font-size: 13px;
}

.v3HeaderProductIcon {
    width: 33px;
    object-fit: contain;
}

.websiteHidden {
    display: none;
}
.v3RatingStars {
    display: flex;
    flex-direction: row;
}
.mobileWidth {
    width: 100%;
    image-rendering: -webkit-optimize-contrast;
}

@media only screen and (max-width: 850px) {
    .spacing38 {
        height: 19px;
    }
    .spacing60 {
        height: 32px;
    }
    .spacing128 {
        height: 64px;
    }
    .mobileHidden {
        display: none;
    }
    .websiteHidden {
        display: flex;
    }
    .mobileWidth {
        width: 80%;
    }
    .v3HamburgerSpace {
        height: 30px;
    }
    .v3HeaderDropdownSpace {
        display: none;
    }

    .v3HeaderLink {
        font-weight: 500;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 38px;
        font-size: 18px;
        padding-left: 38px;
        color: #4d4d4d;
        position: relative;
        padding: 28px 38px 28px 38px;
    }
    .arrow {
        border: solid #4d4d4d;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        margin-left: 10px;
        margin-bottom: 2px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    .v3HeaderDropdownHide {
        transition: height 0.6s, opacity 0.2s, visibility 0.4s ease-in-out;
        -webkit-transition: height 0.6s, opacity 0.2s,
            visibility 0.3s ease-in-out;
    }

    .v3HeaderDropdownShow {
        transition: height 0.6s, opacity 0.2s, visibility 0.4s ease-in-out;
        -webkit-transition: height 0.6s, opacity 0.2s,
            visibility 0.3s ease-in-out;
    }

    .v3HamburgerContainer {
        position: fixed;
        margin: auto;
    }
    .v3HamburgerToggle {
        top: 0;
        right: 0;
        display: fixed;
    }
    .v3HamburgerDropdown {
        display: relative;
        width: 100vw;
        height: auto;
        margin: auto;
    }
    .v3HeaderDropdownShow {
        left: 0;
        position: relative;
        visibility: visible;

        display: flex;
        flex-direction: column;
        opacity: 1;
        height: auto;

        transition: height 0.5s, opacity 0.5s, visibility 0.5s ease-in-out;
        -webkit-transition: height 0.5s, opacity 0.5s,
            visibility 0.5s ease-in-out;
    }

    .v3HamDropdownBg {
        width: 100vw;
        right: 0;
        background-color: #ffffff;
        box-shadow: 0px 7px 16px rgb(0 0 0 / 16%);
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 20px;
    }
    .v3HamDropdownContent {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
    .v3HeaderDropdown {
        position: relative;
    }
    .v3HeaderDropdownContent {
        padding: 0px;
    }
    .v3HeaderDropdownRow {
        padding-bottom: 0px;
        margin: 0;
        padding-left: 5px;
        flex-direction: column;
        max-width: 300px;
    }
    .v3HeaderProductContent {
        padding-bottom: 16px;
        padding-left: 26px;
    }
    /*for texts part*/
    .v3HeaderProductContent .v3HeaderProductSplitViewSide {
        padding-left: 26px;
        display: flex;
        flex-direction: column;
        width: 70%;
        color: #333333;
    }
    .v3HeroSplit {
        flex-direction: column;
        padding: 10px;
    }
    .v3HeroText {
        font-size: 32px;
        text-align: center;
        margin-bottom: 20px;
        line-height: 1em;
        font-weight: 900;
        letter-spacing: -0.01em;
    }
    .v3HeroDescription {
        font-size: 18px;
        text-align: center;
        font-family: 'Inter';
        width: 100%;
        margin-bottom: 20px;
    }
    .v3HeaderDropdownBg {
        box-shadow: none;
    }
    .v3LandingProductLink {
        text-align: center;
    }
    .v3RatingStars {
        display: flex;
        flex-direction: row;
    }
    .v3HeroContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        width: auto;
        padding: 6%;
        background-image: url('/assets/landing/v3/hero-decor-mobile.svg');
        background-size: cover;
        background-position: 50% 0%;
    }
    .v3HomepageBackground {
        background: url('/assets/landing/v3/homepage-mobile-bg.png') no-repeat;
        background-size: 100%;
    }
    .v3ContentContainer {
        padding: 0;
    }
    .v3HeroSplitSide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .v3HeroTextSide {
        display: flex;
        padding-left: 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .v3CenteredHeader {
        font-size: 28px;
        margin: auto;
        align-items: center;
        margin-left: 20px;
        margin-right: 20px;
    }
    .v3BrandLogo {
        image-rendering: -webkit-optimize-contrast;
        margin: 10px;
        width: 80px;
    }
    .v3BrandsList {
        margin-top: 20px;
        margin-bottom: 20px;
        justify-content: space-evenly;
    }
    .v3CenteredDescription {
        font-size: 16px;
        font-family: 'Inter';
        max-width: 91%;
    }

    .v3ButtonContainer {
        justify-content: center;
    }

    .v3TabLabelContainer {
        overflow-x: auto;
        width: 100vw;
    }
    .v3StoreHighlightSpacing {
        height: 60px;
    }
    .v3ProductSplitView {
        flex-direction: column-reverse;
        max-width: auto;
        align-items: center;
        margin: auto;
        padding: 0px 8%;
        height: auto;
    }
    .v3ProductSplitViewSide {
        align-items: center;
        margin: auto;
        padding: 0;
    }
    .v3ProductSplitViewRight {
        padding-right: 0;
        margin: 10%;
    }
    .v3ReviewManyContainer {
        flex-direction: row;
        overflow-x: auto;
        width: 100vw;
    }

    .v3ReviewContainer {
        min-width: 60%;
        margin-left: 12%;
        margin-right: 12%;
    }
    .v3FooterRow {
        flex-direction: column;
    }
    .v3LandingProductTagline {
        color: #4d4d4d;
        margin: auto;
        text-align: center;
    }
    .v3LandingProductName {
        align-items: center;
        text-align: center;
        margin: auto;
    }
    .v3Landingv3LandingProductDescription {
        font-size: 14px;
        color: #4d4d4d;
        text-align: center;
        font-family: 'Inter';
        width: auto;
        padding-bottom: 16px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .v3TabLabel {
        white-space: nowrap;
    }
    .v3TabLabel:first-of-type {
        margin-left: 30px;
    }
    .v3TabLabel:last-of-type {
        margin-right: 30px;
    }
    .v3IntegrationsContainer {
        display: flex;
        flex-wrap: nowrap;
        justify-content: start;
        max-width: 90%;
        overflow-x: auto;
        width: 100vw;
        margin: auto;
    }

    .v3ReadyHeader {
        font-size: 30px;
        text-align: center;
    }
    .v3LandingProductImage {
        width: 100%;
        image-rendering: -webkit-optimize-contrast;
    }
}

@font-face {
    font-family: 'Circular Std';
    src: url('/fonts/CircularStd/CircularStd-Black.woff2') format('woff2'),
        url('/assets/fonts/CircularStd/CircularStd-Black.woff') format('woff');
    font-weight: 900;
}

@font-face {
    font-family: 'Circular Std';
    src: url('/fonts/CircularStd/CircularStd-Black-Italic.woff2')
            format('woff2'),
        url('/assets/fonts/CircularStd/CircularStd-Black-Italic.woff')
            format('woff');
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'Circular Std';
    src: url('/fonts/CircularStd/CircularStd-Bold.woff2') format('woff2'),
        url('/assets/fonts/CircularStd/CircularStd-Bold.woff') format('woff');
    font-weight: 700;
}

@font-face {
    font-family: 'Circular Std';
    src: url('/fonts/CircularStd/CircularStd-Bold-Italic.woff2') format('woff2'),
        url('/assets/fonts/CircularStd/CircularStd-Bold-Italic.woff')
            format('woff');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Circular Std';
    src: url('/fonts/CircularStd/CircularStd-Book.woff') format('woff2'),
        url('/assets/fonts/CircularStd/CircularStd-Book.woff') format('woff');
}
@font-face {
    font-family: 'Circular Std';
    src: url('/fonts/CircularStd/CircularStd-BookItalic.woff') format('woff2'),
        url('/assets/fonts/CircularStd/CircularStd-BookItalic.woff')
            format('woff');
    font-style: italic;
}
@font-face {
    font-family: 'Circular Std';
    src: url('/fonts/CircularStd/CircularStd-Medium.woff') format('woff2'),
        url('/assets/fonts/CircularStd/CircularStd-Medium.woff') format('woff');
    font-weight: 500;
}
@font-face {
    font-family: 'Circular Std';
    src: url('/fonts/CircularStd/CircularStd-MediumItalic.woff') format('woff2'),
        url('/assets/fonts/CircularStd/CircularStd-MediumItalic.woff')
            format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter/static/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter/static/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'DM Sans';
    src: url('/fonts/DM_Sans/DMSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'DM Sans';
    src: url('/fonts/DM_Sans/DMSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'DM Sans';
    src: url('/fonts/DM_Sans/DMSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'DM Sans';
    src: url('/fonts/DM_Sans/DMSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

.pdSectionContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.pdHeroContainer {
    width: calc(100vw - 60px);
    background-image: url('/assets/landing/v3/hero-decor.svg');
    background-size: cover;
    font-family: 'Circular Std';
    background-position: 0% 0%;
    padding-left: 30px;
    padding-right: 30px;
}

.pdHeroSplit {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1200px;
    padding: 20px;
    margin: auto;
}

.pdHeroSplitSide {
    flex: 1 1;
    margin-left: auto;
    margin-right: auto;
    padding-left: 8%;
}
.pdHeroTagline {
    font-size: 16px;
    font-weight: 500;
    color: #4d4d4d;
}

.pdHeroText {
    font-size: 38px;
    font-weight: 700;
    color: #232323;
    line-height: 1em;
    margin-top: 10px;
    margin-bottom: 20px;
}

.pdHeroDescr {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    color: #525252;
    padding-bottom: 25px;
}
.pdHeroImg {
    margin-left: auto;
    margin-right: auto;
    image-rendering: -webkit-optimize-contrast;
}

.ProductSplitView {
    display: flex;
    max-width: 1040px;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: auto;
}
/* reverse when is  mobile mode */
.ProductSplitViewRWM {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1040px;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.ProductSplitViewSide {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 50%;
    margin: auto;
}

.ProductSplitViewRight {
    display: flex;
    justify-content: center;
    width: auto;
    margin: auto;
}

.ProductImage {
    max-width: 450px;
    max-height: 750px;
    object-position: 50% 50%;
    margin: auto;
    image-rendering: -webkit-optimize-contrast;
}

.v3LandingProductName {
    font-size: 28px;
    font-weight: 600;
    color: #232323;
}

.v3LandingProductDescription {
    font-family: Inter;
    font-size: 16px;
    color: #333333;
    width: 84%;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 500;
}

.ProductLink {
    color: #4051cb;
    font-size: 16px;
    margin-top: 10px;
    text-align: left;
}

.ProductChevron {
    width: 7px;
}

.CheckoutOthersContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    grid-gap: 24px;
    gap: 24px;
}

.CheckoutContent {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: auto;
}

.CheckoutImg {
    align-items: center;
    image-rendering: -webkit-optimize-contrast;
    width: 388px;
}

.CheckoutTag {
    padding-top: 8px;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}
.CheckoutDescrp {
    font-size: 16px;
    color: #4d4d4d;
    text-align: left;
    margin: auto;
    max-width: 390px;
    margin-top: 10px;
}

.ProductBackground {
    background: url('/assets/landing/v3/productPages/pd-bg.svg') no-repeat;
    background-size: cover;
    background-position: 0% 0%;
    background-size: 100%;
}

.ReadySectionBackground {
    background: url('/assets/landing/v3/productPages/ready-section-bg.svg')
        no-repeat;
    background-size: cover;
    background-position: 0% 0%;
    background-size: 100%;
}

@media only screen and (max-width: 850px) {
    .pdSectionContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .pdHeroContainer {
        width: calc(100% - 20px);
        padding: 10px;
        background-image: url('/assets/landing/v3/hero-decor-mobile.svg');
        background-position: 50% 0%;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .pdHeroSplit {
        display: flex;
        flex-direction: column;
        padding: 0px 0px 32px;
        align-items: center;
    }

    .pdHeroSplitSide {
        display: contents;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0px;
    }
    .pdHeroTagline {
        font-weight: 400;
        font-size: 16px;
        text-align: center;
    }

    .pdHeroText {
        font-size: 28px;
        text-align: center;
        color: #232323;
        width: 80%;
        padding-bottom: 20px;
    }

    .pdHeroDescr {
        font-family: Inter;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        width: 84%;
        color: #4d4d4d;
        padding-bottom: 28px;
    }

    .ProductSplitView {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 600px;
        margin: auto;
    }
    .ProductSplitViewRWM {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .ProductSplitViewSide {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        margin: auto;
    }

    .ProductSplitViewRight {
        justify-content: center;
        align-items: center;
        margin-right: auto;
        margin-left: auto;
        padding: 20px;
        margin: auto;
    }

    .ProductImage {
        width: auto;
        align-items: center;
        max-width: 100%;
    }

    .v3LandingProductName {
        text-align: center;
        font-size: 28px;
        font-weight: 600;
        max-width: 90%;
        padding-bottom: 12px;
    }

    .v3LandingProductDescription {
        font-family: Inter;
        color: #4d4d4d;
        text-align: center;
        font-size: 14px;
        max-width: 85%;
        align-items: center;
        padding-bottom: 12px;
    }

    .ProductLink {
        color: #4051cb;
        font-weight: 600;
        font-size: 16px;
        margin-top: 10px;
        text-align: center;
        padding-bottom: 20px;
    }

    .ProductChevron {
        width: 7px;
    }

    .CheckoutOthersContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }

    .CheckoutContent {
        width: 75%;
    }

    .CheckoutImg {
        align-items: center;
        width: 100%;
    }

    .CheckoutTag {
        padding-top: 8px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }
    .CheckoutDescrp {
        font-family: Inter;
        font-size: 16px;
        color: #4d4d4d;
        align-items: center;
        text-align: center;
    }

    .ProductBackground {
        background: url('/assets/landing/v3/pd-mobile-bg.png') no-repeat;
        background-size: 100%;
    }

    .ReadySectionBackground {
        background: url('/assets/landing/v3/productPages/ready-section-bg.svg')
            no-repeat;
        background-size: 100%;
    }

    .pdHeroImg {
        margin-left: auto;
        margin-right: auto;
        width: 70%;
    }
}

.v3ContactUsContainer {
    background-image: url('/assets/landing/v3/content-bg.svg');
    background-size: cover;
    background-position: 0% 0%;
    background-size: 100%;
}
.v3ContactHomeContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 8%;
    padding-right: 8%;
    width: auto;
    flex-shrink: 1;
}
.v3ContactUsSide {
    display: flex;
    flex-direction: column;
    width: auto;
    margin: auto;
}
.v3ContactUsTag {
    font-size: 48px;
    font-weight: 700;
    color: #ffffff;
    padding-bottom: 16px;
}
.v3ContactUsText {
    font-size: 18px;
    width: auto;
    max-width: 460px;
    font-weight: 400;
    color: #ffffff;
    font-family: 'Inter';
    padding-bottom: 48px;
}
.v3ContactImage {
    align-items: center;
    width: 90%;
}

.v3ContactUsForm {
    margin-left: 32px;
    display: flex;
    width: 48%;
    height: auto;
    border-radius: 15px;
    background-color: #ffffff;
}
.v3CompleteContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
}
.v3CompleteText {
    font-size: 24px;
    padding: 8px 12px;
    width: auto;
    text-align: center;
    font-weight: 500;
    color: #36b37e;
    font-family: 'Inter';
    padding-bottom: 54px;
}
.v3CompleteIcon {
    display: flex;
    align-items: center;
}
.v3ContactBrandsBackground {
    -webkit-backdrop-filter: blur(25px) saturate(150%);
            backdrop-filter: blur(25px) saturate(150%);
    background-color: rgba(255, 255, 255, 0.65);
}

.v3BrandsContainer {
    max-width: 1200px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}
.desktopHidden {
    display: none;
}

@media only screen and (max-width: 850px) {
    .v3ContactImage {
        display: none;
        align-items: center;
        width: 90%;
    }
    .v3ContactHomeContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 8%;
        padding-right: 8%;
        width: auto;
        flex-shrink: 1;
    }

    .v3ContactUsContainer {
        background-image: url('/assets/landing/v3/contact-bg-mobile.png');
    }
    .v3ContactUsForm {
        margin: auto;
        display: flex;
        width: auto;
        min-width: 300px;
        height: auto;
        border-radius: 15px;
        background-color: transparent;
    }
    .v3ContactUsTag {
        font-size: 34px;
        font-weight: 700;
        color: #ffffff;
        padding-bottom: 16px;
        text-align: center;
    }
    .v3ContactUsText {
        font-size: 16px;
        width: auto;
        margin: auto;
        max-width: 300px;
        text-align: center;
        font-weight: 400;
        padding-bottom: 0;
    }
    .v3CompleteIcon {
        display: flex;
        align-items: center;
        background: url('/assets/landing/v3/complete-icon-white.svg');
        height: auto;
    }
    .v3CompleteText {
        padding-top: 8px;
        font-size: 18px;
        width: auto;
        text-align: center;
        font-weight: 500;
        color: #fff;
        font-family: 'Inter';
        padding-bottom: 54px;
    }
    .v3CompleteContainer {
        height: auto;
        width: 300px;
    }
    .v3BrandsContainer {
        display: none;
    }
    .mobileHidden {
        display: none;
    }
}

a {
    text-decoration: none;
}

.landmark {
    width: 250px;

    border-radius: 5px;

    font-family: Helvetica, Arial, sans-serif;
    -webkit-transform: translate(-67%, -75px);
            transform: translate(-67%, -75px);
}

.landmark h1 {
}

.landmark section {
}

.landmark section p {
}

.landmark:after {
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    margin-left: 50%;
    border-top: 20px solid #ffffff;
}

.mapContainer {
    flex-grow: 5;
}

.restaurantList {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    top: 0;
    left: 0;
    background-color: #fff;
    height: 100vh;
    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.29);
    overflow: auto;
    /* cursor: pointer; */
    align-items: center;
    min-width: 350px;
    max-width: 500px;
    width: 20%;
}

.restaurantList img {
    padding: 52px 0;
    max-width: 180px;
}

.restaurantList h1 {
    font-size: 23px;
    margin: 10px;
    margin-bottom: 0px;
    margin-top: 20px;
    font-weight: 600;
    color: #fff;
}

.restaurantList p {
    color: #555;
    font-size: 15px;
    margin-top: 0;
    font-weight: 500;
    margin-left: 10px;
    margin-bottom: 0;
    color: #20b04b;
}

.markerView .title {
    background-color: #238e44;
    border-radius: 8px;
    color: #ffffff;
    font-size: 14px;
    padding: 10px 15px;
    position: relative;
}

.markerView .title::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #238e44;
}

.markerView .details {
    display: none;
}

.markerView.highlight .details {
    display: block;
}

.markerView.highlight .title {
    display: none;
}

.restaurantItem {
    width: calc(100% - 60px);
    margin: 10px;
    background-color: #ffffff;
    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.restaurantItemAnnotation {
    margin: 0;
    width: 100%;
}

.restaurantItemAnnotation::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #ffffff;
}

.restaurantItem + .restaurantItem {
    margin-top: 0;
}

.restaurantItem h1 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #207e2b;
}

.restaurantItem section {
    font-size: 13px;
    color: #555;
    font-weight: 400;
    margin-top: 5px;
}

.restaurantItem:first-of-type {
    margin-top: 20px;
}

.restaurantLocationPickerContainer {
    display: flex;
    height: 100vh;
    width: 100%;
}

.restaurantLocationActionContainer {
    display: flex;
}

.restaurantLocationActionButton {
    width: calc(50% - 5px);
    text-align: center;
    margin-top: 10px;
    background-color: #f9f9f9;
    border: 1px solid #eaeaea;
    border-radius: 5px;
    margin-right: 5px;
    color: #207e2b;
    font-weight: 500;
    padding: 5px;
    transition: 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}
.radiusList {
    position: relative;
    background-color: #ffffff;
    width: 100px;
    margin-left: 10px;
}

.restaurantLocationOrderButton {
    width: calc(50% - 5px);
    text-align: center;
    margin-top: 10px;
    background-color: #207e2b;
    border-radius: 5px;
    margin-right: 5px;
    color: #ffffff;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
}

.restaurantLocationOrderButton2 {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    background-color: #207e2b;
    border-radius: 5px;
    margin-right: 5px;
    color: #ffffff;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: 0.2s ease-in-out;
    display: block;
    align-items: center;
    justify-content: center;
}

.restaurantLocationActionButton:hover {
    opacity: 0.6;
}

.restaurantLocationActionButton:last-of-type {
    margin-right: 0;
}

.restaurantSearchbarContainer {
    width: calc(100% - 30px);
    display: flex;
    position: relative;
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid rgb(200, 200, 200);
}

.restaurantSearchbarContainer .deliveryType {
    margin-left: 0;
    position: relative;
}
.restaurantSearchbarContainer .deliveryList {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    background-color: white;
    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.2);
    z-index: 10;
}

.restaurantDropdownButtonClosed {
    height: 43px;
    text-align: center;
    align-items: center;
    border-style: solid;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-width: 1px 0px 1px 1px;
    border-color: #eaeaea;
    font-weight: 500;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin: auto;
    width: 105px;
}

.restaurantDeliveryButton2 {
    height: 43px;
    width: 105px;
    text-align: center;
    align-items: center;
    border-style: solid;
    border-top-left-radius: 5px;
    border-width: 1px;
    border-color: #eaeaea;
    font-weight: 500;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.restaurantDeliveryButton3 {
    height: 33px;
    width: 105px;
    text-align: center;
    align-items: center;
    border-style: solid;
    border-bottom-left-radius: 5px;
    border-width: 1px;
    border-color: #eaeaea;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.searchRadiusButton {
    height: 43px;
    text-align: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: #eaeaea;
    font-weight: 500;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin: auto;
}

.searchRadiusButton2 {
    height: 33px;
    align-items: center;
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-color: #eaeaea;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.restaurantDeliveryButton2:hover {
    background-color: rgba(32, 126, 43, 0.1);
}

.restaurantDeliveryButton3:hover {
    background-color: rgba(32, 126, 43, 0.1);
}

.searchRadiusButton2:hover {
    background-color: rgba(32, 126, 43, 0.1);
}

.useLocation {
    padding-top: 5px;
    color: #797979;
    cursor: pointer;
    text-decoration: underline;
    width: calc(100% - 30px);
}

.dayOfTheWeekText {
    float: left;
    width: 35%;
}

.openHoursText {
    width: 120%;
}

.openHoursSection {
    border-top: 1px solid rgb(200, 200, 200);
    border-bottom: 1px solid rgb(200, 200, 200);
    padding-top: 2px;
    padding-bottom: 5px;
}

.noResultsTag {
    font-size: 16px;
    color: black;
    font-weight: 500;
    padding-top: 16px;
}
.noResultsText {
    font-size: 16px;
    color: grey;
}
.gkLogo {
    max-width: 100%;
    max-height: 100%;
    display: flex;
}
.commingSoonText {
    background-color: #f9f9f9;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    color: #666666;
    font-weight: 600;
    margin-top: 10px;
    border: 1px solid #eaeaea;
    flex: 1 1;
}
.restaurantSearchbarButton {
    /* float: left; */
    width: 105px;
    background-color: #ffffff;
    z-index: 1;
}

.restaurantDistanceText {
    font-size: 13px;
    color: #a6a6a6;
    float: right;
    padding-top: 3px;
}
.openHoursContianer {
    border-top: 1px solid rgb(200, 200, 200);
    border-bottom: 1px solid rgb(200, 200, 200);
    padding-top: 2px;
    padding-bottom: 5px;
}
.currentTimeText {
    font-weight: 500;
    color: black;
}
.noResultsContainer {
    padding: 18px 18px;
}

.markerTag {
    background-color: #238e44;
    border-radius: 8px;
    color: #ffffff;
    font-size: 14px;
    padding: 10px 15px;
    position: relative;
}

.markerTag::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #238e44;
}

@media only screen and (max-width: 600px) {
    .restautantsAddressText {
        display: flex;
        width: 85%;
    }
    .backIconContainer {
        background-color: #fff;
        height: auto;
        position: relative;
        box-shadow: 0px -10px 8px rgba(0, 0, 0, 0.2);
        padding: 16px 12px 6px;
    }
    .headerAndMapContainer {
        display: -webkit-flex;
        position: -webkit-sticky;
        position: sticky;
        display: flex;
        flex-direction: column;
        left: 0;
        top: 0;
        height: 100%;
    }
    .useLocationRoundButton {
        position: absolute;
        width: 41px;
        height: 41px;
        left: 32px;
        bottom: 5%;
        border-radius: 50%;
        z-index: 100;
        background: #ffffff;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    }
    .noResultsContainer {
        padding: 32px 18px;
    }
    .noResultsTag {
        font-size: 16px;
        color: black;
        font-weight: 500;
        padding-top: 0;
        padding-left: 0;
        padding-bottom: 8px;
    }
    .noResultsText {
        font-size: 14px;
        color: grey;
        padding-left: 0;
    }

    .restaurantList img {
        display: none;
    }

    .restaurantList p {
        display: none;
    }

    .mapContainer {
        display: flex;
        position: sticky;
        position: -webkit-sticky;
        height: 100%;
    }
    .mobileSearchContent {
        margin: auto;
        padding: 18px;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .restaurantList {
        flex-grow: 1;
        width: 100%;
        max-width: 100%;
        background-color: #fff;
        height: 100vh;
        box-shadow: 0px 0px;
        /* cursor: pointer; */
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
    }

    .restaurantList img {
        padding: 20px;
        max-width: 180px;
    }

    .restaurantList h1 {
        font-size: 23px;
        margin: 10px;
        margin-bottom: 0px;
        margin-top: 20spx;
        font-weight: 600;
        color: #fff;
    }

    .restaurantList p {
        color: #555;
        font-size: 15px;
        margin-top: 0;
        font-weight: 500;
        margin-left: 10px;
        margin-bottom: 0;
        color: #20b04b;
    }

    .restaurantItem {
        justify-content: center;
        align-content: center;
        display: flex;
        flex-direction: column;
        background-color: #ffffff;
        padding: 14 px;
        margin: 0;
        box-shadow: 0px 0px;
        border-radius: 0 px;
        cursor: pointer;
        transition: 0.2s ease-in-out;
        flex: 0 0 auto;
    }

    .restaurantItem + .restaurantItem {
        margin-top: 0;
    }

    .restaurantItem h1 {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        color: #207e2b;
    }

    .restaurantItem section {
        font-size: 14px;
        color: #555;
        font-weight: 400;
        margin-top: 5px;
    }

    .restaurantItem:first-of-type {
        margin-top: 0px;
    }

    .restaurantItem:last-of-type {
        margin-bottom: 0px;
    }

    .restaurantLocationPickerContainer {
        display: -webkit-flex;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        height: 100vh;
        top: 0;
        left: 0;
        max-width: 100%;
    }

    .restaurantLocationActionContainer {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }

    .restaurantLocationActionButton {
        width: calc(50% - 10px);
        height: 45px;
        text-align: center;
        margin-top: 10px;
        background-color: #f9f9f9;
        border: 1px solid #eaeaea;
        border-radius: 5px;
        margin-right: 5px;
        color: #207e2b;
        font-weight: 500;
        padding-top: 5px;
        padding-bottom: 5px;
        transition: 0.2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 3x 3px 10px rgba(0, 0, 0, 0.2);
    }
    .restaurantOrderButtonContainer {
        display: flex;
        width: 100%;
        height: auto;
        margin: auto;
    }

    .restaurantLocationOrderButton {
        width: calc(50% - 5px);
        text-align: center;
        margin-top: 10px;
        background-color: #207e2b;
        border-radius: 5px;
        margin-right: 5px;
        color: #ffffff;
        font-weight: 500;
        padding-top: 5px;
        padding-bottom: 5px;
        transition: 0.2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 3x 3px 10px rgba(0, 0, 0, 0.2);
    }

    .restaurantLocationOrderButton2 {
        width: 100%;
        height: 45px;
        text-align: center;
        margin-top: 10px;
        background-color: #207e2b;
        border-radius: 5px;
        margin-right: 0px;
        color: #ffffff;
        font-weight: 500;
        padding-top: 5px;
        padding-bottom: 5px;
        transition: 0.2s ease-in-out;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 3x 3px 10px rgba(0, 0, 0, 0.2);
    }

    .restaurantLocationActionButton:hover {
        opacity: 0.6;
    }

    .restaurantLocationActionButton:last-of-type {
        margin-right: 0;
    }

    .restaurantDropdownButtonClosed {
        height: 20px;
        width: 50%;
        float: left;
        text-align: center;
        font-size: 75%;
        border-style: solid;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-width: 0px;
        border-color: #eaeaea;
        font-weight: 500;
        font-size: 14px;
        color: #606060;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    .restaurantDeliveryButton2 {
        height: 33px;
        text-align: center;
        font-size: 75%;
        border-style: solid;
        border-top-left-radius: 5px;
        border-width: 1px;
        border-color: #eaeaea;
        font-weight: 500;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    .restaurantDeliveryButton3 {
        height: 33px;
        text-align: center;
        font-size: 75%;
        border-style: solid;
        border-bottom-left-radius: 5px;
        border-width: 1px;
        border-color: #eaeaea;
        font-weight: 500;
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    .searchRadiusButton {
        height: 33px;
        text-align: center;
        font-size: 75%;
        border-style: solid;
        border-width: 1px;
        border-color: #eaeaea;
        font-weight: 500;
        padding-top: 5px;
        padding-bottom: 5px;
        display: block;
        justify-content: center;
        cursor: pointer;
    }

    .restaurantDeliveryButton2:hover {
        background-color: rgba(32, 126, 43, 0.1);
    }

    .restaurantDeliveryButton3:hover {
        background-color: rgba(32, 126, 43, 0.1);
    }

    .useLocation {
        padding: 11px;
        color: #797979;
        cursor: pointer;
        text-decoration: underline;
    }

    .dayOfTheWeekText {
        float: left;
        width: 35%;
        color: #555;
    }

    .openHoursText {
        width: 80%;
        color: #555;
        margin: auto;
    }

    .logoBar {
        flex-grow: 0;
        top: 0;
        left: 0;
        background-color: #fff;
        height: 5vh;
        /* cursor: pointer; */
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding-top: 18px;
        justify-content: space-between;
    }
    .logoBarMapOpen {
        flex-grow: 0;
        top: 0;
        left: 0;
        background-color: #fff;
        height: 45px;
        /* cursor: pointer; */
        z-index: 100;
        position: sticky;
        position: -webkit-sticky;
        flex-direction: row;
        align-items: center;
        padding-top: 18px;
        justify-content: center;
        width: auto;
        display: flex;
        float: left;
        padding-bottom: 12px;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    }
    .gkLogoContainer {
        width: 20%;
        display: flex;
        margin: auto;
        padding: 0px;
    }
    .noResultsContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
    }
    .mobileListLogo {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
    .searchIcon {
        margin-left: 14px;
        color: #b4b4b4;
    }
    .useLocationIcon {
        color: #207e2b;
        padding-right: 14px;
    }
    .useLocationMobileList {
        color: #797979;
        cursor: pointer;
        max-height: 100%;
        width: auto;
        display: flex;
        padding-right: 14px;
    }
    .restaurantAnimationContainer {
        margin-top: 8px;
        background-color: #fff;
        padding-bottom: 52px;
    }

    .restaurantAnntationToday {
        font-weight: 500;
        color: #000;
        padding-top: 12px;
    }
}

.nuveiIframe {
    width: 100%;
    height: calc(100vh - 64px);
    border: 0;
    flex-grow: 1;
}

.nuveiPaymentContainer {
    display: flex;
    flex-direction: column;
}

.nuveiPaymentComplete {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.nuveiPaymentCompleteText {
    margin-top: 10px;
    font-weight: 500;
    color: #333;
    font-family: 'Roboto';
    padding: 20px;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ripple div {
    position: absolute;
    border: 4px solid rgb(16, 111, 189);
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
}
@-webkit-keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.receipt-container {
    padding: 30px;
}

.rating-container {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.rating-control {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.address-container {
    font-family: 'Roboto';
    font-size: 13px;
    font-weight: 300;
    text-align: center;
    padding-top: 30px;
}

.rating-icons {
    padding-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.rating-icon {
    display: flex;
    flex-direction: column;
    margin-left: 5px;
    margin-right: 5px;
}

.rating-icon > a {
    color: inherit;
}

.receiptLogoContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.receiptLogo {
    display: block;
    height: 80px;
    width: calc(80%);
    margin: 10px;
    object-fit: contain;
    margin-bottom: 40px;
}

.receipt-powered-by {
    margin-top: 20px;
    font-weight: 400;
    font-size: 12px;
    color: #333;
    text-align: center;
}

@media (max-width: 640px) {
    .rating-control {
        flex-direction: column;
    }
}

.yourOrderContainer {
    padding-left: 20px;
    margin-right: 30px;
}
.yourOrderTitle {
    font-size: 24px;
    font-family: 'Roboto';
    font-weight: 400;
    color: #758688;

    margin-top: 30px;
    margin-bottom: 30px;
}

.yourOrderDescription {
    font-family: 'Lato';
    font-size: 16px;
    font-style: italic;
    color: #757575;

    margin-bottom: 60px;
}

.yourOrderItemContainer {
    margin-bottom: 20px;
}

.yourOrderItemRow {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 300;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.yourOrderItemModifier {
    margin-left: 20px;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 300;
}

.thumbIcon {
    display: inline-block;
    margin-left: 5px;
}

.thumbsUp {
    color: green;
}

.thumbsDown {
    color: red;
}

.PAGItem {
    font-size: 14px;
    color: #758688;
}

.originalPrice {
    text-decoration: line-through;
}

.yourOrderItemPriceContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

/* .yourOrderItemLabel {

}

.yourOrderItemPrice {
    
} */

@media only screen and (min-width: 700px) {
    .yourOrderContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .yourOrderTitle {
        min-width: 700px;
    }
    .yourOrderDescription {
        min-width: 700px;
    }
    .yourOrderItemRow {
        min-width: 700px;
    }
}

.orderDetailsTrigger {
    margin: 30px;
    color: #333;
    cursor: pointer;
    transition: 0.3s ease;
    font-size: 12px;
    font-weight: 500;
}

.orderDetailsTrigger:hover {
}

.orderDetailsContainer {
    margin-top: 20px;
}

.discountCodeTextInput {
    padding: 10px;
    border: 1px solid #aaa;
    border-radius: 10px;
    font-family: 'Roboto Mono';
    width: 100px;
}

.discountCodeContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9001;
    background-color: #fff;
}

.enterDiscountCodeButton {
    display: flex;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 5px;
    padding: 10px;
    /* background-color: #469F5E; */
    /* box-shadow: 0 0 30px rgba(0,0,0,0.2); */
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 5px;
    text-align: left;
    font-weight: 500;
    cursor: pointer;

    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease;
    font-size: 12px;
    font-weight: 500;
    padding: 10px;
    color: #555;
}

.discountCodePrompt {
    padding: 20px;
}

.discountCodeInput {
    width: calc(100% - 20px);
    background-color: #f7f7f7;
    border: 0;
    border-radius: 5px;
    border-bottom: 5px solid #dadada;
    font-size: 18px;
    font-family: 'Roboto Mono';
    padding: 10px;
    margin-bottom: 38px;
    text-align: center;
}

.discountCodeLabel {
    margin-top: 10px;
    margin-bottom: 10px;
}

.discountCodePromptHeader {
    font-size: 32px;
    font-weight: 600;
    margin-top: 50px;
}

.margin-zero {
    margin: 0;
}

.button {
    color: #2d2d2d;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: 0.5s ease;
}

.disabled {
    pointer-events: none;
    background-color: #eee;
}

.button:hover {
    cursor: pointer;
    background-color: '#ddd';
}

.button:active {
    background-color: '#eee';
}

.rightArrow {
    height: 7px;
}

.rightAlign {
    display: flex;
    flex-direction: row;
}

.priceText {
    margin-right: 10px;
    font-size: 17px;
}

.button3 {
    flex-grow: 1;
    /* Padding for interior items*/
    /* Border */
    /* border: 1px solid #DFDFDF;
    border-radius: 11px;
    box-sizing: border-box; */
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 13px;
    padding-bottom: 13px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    transition: 0.3s ease;
}

.button3:hover {
    background-color: #eee;
}

.button3 + .button3 {
    border-top: none;
}

.buttonText {
    font-family: 'Lato';
    font-size: 18px;
    font-weight: 600;
    color: #647577;
}

.buttonGroupContainer {
    width: 100%;
}

@media only screen and (min-width: 700px) {
    .buttonGroupContainer {
        width: auto;
    }
    .button {
        max-width: 700px;
        width: 700px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px 10px 0px 0px;
    }
    .button + .button {
        /* border: 1px solid #ccc; */
        border-radius: 0px 0px 0px 0px;
    }
    .button:last-of-type {
        border-radius: 0px 0px 10px 10px;
    }
    .button:last-of-type.button:first-of-type {
        border-radius: 10px 10px 10px 10px;
    }
    .button3 {
        border-radius: 10px 10px 0px 0px;
    }
    .button3 + .button3 {
        /* border: 1px solid #ccc; */
        border-radius: 0px 0px 0px 0px;
    }
    .button:last-of-type {
        border-radius: 0px 0px 10px 10px;
    }
    .button3:last-of-type.button3:first-of-type {
        border-radius: 10px 10px 10px 10px;
    }
}

.Container {
    /* display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    align-items: center; */
}

.seperator {
    margin: 40px;
}

.greeting {
    margin-top: 30px;
    margin-bottom: 80px;
    padding-left: 20px;
    margin-right: 30px;
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 18px;
    font-style: italic;
    color: #758688;
}

.restaurantLogo {
    margin-left: 20px;
    width: 100px;
}

.restaurantName {
    margin-left: 20px;
    margin-top: 10px;
    font-family: 'Roboto';
    font-size: 34px;
    font-weight: 800;
    color: #fefefe;
    line-height: 1;
}

.instructionsText {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    color: #fefefe;
    margin-left: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.guestListContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Container {
    width: 100%;
    padding-bottom: 20px;
}

.errorMessage {
    font-family: 'Roboto';
    color: #758688;
    margin: 10px;
    margin-bottom: 20px;
    padding-left: 20px;
    margin-right: 30px;
}

.textBox {
    /* To override styling */
    background: none;
    border: none;
    outline: none;
    box-sizing: border-box;

    flex: 1 1;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 20px;
    margin-left: 20px;

    border: 1.2px solid #eee;
    box-sizing: border-box;

    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #758688;
    padding: 15px;
    padding-top: 16px;
    padding-bottom: 16px;

    background-color: #eee;
    transition: 0.3s ease;

    border-radius: 5px;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.textBox:focus {
    background-color: #fff;
    border: 1.2px solid #a9b3b5;
    box-sizing: border-box;
}

.pageTitle {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    color: #758688;

    padding-left: 20px;
    margin-right: 30px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.pageDescription {
    font-family: 'Lato';

    padding-left: 20px;
    margin-right: 30px;

    margin-top: 40px;
    margin-bottom: 40px;

    font-size: 16px;
    color: #758688;
    font-style: italic;
}

.welcome {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    margin-top: 70px;
    margin-bottom: 70px;
}
.welcome-greeting {
    font-family: 'Playfair Display';
    font-style: italic;
    font-size: 18px;
    color: #758688;
}
.welcome-name {
    font-family: 'Roboto';
    font-size: 18px;
    color: #758688;
    font-weight: 300;
    margin-top: 8px;
}

.guestHomeContainer {
    background-color: #24212d;
    padding-bottom: 20px;
}

.guestHomeButtonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.creditCardContainer {
    margin-top: 80px;
    display: flex;
    justify-content: center;
}

.paymentMethodIcons {
    height: 30px;
}

.guestHomeHeaderImage {
    width: 100%;
    border-radius: 12px;
}

.homeHeaderContainer {
    margin: 20px;
}

@-webkit-keyframes healthFormFadeIn {
    0% {
        opacity: 0;
        -webkit-backdrop-filter: saturate(100%) blur(0px);
                backdrop-filter: saturate(100%) blur(0px);
    }
    100% {
        opacity: 1;
        -webkit-backdrop-filter: saturate(150%) blur(15px);
                backdrop-filter: saturate(150%) blur(15px);
    }
}
@keyframes healthFormFadeIn {
    0% {
        opacity: 0;
        -webkit-backdrop-filter: saturate(100%) blur(0px);
                backdrop-filter: saturate(100%) blur(0px);
    }
    100% {
        opacity: 1;
        -webkit-backdrop-filter: saturate(150%) blur(15px);
                backdrop-filter: saturate(150%) blur(15px);
    }
}
.healthAndSafetyBackground {
    position: fixed;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    z-index: 9998;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    -webkit-backdrop-filter: saturate(150%) blur(15px);

            backdrop-filter: saturate(150%) blur(15px);
    background-color: rgba(0, 0, 0, 0.4);

    -webkit-animation: 1s ease healthFormFadeIn;

            animation: 1s ease healthFormFadeIn;
}
.healthAndSafetyContainer {
    position: fixed;
    z-index: 9999;
    max-width: 600px;

    background-color: #fff;
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    margin-top: 25px;
    width: calc(100% - 60px);
    /* height: calc(100% - 100px); */
    border-radius: 20px;
    padding-bottom: 50px;
}

.healthAndSafetyHeader {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.has-left {
    display: flex;
    align-items: center;
}
.has-heart {
    width: 23px;
}
.has-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 106.69%;
    /* or 19px */
    color: #d7443e;
    margin-left: 5px;
}
.has-header-button {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 106.69%;

    display: inline-block;
    width: 50px;
    /* identical to box height, or 16px */

    text-align: center;

    color: #0085ff;
    cursor: pointer;
}

.has-description {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 125.19%;
    /* or 18px */

    text-align: center;
    letter-spacing: 0.04em;

    color: #000000;

    padding: 20px;
}

.has-description-long {
    margin-top: 20px;
    white-space: pre-wrap;
    text-align: left;
}

.scrollFormContainer {
    overflow-y: scroll;
    height: 400px;
}

.has-form-input-container {
    padding: 20px;
    padding-right: 10px;
    width: 100%;
}
.has-form-input-container + .has-form-input-container {
    padding-left: 0;
}
.has-form-input {
    min-width: 0px;
    width: calc(100% - 40px);
    padding: 15px;
    background: #f7f7f7;
    border-radius: 5px;
    border: 0;
    margin-top: 5px;
    font-size: 18px;

    border-bottom: 5px solid #dadada;
}

.has-form-input + .has-form-input {
    width: calc(100% - 40px);
}

.has-form-multiple {
    display: flex;
}

.PhoneInputCountrySelectArrow {
    visibility: hidden;
}

.errorMessage {
    color: red;
    margin: 0;
    padding: 0;
    margin-left: 20px;
}

.privacyLabel {
    color: #0085ff;
    cursor: pointer;
}

.privacyPolicyContainer {
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 14px;
}

.has-thank-you {
    font-size: 36px;
    font-weight: 100;
    font-family: 'Roboto';
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.has-thank-you-image {
    background-image: url('/assets/landing/covid-art.jpg');
    width: 100%;
    height: 300px;
    background-position: 50% 20%;
    background-size: cover;
}

.PhoneInputInput {
    min-width: 50px;
    width: 50px;
}

.healthSpinner {
    width: 25px;
    height: 25px;
    margin-left: 25px;
    position: relative;
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #999;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 1s infinite ease-in-out;
    animation: sk-bounce 1s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

.al {
    text-align: left;
}
.ar {
    text-align: right;
}

/* Closed.js */

.closedContainer {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.closedText {
    text-align: center;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 26px;
    padding: 20px;
}

.closedLogo {
    width: 70%;
}

/* Override Banner Colors */

/* .Toastify__toast-container {
    z-index: 999;
} */

@media only screen and (max-width: 700px) {
    .Toastify__toast-container--top-right {
        width: calc(100% - 40px) !important;
        margin: 20px !important;
        border-radius: 10px !important;
    }
}

.toastCheckmark {
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    margin: 5px;
    margin-right: 15px;
}

.toastSuccessContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Toastify__toast-body {
    padding: 10px;
    text-align: left;
    font-family: 'Roboto';
    font-weight: 400;
}

.Toastify__toast--default {
    background: #fff;
    color: #aaa;
}

.Toastify__toast--info {
    background: #3498db;
}

.Toastify__toast--success {
    -webkit-backdrop-filter: blur(10px) saturate(250%);
            backdrop-filter: blur(10px) saturate(250%);
    background: rgba(66, 143, 40, 0.9) !important;
}

.Toastify__toast--warning {
    padding: 5px;
    background: #f1c40f !important;
}

.Toastify__toast--error {
    padding: 5px;
    background: #8d071d !important;
}

/* Override Phone Input */

.PhoneInput {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
}

.PhoneInputInput {
    /* The phone number input stretches to fill all empty space */
    flex: 1 1;
    /* The phone number input should shrink
	   to make room for the extension input */
    min-width: 0;
    padding: 3px;
    font-size: 20px;
    height: 30px;
    text-align: center;
    border: none;
    background-color: inherit;
}

/* Pay.js */

.youPayContainer {
    padding: 20px;
}

.youPayLabel {
    font-style: normal;
    font-size: 24px;
    line-height: 44px;
    /* identical to box height */
    color: #2b2b2b;
    letter-spacing: -0.05em;
}

.youPayPrice {
    font-style: normal;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: -0.05em;
    color: #444;
    /* identical to box height */
}

.applePayContainer {
}

.applePayLabel {
    padding-bottom: 10px;
}

.payAtCounterContainer {
    position: relative;
    bottom: env(safe-area-inset-bottom);
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.payAtCounterText {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #252525;
    margin-bottom: 18px;
    max-width: 200px;
}

.payAtCounterButton {
    background: #121212;
    border-radius: 4px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    color: #fff;
    width: calc(100% - 40px);
    margin-bottom: 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s ease;
}

.payAtCounterButton:hover {
    opacity: 0.8;
}

/* Override toast */

.Toastify__toast--success {
    padding: 5px;
}

.Toastify__toast--warning {
    padding: 5px;
}

/* AddedToOrder.js */

.addedToOrderDismissPrompt {
    position: absolute;
    bottom: 50px;
    margin-top: 20px;
    color: #999;
}

/* PaymentComplete*/

.startNewOrderButton {
    background-color: #3bad4d;
    color: #fff;
    width: 100%;
    height: 100px;
    font-size: 30px;
    font-weight: 600;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    bottom: 0;
    left: 0;
    cursor: pointer;
}

.textReceiptSubmit {
    text-align: center;
    width: 100%;
    background: #d03838;
    border-radius: 4px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    color: #ffffff;
    cursor: pointer;
}

.textReceiptSubmitDisabled {
    background: #cbcbcb;
    color: #000;
    cursor: default;
}

.paymentCompleteCodeBold {
    font-weight: 700;
    font-size: 36px;
    color: #333;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 80px;
    /* identical to box height */
    color: #3c3c3c;
}

.paymentCompleteThankYou {
    font-style: normal;
    font-size: 36px;
    line-height: 44px;
    /* identical to box height */
    color: #222;
    margin-bottom: 40px;
}

.phoneNumberNewFeature {
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    background-color: rgb(47, 153, 47);
    color: #fff;
    margin-right: 5px;
}

.paymentCompleteContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 120px;
}

.hiltonCompleteMsg {
    margin-bottom: 40px;
    font-size: 18px;
}

.hilton-complete-msg-bold {
    font-weight: 800;
}

.paymentCompleteCheckmark {
    width: 60px;
}

.paymentCompleteText {
    color: #758688;
}

.paymentCompleteOrderReadyTime {
    padding: 20px;
    margin-bottom: 30px;
    background-color: #eee;
    font-weight: 600;
    text-align: center;
}

.paymentCompleteDescription {
    color: #758688;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #3f3f3f;
}

.phoneNumberPrompt {
    text-align: center;
    color: #333;
    font-size: 16px;
    padding: 30px 50px 10px 50px;
}

.textReceiptContainer {
    background-color: #f3f3f3;
    /* position: fixed; */
    /* bottom: env(safe-area-inset-bottom); */
    padding: 20px;
    width: calc(100% - 40px);
}

.textReceiptNewLabel {
    display: inline-block;
    background-color: #d03838;
    padding: 2px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    color: #ffffff;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.textReceiptTitle {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #1b1b1b;
    margin-top: 7px;
    margin-bottom: 7px;
}

.textReceiptDescription {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #1b1b1b;
}

/* PaymentMethods.js */

.paymentContainer {
    margin: 20px;
}

.paymentInstructionsLabel {
    margin: 20px;
    font-family: 'Lato';
    font-size: 18px;
    font-weight: 600;
    color: #747474;
}

.creditCardContainer {
}

.paymentSubmitButton {
    border-radius: 5px;
    margin-top: 10px;
    background: #121212;
    font-family: Roboto;
    color: #ffffff;
    border: 0;
    padding: 12px 12px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    width: 100%;
}

.paymentSubmitButtonGreen {
    font-family: Poppins;
    background-color: #20b04b;
}
.paymentSubmitButtonDisabled {
    background-color: #f0f0f0;
    color: #999;
}
.paymentSubmitButtonAddMore {
    background-color: #f0f0f0;
    color: #333;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

/* complete.js */

.completeContainer {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.completeLogo {
    width: 40%;
    margin-bottom: 30px;
}

.completeThankYouContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
}

.completeThankYouText {
    text-align: center;
    font-family: 'Lato';
    color: #758688;
    font-style: italic;
    font-size: 18px;
    margin: 5px;
}

/*finalizeorderbutton.js*/

.editTakeoutButtonContainer {
    width: 100%;
}
.renderCashDiscountMessage {
    text-align: center;
    position: fixed;
    bottom: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    background-color: rgb(44, 179, 150);
    color: #fff;
}

.editTakoutLabel {
    margin-left: 20px;
    font-weight: 600;
}

.editTakeoutTimeButton {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    border: 1px solid #ddd;
    padding: 20px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #fff;
    color: #000;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    transition: 0.3s ease;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    border: 1px solid #aaa;
}
.editTakeoutTimeButton:hover {
    background-color: #eee;
}
.editTakeoutTimeButton:active {
    background-color: #ddd;
}

.finalizeOrderShoppingIcon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.finalizeItemLabel {
    font-weight: 400;
    color: #424242;
}

.finalizeNuveiWarning {
    font-weight: 700;
    color: #424242;
}

.floatingNotificationContainer {
    background-color: '#000';
    position: fixed;
    bottom: 60px;
    display: flex;
    width: 100%;
    z-index: 999;
}

.floatingNotification {
    display: block;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 10px;
}

.floatButtonRightArrow {
    height: 8px;
    margin-left: 15px;
}

.floatButtonPlus {
    -webkit-filter: brightness(1000%);
            filter: brightness(1000%);
    height: 16px;
}

.floatingButtonItemCount {
    color: #fff;
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 600;
    width: 100px;
    text-align: right;
}

.floatingButtonRight {
    margin-right: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
}

/*finalizeorder.js*/

.finalizeNoOrdersContainer {
    width: 100%;
}

.finalizeNoOrdersPrompt {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    margin-right: 30px;
    font-weight: 500;
    font-size: 24px;
    color: #393939;
}

.finalizeAddItemsButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    border: 2px solid #555;
    color: #555;
    font-weight: 700;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    height: 50px;
    align-items: center;
    transition: 0.3s ease;
}

.finalizeAddItemsButton:hover {
    background-color: #eee;
}

.tipContainer {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
}

.tipTitle {
    color: #758688;
    font-weight: 600;
}

.tipButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 15px;
    flex-wrap: wrap;
}

.tipButton {
    display: block;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid #758688;
    color: #758688;
    margin-right: 10px;
    margin-bottom: 10px;
    transition: 0.5s ease;
    cursor: pointer;
    border-radius: 5px;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tipButton:hover {
    /* background-color: #ddd; */
}

.tipButtonActive {
    display: block;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #758688;
    border: 1px solid rgba(0, 0, 0, 0);
    color: #fff;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.orderTotalDivider {
    display: flex;
    background-color: #eee;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.orderTotalRow {
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
    justify-content: space-between;
    font-family: 'Roboto';
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    align-items: center;
}

.orderTotalLabel {
    font-weight: 400;
    color: #333;
    display: flex;
    align-items: center;
}

.orderTotalPrice {
    font-weight: 400;
    color: #333;
}

.youPay {
    font-weight: 700;
}

.rewardItemPrompt {
    /* width: 80%; */
    width: 90%;
    min-height: 30px;
    padding: 15px 17.5px;
    font-size: 15px;
    border-radius: 7px;
    background-color: #469f5e;
    margin-bottom: 7.5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.rewardItemPrompt:hover {
    cursor: pointer;
}

.rewardItemPromptContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    color: white;
}

.rewardItemReminderModal {
    position: absolute;
    top: 37.5%;
    left: 15%;
    right: 15%;
    bottom: 37.5%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: white;
    z-index: 999;
    overflow: auto;
    border: none;
    border-radius: 8px;
}

.rewardItemReminderOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

.rewardReminderMessage {
    padding: 0 30px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Cormorant Garamond';
    font-weight: 500;
}

.rewardReminderButtonContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.rewardReminderButton {
    font-family: 'Cormorant Garamond';
    background-color: transparent;
    height: 100%;
    font-size: 20px;
    font-weight: 500;
    border: none;
}

.rewardReminderButton:hover {
    cursor: pointer;
}

.rewardReminderButton--proceed {
    color: green;
}

.rewardReminderButton--cancel {
    color: red;
}

/* OrderComplete.js */

.checkCircleOutline {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0rem 1rem 2rem 1rem;
}

/* Tablet UI */

@media only screen and (min-width: 700px) {
    .orderTotalLabel {
        font-size: 20px;
    }
    .orderTotalPrice {
        font-size: 20px;
    }
    .textReceiptContainer {
        width: calc(50%);
    }
    .textReceiptAndPaymentComplete {
        display: flex;
        justify-content: space-between;
    }
}

.bold {
    font-weight: 500;
}

.finalizeOrderContainer {
    padding-left: 20px;
    padding-right: 20px;
}

.finalizeOrderTitle {
    font-size: 24px;
    font-family: 'Roboto';
    font-weight: 600;
    color: #333;
    margin-top: 30px;
    margin-bottom: 30px;
}

.finalizeItemContainer {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    width: 100%;
}

.finalizeItemRow {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.finalizeItemModifier {
    font-family: 'Roboto';
    margin-left: 25px;
    font-size: 15px;
    font-weight: 400;

    margin-top: 5px;
    margin-bottom: 5px;
}

.finalizeItemOptions {
    display: flex;
    margin-top: 10px;
    flex-direction: row;
    justify-content: space-between;
}

.finalizeItemOptions-delete {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease;
    font-size: 12px;
    font-weight: 500;
    color: #555;
}
.finalizeItemOptions-delete:hover {
    background-color: #777;
    color: #fff;
}
.finalizeItemOptions-quantity {
    display: flex;
    margin-left: 20px;
    align-items: center;
    font-size: 12px;
}
.finalizeItemOptions-quantity-minus {
    margin-left: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease;
}
.finalizeItemOptions-quantity-minus:hover {
    background-color: #777;
    color: #fff;
}
.finalizeItemOptions-quantity-value {
    padding: 10px;
}
.finalizeItemOptions-quantity-plus {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease;
}
.finalizeItemOptions-quantity-plus:hover {
    background-color: #777;
    color: #fff;
}
.finalizeOrderMessage {
    padding: 20px;
    font-style: italic;
    color: #555;
    text-align: center;
}

.finalizeOrderPaySummaryContainer {
    width: 100%;
}

.finalizeOrderLoading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.55);
}

.finalizeItemDelete {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-left: 10px;
    border: 1px solid #fff;
    cursor: pointer;
}

.finalizeItemDelete:hover {
    border: 1px solid #333;
}

.finalizeItemDeleteConfirm {
    width: 65px;
    height: 30px;
    margin-right: 10px;
}

.finalizeItemPrice {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    color: #424242;
    margin-left: 10px;
}

/* ItemDetail.js */

.itemPhoto {
    max-height: 300px;
    object-fit: cover;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

@media only screen and (min-width: 700px) {
    .itemPhoto {
        background-size: contain;
        max-height: 300px;
    }
}

.itemMultiplierContainer {
    touch-action: manipulation;
    position: fixed;
    -webkit-backdrop-filter: blur(10px) saturate(250%);
            backdrop-filter: blur(10px) saturate(250%);
    background-color: rgba(255, 255, 255, 0.7);
    bottom: 60px;
    margin-bottom: env(safe-area-inset-bottom);
    width: 100vw;
    display: flex;
    justify-content: space-between;
    border: 2px solid #758688;
    align-items: center;
}

.itemMultiplerMinus {
    font-family: 'Roboto';
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 28px;
    border-right: 2px solid #758688;
    color: #758688;
    transition: 0.2s;
    cursor: pointer;
    transition: 0.3s ease;
}

.itemMultiplerMinus:hover {
    background-color: #eee;
}

/* .itemMultiplerMinus:hover, .itemMultiplerMinus:active {
    background-color: #ddd;
} */

.itemMultiplierAmount {
    color: #758688;
    font-size: 22px;
    font-weight: 700;
}

.itemMultiplierPlus {
    font-family: 'Roboto';
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 2px solid #758688;
    color: #758688;
    font-weight: 700;
    font-size: 24px;
    cursor: pointer;
    transition: 0.3s ease;
}

.itemMultiplierPlus:hover {
    background-color: #eee;
}

/* .itemMultiplierPlus:hover, .itemMultiplierPlus:active {
    background-color: #ddd;
    color: #fff;
} */

.floatingButton {
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    width: 100vw;
    height: 60px;
    background-color: #758688;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    cursor: pointer;
}

.floatingButtonHalfRight {
    width: 60%;
    left: 40%;
    justify-content: flex-end;
}
.floatButtonHalfLeft {
    width: 40%;
    left: 0;
    background-color: #555;
    box-sizing: border-box;
    /* border-right: 1px solid #ccc; */
}

.floatingButtonBag {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.floatingButtonSafeArea {
    position: fixed;
    height: env(safe-area-inset-bottom);
    width: 100vw;
    bottom: 0;
    -webkit-backdrop-filter: blur(3px) saturate(250%);
            backdrop-filter: blur(3px) saturate(250%);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.floatingButtonLabel {
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin-left: 20px;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.floatingButtonAlt {
    position: fixed;
    bottom: 60px;
    width: 100%;
    height: 60px;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.floatingButtonAltLow {
    bottom: 0px;
}

.floatingButtonLabelAlt {
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 600;
    color: #333;
    padding-left: 20px;
}

.center {
    text-align: center;
}

.itemModifierGroupContainer {
    margin: 20px;
    margin-top: 0;
}

.itemModifierGroupName {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.itemModifierRadioRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s ease;
}

.itemModifierRadioSelected {
    background-color: rgb(48, 48, 48);
    color: #fff;
    border-color: rgb(48, 48, 48);
}

.itemModifierRequiredAccent {
    color: red;
}

.itemModifierRadio {
}

.itemModifierRadio:active {
}

radio {
    cursor: pointer;
}

.itemModifierLabel {
    margin-left: 10px;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
}

.itemModifierDescriptionLabel {
    font-size: 15px;
    font-weight: 400;
    margin-top: 5px;
}

.itemDetailsContainer {
    margin-top: 30px;
    padding-left: 20px;
    margin-right: 30px;
}

.headerRow {
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
}

.itemDetailName {
    display: block;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 22px;
    padding-right: 10px;
}

.itemDetailPrice {
    font-family: 'Roboto';
    font-size: 17px;
    font-weight: 400;
}

.itemDetailDescription {
    margin-top: 10px;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
}

.itemSpecialInstructionsContainer {
    margin: 20px;
}

.itemSpecialInstructionsLabel {
}

.itemSpecialInstructionsTextField {
    font-size: 18px;
    font-family: 'Roboto';
    padding: 10px;
    width: calc(100% - 20px);
    margin-top: 10px;
    border-radius: 5px;
    border: 0;
    background-color: #f7f7f7;
    border-bottom: 5px solid #dadada;
    transition: 0.3s ease;
}

.itemSpecialInstructionsTextField:hover {
    border-bottom: 5px solid #999999;
}

.itemSpecialInstructionsTextField:focus {
    border-bottom: 5px solid #999999;
}

.purchaseAndGetCampaignItems {
    font-family: 'Roboto';
    margin-left: 25px;
    font-size: 15px;
    font-weight: 400;

    margin-top: 5px;
    margin-bottom: 5px;
}

/* - Change Quantity */

.changeQuantityContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    margin-right: 20px;
    border: 1px black solid;
    align-items: center;
    justify-content: space-between;
}

.cqLeft {
    padding-left: 15px;
    font-family: 'Lato';
    font-weight: 600;
}

.cqRight {
    display: flex;
    flex-direction: row;
}

.cqMinusContainer {
    width: 40px;
    height: 40px;
    border-left: 1px solid black;
    display: flex;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 30px;
    justify-content: center;
    align-items: center;
}

.cqPlusContainer {
    width: 40px;
    height: 40px;
    border-left: 1px solid black;
    justify-content: center;
    align-items: center;
}

.cqLabel {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 28px;
}

/* OneMenu.js */

@-webkit-keyframes animateBlurIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes animateBlurIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.menuHoursContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 99999;

    -webkit-backdrop-filter: blur(15px) saturate(150%);

            backdrop-filter: blur(15px) saturate(150%);
    -webkit-animation: 0.3s ease-in animateBlurIn;
            animation: 0.3s ease-in animateBlurIn;
}

.menuHours {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #444;
    padding: 20px;
    margin: 20px;
    margin-top: 60px;
    margin-bottom: 60px;
    overflow-y: auto;
}

.menuHoursHours {
    width: 100%;
}

.menuHourInterval {
    margin-left: 20px;
}

.menuHoursTitle {
    width: 100%;
    font-size: 26px;
    font-weight: 600;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
}

.menuHourRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 250px;
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
}
.menuHourRow + .menuHourRow {
    border-top: 1px solid #eee;
}
.menuHourRow:last-of-type {
    margin-bottom: 20px;
}
.menuHourRow-bold {
    background-color: #e2e2e2;
    /* border-radius: 50px; */
}
.menuHourRow-bold:first {
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}
.menuHourRow-bold:last-of-type {
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

.menuHoursFloatRight {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Category.js */

.categoryHeader {
    padding-top: 15px;
    padding-bottom: 35px;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 36px;
    color: #758688;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.categoryHeaderDescriptionContainer {
    font-weight: 700;
    color: #555;
    margin: 10px 0;
    font-size: 18px;
}

.categoryHeaderMenuHours {
    display: inline-block;
    border: 1px solid #fff;
    color: #fff;
    background-color: #0071e3;
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 500;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
    border-radius: 100px;
    cursor: pointer;
    margin: 5px;
}

.categoryHeaderMenuHoursContainer {
    display: flex;
}

.categoryHeaderMenuHours-light {
    background-color: #eee;
    color: #000;
}

.categoryLabel {
    font-family: 'Lato';
    font-weight: 700;
    color: #212529;
}

.items {
    padding-bottom: 80px;
}

.itemFlexContainer {
    display: flex;
    width: 100%;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.itemFlexImage {
    max-width: 75px;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    /* background: image('/assets/noimage.jpg'); */
}

.itemDetailsColumn {
    padding: 20px;
    padding-top: 13px;
    padding-bottom: 13px;
}

.itemContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.itemFlexContainer + .itemFlexContainer {
    border-top: 0;
}

.itemHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.itemName {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
}

.itemDescription {
    font-size: 15px;
    font-weight: 400;
    font-family: 'Roboto';
    margin-top: 5px;
}

.itemRight {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.itemPrice {
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 400;
    margin-top: 8px;
}

/* RestaurantMenus.js */

.menuTitle {
    padding-left: 20px;
    margin-right: 30px;
    font-family: 'Roboto';
    font-size: 24px;
    color: #758688;
    margin-top: 50px;
    margin-bottom: 30px;
}

.menuButton {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 13px;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.menuButton + .menuButton {
    border-top: 0;
}

.menuButtonHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.menuButtonLabel {
    font-family: 'Roboto';
    color: #000;
    font-size: 16px;
    font-weight: 400;
}

.menuButtonRightArrow {
    height: 7px;
}

.category {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    color: #758688;
}

.categoryDescription {
    font-weight: 400;
    font-family: 'Roboto';
    color: #555;
    margin-top: 10px;
    margin-bottom: 10px;
}

.itemMultiplier {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.paymentContainerDesktop {
    width: 100%;
}

.itemDetailMobileBottomPaddingFix {
    padding-bottom: 140px;
}

@media only screen and (min-width: 700px) {
    .itemDetailMobileBottomPaddingFix {
        padding: 0;
    }
    .categoryHeader {
        text-align: center;
    }
    .itemFlexImage {
        border-radius: 5px;
    }
    .menuButton {
        max-width: 700px;
        width: 700px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px 10px 0px 0px;
        transition: 0.3s ease;
    }
    .menuButton:hover {
        background-color: #eee;
    }
    .menuButton + .menuButton {
        /* border: 1px solid #ccc; */
        border-radius: 0px 0px 0px 0px;
    }
    .menuButton:last-of-type {
        border-radius: 0px 0px 10px 10px;
    }
    .menuButton:last-of-type.menuButton:first-of-type {
        border-radius: 10px 10px 10px 10px;
    }
    .menuButtonContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .itemFlexContainer {
        max-width: 700px;
        width: 700px;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 10px 10px 0px 0px;
        transition: 0.3s ease;
    }
    .itemFlexContainer:hover {
        background-color: #eee;
    }
    .itemFlexContainer + .itemFlexContainer {
        border-radius: 0;
    }
    .itemFlexContainer:last-of-type {
        border-radius: 0px 0px 10px 10px;
    }
    .itemFlexContainer:last-of-type.itemFlexContainer:first-of-type {
        border-radius: 10px 10px 10px 10px;
    }
    .itemPhoto {
        width: 50vw;
        min-height: calc(100vh - 120px);
        max-width: 600px;
        background-size: cover;
        background-position: 50% 50%;
    }
    .itemDetailOverflow {
        margin-left: 10px;
        padding-bottom: 50px;
        height: calc(100vh - 140px);
        overflow: auto;
    }
    .itemDetailsDesktopContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .itemDetailsContainer {
        max-width: 300px;
        width: 300px;
    }
    .itemModifierGroupContainer {
        width: 300px;
        max-width: 300px;
    }
    .itemMultiplierContainer {
        max-width: 300px;
        position: inherit;
        margin-top: 20px;
        margin-bottom: 40px;
    }
    .finalizeOrderBackground {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 150px;
    }
    .finalizeOrderContainer {
        min-width: 700px;
        width: 700px;
        padding: 0;
        margin: 0;
    }
    .finalizeNoOrdersContainer {
        min-width: 700px;
        width: 700px;
    }
    .paymentContainerDesktop {
        min-width: 700px;
        max-width: 700px;
    }
    .payAtCounterContainer {
        max-width: 700px;
        min-width: 700px;
    }
    .itemMultiplierContainer {
        border-radius: 10px;
    }
    .itemMultiplerMinus {
        border-radius: 10px 0px 0px 10px;
    }
    .itemMultiplierPlus {
        border-radius: 0px 10px 10px 0px;
    }

    .editTakeoutTimeButton {
        width: calc(700px - 60px);
        margin: 0;
        margin-top: 20px;
    }
    .editTakoutLabel {
        margin: 0;
        color: #555;
    }

    .editTakeoutButtonContainer {
        min-width: 700px;
        max-width: 700px;
    }

    .tipContainer {
        min-width: 700px;
        width: 700px;
    }
    .finalizeOrderPaySummaryContainer {
        min-width: 700px;
        width: 700px;
        width: auto;
    }

    .headerRow {
        padding-top: 60px;
    }

    .rewardReminderMessage {
        padding: 0 40px;
        font-size: 25px;
    }

    .rewardReminderButtonContainer {
        width: 80%;
    }

    .rewardReminderButton {
        font-family: 'Cormorant Garamond';
        background-color: transparent;
        height: 100%;
        font-size: 30px;
        font-weight: 600;
        border: none;
    }

    .rewardItemReminderModal {
        position: absolute;
        top: 37.5%;
        left: 22.5%;
        right: 22.5%;
        bottom: 37.5%;
    }
}

.cssload-container {
    position: relative;
    margin: 50px;
}

.cssload-whirlpool,
.cssload-whirlpool::before,
.cssload-whirlpool::after {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid rgb(204, 204, 204);
    border-left-color: rgb(0, 0, 0);
    border-radius: 749px;
    -o-border-radius: 749px;
    -ms-border-radius: 749px;
    -webkit-border-radius: 749px;
    -moz-border-radius: 749px;
}

.cssload-whirlpool {
    margin: -19px 0 0 -19px;
    height: 37px;
    width: 37px;
    animation: cssload-rotate 1900ms linear infinite;
    -o-animation: cssload-rotate 1900ms linear infinite;
    -ms-animation: cssload-rotate 1900ms linear infinite;
    -webkit-animation: cssload-rotate 1900ms linear infinite;
    -moz-animation: cssload-rotate 1900ms linear infinite;
}

.cssload-whirlpool::before {
    content: '';
    margin: -17px 0 0 -17px;
    height: 33px;
    width: 33px;
    animation: cssload-rotate 1900ms linear infinite;
    -o-animation: cssload-rotate 1900ms linear infinite;
    -ms-animation: cssload-rotate 1900ms linear infinite;
    -webkit-animation: cssload-rotate 1900ms linear infinite;
    -moz-animation: cssload-rotate 1900ms linear infinite;
}

.cssload-whirlpool::after {
    content: '';
    margin: -22px 0 0 -22px;
    height: 42px;
    width: 42px;
    animation: cssload-rotate 3800ms linear infinite;
    -o-animation: cssload-rotate 3800ms linear infinite;
    -ms-animation: cssload-rotate 3800ms linear infinite;
    -webkit-animation: cssload-rotate 3800ms linear infinite;
    -moz-animation: cssload-rotate 3800ms linear infinite;
}

@keyframes cssload-rotate {
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-webkit-keyframes cssload-rotate {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

hr {
    width: 66vw;
    height: 2px;
    color: grey;
    background-color: grey;
    border-width: 0px;
}

.column-container {
    display: flex;
    flex-direction: column;
}

.row-container {
    display: flex;
    flex-direction: row;
}

.center {
    align-items: center;
    /* justify-content: center; */
}

.justify-center {
    justify-content: center;
}

.cccCard {
    flex: 3 1;
}

.cccRowCard {
    margin: 1vw;
    width: 25vw;
}

.payLayout {
    height: 100vh;
    width: 100vw;
    background-color: #f2f2f2;
}
.paymentText {
    font-size: 2.5rem;
    text-align: start;
    font-weight: 500;
    margin: 1rem;
    width: 66vw;
    color: '#999999';
}

#ccnumber input {
    font-family: 'Montserrat';
    background-color: transparent;
    border-width: 0px;
    padding: 0.5rem;
    font-size: 0.8rem;
}

#ccexp input {
    font-family: 'Montserrat';
    background-color: transparent;
    border-width: 0px;
    padding: 0.5rem;
    font-size: 0.8rem;
}

#cvv input {
    font-family: 'Montserrat';
    background-color: transparent;
    border-width: 0px;
    padding: 0.5rem;
    font-size: 0.8rem;
}

#googlepaybutton {
    align-self: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 66vw;
}

#applepaybutton {
    margin-top: 1rem;
    align-self: 'center';
    width: 66vw;
}

.dataBlock {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.dataBlockHeader {
    font-weight: 700;
    color: #272727;
    font-size: 26px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.dataBlockMany {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.gk-restaurant-location-container {
    width: calc(100% - 40px);
    padding: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    color: #333;
}

.gk-restaurant-header {
    font-size: 22px;
}

.gk-restaurant-name {
    font-size: 28px;
    font-weight: 900;
}

.gk-restaurant-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;

    margin-top: 10px;
    cursor: pointer;
}

.gk-restaurant-info-item {
    display: inline-block;
    border-radius: 5px;
    text-align: left;
    font-size: 18px;
    color: rgb(58, 120, 212);
    line-height: 1.5em;
    letter-spacing: 0.03em;
    font-weight: 500;
    text-decoration: underline;
}
.gk-restaurant-info-item + .gk-restaurant-info-item {
    margin-top: 20px;
}

.dataBlockItemContainer {
    position: relative;
    display: inline-block;

    min-width: calc(50vw - 15px);
    width: calc(50vw - 15px);
    height: 150px;

    margin-bottom: 5px;
    cursor: pointer;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    border: 0;
}

.dataBlockImage {
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;

    border-radius: 10px;

    background-size: cover;
    background-position: 50% 50%;
    border: 0;
}

.dataBlockGradient {
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0px;
    border-radius: 5px;
    z-index: 2;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 20.96%,
        rgba(20, 26, 26, 0.5) 70%
    );
    border: 0;
}

.dataBlockMenuText {
    position: absolute;
    max-width: 100%;
    bottom: 10px;
    left: 0;
    padding: 10px;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    z-index: 2;
}

.ghostKitchenLogoButton {
    cursor: pointer;
}

.ghostKitchenLogoButtonComingSoon {
    opacity: 0.5;
}

.ghostKitchenLogoButtonComingSoonText {
    padding: 20px;
    font-weight: 700;
    font-size: 36px;
    border-radius: 20px;
    z-index: 999;
    color: #52b16a;
    text-align: left;
    margin-top: 30px;
    width: calc(100vw - 40px);
}

.gkLogoContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
}

.gkImage {
    width: 106px;
    height: 106px;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    margin: 5px;
}

.gkImageComingSoon {
    -webkit-filter: saturate(0%);
            filter: saturate(0%);
}

.logoViewHeaderImage {
    position: absolute;
    height: 200px;
    width: 100%;
    object-fit: cover;
    background-size: cover;
    border: 0;
    outline: none;
}

.logoViewHeaderText {
    display: none;
}

.logoViewHeaderExpansion {
    border: 0;
    visibility: hidden;
    max-height: 200px;
    position: relative;
}

/* New Blur Design for dataBlock */

.dataBlockItemContainer {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
}

.dataBlockGradient {
    display: none;
}

.dataBlockMenuText {
    -webkit-backdrop-filter: saturate(200%) blur(25px) brightness(90%) contrast(100%);
            backdrop-filter: saturate(200%) blur(25px) brightness(90%) contrast(100%);
    border-radius: 10px;
    max-width: calc(100% - 20px);
    margin-left: 10px;
    margin-right: 10px;

    left: auto;
    bottom: auto;
    bottom: 10px;
    left: 0;

    text-align: left;
}

.featuredCampaigns {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    padding: 0 10px;
}

/* Tablet css */
@media only screen and (min-width: 700px) {
    .logoViewDesktopContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .logoViewHeaderText {
        display: block;
        position: absolute;
        top: 180px;
        text-align: center;
        width: 100%;
        font-size: 48px;
        font-weight: 500;
        left: 0;
    }

    .textGlow {
        /* text-shadow: rgba(0,0,0,1) 0 0 35px; */
        display: inline-block;
        color: #fff;
        -webkit-backdrop-filter: saturate(200%) blur(100px) brightness(100%)
            contrast(100%);
                backdrop-filter: saturate(200%) blur(100px) brightness(100%)
            contrast(100%);
        border-radius: 30px;
        padding: 15px;
        padding-left: 23px;
        padding-right: 23px;
    }

    .logoViewHeaderImage {
        height: 300px;
        /* filter: brightness(50%); */
    }

    .logoViewHeaderExpansion {
        max-height: 300px;
    }

    .dataBlockMany {
        justify-content: center;
    }
    .dataBlockItemContainer {
        min-width: 200px;
        width: 200px;
        margin: 5px;
    }
    .gkLogoContainer {
        justify-content: flex-start;
    }
    .gkImage {
        width: 168px;
        height: 168px;
    }

    .dataBlockItemContainer {
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
        margin: 13px;
        transition: 0.5s ease;
    }

    .dataBlockItemContainer:hover {
        box-shadow: 0px 0px 30px rgba(25, 25, 25, 0.8);
        margin: 13px;
    }

    .dataBlockGradient {
        display: none;
    }

    .dataBlockMenuText {
        -webkit-backdrop-filter: saturate(200%) blur(25px) brightness(90%)
            contrast(100%);
                backdrop-filter: saturate(200%) blur(25px) brightness(90%)
            contrast(100%);
        border-radius: 10px;
        max-width: calc(100% - 20px);
        margin-left: 10px;
        margin-right: 10px;
    }

    .dataBlock {
        display: flex;
        justify-content: center;
    }

    .dataBlockMany {
        max-width: 1200px;
    }
}

.campaignBlockContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid black;
    border-radius: 5px;
    width: 100%;
    margin: 5px 0;
    cursor: pointer;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
    border: 0;
    padding-top: 10px;
    padding-bottom: 10px;
}

.campaignBlockImage {
    height: 45px;
    width: 45px;
    margin: 0 20px;
    z-index: 1;
}

.campaignBlockSeeAll {
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
}

.campaignBlockPromotionLabel {
    margin-top: 5px;
    margin-bottom: 5px;
}

.campaignBlockText {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.campaignBlockTitle {
    font-size: 22px;
    margin-bottom: 3px;
    font-weight: 700;
}

.campaignBlockDescription {
    font-size: 13px;
    margin: 0;
}

/* Tablet css */
@media only screen and (min-width: 700px) {
    .campaignBlockContainer {
        margin: 10px 0;
    }

    .campaignBlockTitle {
        font-size: 22px;
        margin-bottom: 5px;
    }

    .campaignBlockDescription {
        font-size: 16px;
    }
}

.campaignPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10vh;
}

.campaignListContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 50vw;
    width: 85vw;
    margin-bottom: 30px;
}

.campaignListContainer_first {
    border-top: 0;
}

.campaignListContainer_not_first {
    border-top: 2px solid grey;
}

.campaignListTitle {
    align-self: flex-start;
    text-align: left;
    margin: 25px 0;
    font-size: 13px;
}

h1 {
    margin: 0px;
    margin-bottom: 5px;
}

p {
    margin: 0px;
}

/* Tablet css */
@media only screen and (min-width: 700px) {
    .campaignListContainer {
        padding: 30px 0;
        width: 70vw;
        max-width: 850px;
    }

    .campaignListTitle {
        margin: 10px 0 20px 0;
        font-size: 17px;
    }
}

.oneCampaignPage {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.oneCampaignContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85vw;
}

.chooseItemContainer {
    margin-bottom: 30px;
}

.campaignButtonContainer {
    margin-top: 50px;
    margin-bottom: 15vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.campaignInfo {
    min-width: 50vw;
    width: 100%;
    margin: 30px 0;
}

.campaignTitle {
    font-size: 25px;
}

.campaignDescription {
    font-size: 14px;
}

.campaignItem {
    min-width: calc(50vw - 15px);
    width: calc(80vw - 15px);
}

.campaignButton {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(18, 18, 18);
    color: #fff;
    padding: 17.5px 0;
    border: 0;
    font-size: 18px;
    font-weight: 400;
    margin-top: 25px;
}

.oneCampaignInteractiveComponents {
    min-width: calc(50vw - 15px);
    width: 100%;
}

.campaignButtonDisabled {
    background-color: rgb(200, 200, 200);
    color: grey;
}

.campaignItemModalContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10vh 10vw;
    z-index: 9999;
    background-color: white;
    overflow: auto;
}

.campaignItemModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9998;
    background-color: rgba(0, 0, 0, 0.35);
}

.campaignModalButtons {
    display: flex;
}

.Collapsible {
    min-width: calc(50vw - 15px);
    margin-bottom: 15px;
    border: 0;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    background-color: inherit;
}

.Collapsible__trigger {
    font-size: 16px;
}

.campaignItemFlexImage {
    max-width: 70px;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    /* background: image('/assets/noimage.jpg'); */
}

.campaignItemContainer {
    width: calc(100%-30px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid #eee;
    cursor: pointer;
    min-height: 80px;
    padding: 5px 10px 5px 15px;
    margin-bottom: 5px;
}

.campaignItemHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.campaignItemName {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
}

.campaignItemDescription {
    font-size: 15px;
    font-weight: 400;
    font-family: 'Roboto';
    margin-top: 5px;
}

.campaignItemRight {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.campaignItemPrice {
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 400;
    margin-top: 8px;
}

.chooseItemMessage {
    font-weight: 600;
    margin-bottom: 10px;
}

.itemSelectionBlock {
    widows: calc(100%-30px);
    display: flex;
    justify-content: space-between;
    padding: 20px 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    border: 2px solid grey;
}

.itemSelectionBlock:hover {
    cursor: pointer;
}

.itemSelectionTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.itemSelectionIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.itemSelectionIcon {
    height: 20px;
}

.campaignItemGroupModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding: 7.5vh 7.5vw;
    z-index: 999;
    background-color: white;
    overflow: auto;
}

.campaignModalCloseButton {
    position: absolute;
    top: 2vh;
    right: 6vw;
    font-size: 30px;
}

.campaignModalGoBackButton {
    position: absolute;
    top: 3vh;
    left: 6vw;
    font-size: 10px;
    height: 10px;
}

.campaignModalDescription {
    margin-bottom: 30px;
}

@media only screen and (min-width: 700px) {
    .oneCampaignContainer {
        width: 70%;
        max-width: 700px;
    }

    .Collapsible {
        margin-bottom: 30px;
        background-color: inherit;
    }

    .campaignButton {
        margin-bottom: 15vh;
    }
}

.campaignItemModifierGroupContainer {
    width: 100%;
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.campaignItemModifierGroupName {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.campaignItemModifierRequiredAccent {
    color: red;
}

.campaignItemModifierRadioRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s ease;
}

.campaignItemModifierLabel {
    margin-left: 10px;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
}

.campaignItemModifierDescriptionLabel {
    font-size: 15px;
    font-weight: 400;
    margin-top: 5px;
}

.campaignItemSpecialInstructionsTextField {
    width: calc(100% - 20px);
    font-size: 18px;
    font-family: 'Roboto';
    margin-top: 10px;
    border-radius: 5px;
    padding: 10px;
    border: 0;
    background-color: #f7f7f7;
    border-bottom: 5px solid #dadada;
    transition: 0.3s ease;
}

.campaignItemSpecialInstructionsTextField:hover {
    border-bottom: 5px solid #999999;
}

.campaignItemSpecialInstructionsTextField:focus {
    border-bottom: 5px solid #999999;
}

.campaignItemPhoto {
    width: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.campaignItemDetailOverflow {
    margin-left: 10px;
    padding-bottom: 50px;
    height: calc(100vh - 140px);
    overflow: auto;
}

.campaignItemDetailsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.campaignHeaderRow {
    width: 85%;
}

.campaignItemDetailName {
    display: block;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 22px;
    margin: 0;
    margin: 20px 0 10px 0;
}

.campaignItemDetailPrice {
    font-family: 'Roboto';
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 5px;
}

.campaignItemDetailDescription {
    width: 85%;
    margin-bottom: 10px;
    font-size: 17px;
    font-family: 'Roboto';
    font-weight: 400;
}

.campaignModalButtonContainer {
    width: 100%;
    align-self: center;
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.campaignItemSpecialInstructionsContainer {
    width: 100%;
    margin-top: 20px;
}

.ModifierGroupsContainer {
    width: 100%;
}

@media only screen and (min-width: 700px) {
    .campaignItemPhoto {
        background-size: contain;
    }

    .campaignItemDetailsContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 500px;
    }
}

.dineSelectContainer {
    width: 85%;
    margin-left: 7.5%;
    height: 100%;
}

.dineSelectLogo {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    height: 100px;
    object-fit: contain;
}

.dineSelectOptionsContainer {
    display: flex;
    justify-content: space-between;
}

.dineSelectOption {
    background-color: #f2f2f2;
    border-radius: 15px;
    padding: 10px;
    width: 100%;
    min-height: 170px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: 0.3s ease;
    color: #717171;
}
.dineSelectOption:hover {
    background-color: #0084ff;
    color: #fff;
}

.dineSelectOption + .dineSelectOption {
    margin-left: 20px;
}

.dineSelectInnerContainer {
    background-color: #e3e3e3;
    border-radius: 10px;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 9;
    transition: 0.3s ease;
}

.dineSelectIcon {
    width: 60px;
    height: 60px;
}

.dineSelectLabel {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 18px;
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.codeInputContainer {
    -webkit-animation: 0.3s ease-in fadeIn;
            animation: 0.3s ease-in fadeIn;
    margin-top: 20px;
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    align-items: center;
}

.codeInputTextField {
    width: auto;
    min-width: 0;
    border: 0;
    background-color: #fff;
    background: #ffffff;
    border: 3.5px solid #e8e8e8;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 15px;
    padding-left: 22px;
    flex-grow: 3;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 106.69%;
    /* or 19px */
    letter-spacing: -0.005em;
    color: #939393;
    outline: none;
}

.codeInputSubmitButton {
    flex-grow: 1;
    background: #0084ff;
    min-width: 42px;
    min-height: 42px;
    max-width: 42px;
    max-height: 42px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
    cursor: pointer;
    transition: 0.3s ease;
}
.codeInputSubmitButton:hover {
    opacity: 0.8;
}

.codeInputSubmitButtonArrow {
    width: 20px;
    height: 20px;
}

.selectedBack {
    background-color: #52abff;
}
.selectedInner {
    background-color: #0084ff;
}
.selectedLabel {
    color: #fff;
}

.animateSlow {
    transition: 1s ease-out;
}

.TwoFAContainer {
    font-family: 'Roboto';
    font-weight: 300;
    padding-top: 20px;
    padding-bottom: 20px;
}

.TwoFALabelContainer {
    text-align: center;
    font-size: 12px;
    padding-bottom: 20px;
}

.TwoFAResendContainer {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    text-decoration: underline;
    font-weight: 500;
    color: #0066ff;
    cursor: pointer;
}

.TwoFAResendContainer:active {
    text-decoration: none !important;
}

.TwoFAInputField {
    width: calc(100% - 20px);
    border: 0;
    background-color: #f7f7f7;
    border-bottom: 5px solid #dadada;
    font-family: 'Roboto Mono';
    letter-spacing: 0.5em;
    font-size: 26px;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
}
.TwoFAInputField:hover {
    border-bottom: 5px solid #8d8d8d;
}
.TwoFAInputField:focus {
    border-bottom: 5px solid #8d8d8d;
}

.AuthCodeContainer {
    margin-bottom: 5px;
}

.FailureBorder {
    -webkit-animation: wiggle 1s 1;
            animation: wiggle 1s 1;
}

.FailureBorder input {
    border-bottom: 5px solid #d9534f !important;
}

@-webkit-keyframes wiggle {
    10%,
    90% {
        -webkit-transform: translate3d(-1px, 0, 0);
                transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
        -webkit-transform: translate3d(2px, 0, 0);
                transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
        -webkit-transform: translate3d(-4px, 0, 0);
                transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
        -webkit-transform: translate3d(4px, 0, 0);
                transform: translate3d(4px, 0, 0);
    }
}

@keyframes wiggle {
    10%,
    90% {
        -webkit-transform: translate3d(-1px, 0, 0);
                transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
        -webkit-transform: translate3d(2px, 0, 0);
                transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
        -webkit-transform: translate3d(-4px, 0, 0);
                transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
        -webkit-transform: translate3d(4px, 0, 0);
                transform: translate3d(4px, 0, 0);
    }
}

.SuccessBorder input {
    border: 1px solid #28a745 !important;
}

.verifyPhoneNumContainer {
    padding: 20px;
}

.verifyPhoneNumberLabel {
    font-size: 18px;
    margin-top: 10px;
    margin-top: 10px;
}

.verifyPhoneNumberLabel + .verifyPhoneNumberLabel {
    margin-top: 20px;
}

.twoFA-button {
    background-color: #469f5e;
    margin-top: 20px;
    border-radius: 5px;
    padding: 15px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    color: #ffffff;
    font-weight: 500;
    display: flex;
    min-height: 30px;
    max-height: 30px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease;

    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    margin-left: 20px;
    margin-right: 20px;
}

.twoFA-button-cancel {
    background-color: #db4c40;
}

.twoFA-button:hover {
    opacity: 0.8;
}

.phoneNumberStep2BackArrow {
    margin: 20px;
    width: 30px;
}

.phoneNumberBlue {
    color: #0066ff;
    font-weight: 600;
}

.twofa-loader,
.twofa-loader:after {
    border-radius: 50%;
    width: 15px;
    height: 15px;
}

.twofa-loader {
    margin: 60px auto;
    font-size: 10px;
    position: relative;
    text-indent: -9999em;
    border-top: 5px solid rgba(255, 255, 255, 0.2);
    border-right: 5px solid rgba(255, 255, 255, 0.2);
    border-bottom: 5px solid rgba(255, 255, 255, 0.2);
    border-left: 5px solid #ffffff;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
}

.twofa-loader__black {
    /* border-top: 5px solid rgba(255, 255, 255, 0.2);
  border-right: 5px solid rgba(255, 255, 255, 0.2);
  border-bottom: 5px solid rgba(255, 255, 255, 0.2);
  border-left: 5px solid rgba(0, 0, 0, 0.6); */

    border-top: 5px solid rgba(0, 0, 0, 0.6);
    border-right: 5px solid #ddd;
    border-bottom: 5px solid #ddd;
    border-left: 5px solid #ddd;
}

@-webkit-keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load8 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media only screen and (min-width: 700px) {
    .twoFAContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .twoFAFixedWidth {
        max-width: 700px;
        min-width: 700px;
    }
    .twoFA-button {
        margin-left: 0px;
        margin-right: 0px;
    }
}

.segmentedControlContainer {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    cursor: pointer;
}

.timePickerContainer {
    /* padding: 20px; */
    margin-top: 20px;
}

.dateTimePickerRow {
    display: flex;
    justify-content: space-between;
}

.segmentedControl {
    flex-grow: 1;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    font-weight: 600;
    color: #333;
    box-sizing: border-box;

    border-right: 0;

    transition: 0.3s ease;

    height: 41px;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.segmentedControl:hover {
    background-color: #469f5e;
    border-color: #469f5e;
    color: #fff;
}

.segmentedControl:active {
    -webkit-filter: brightness(90%);
            filter: brightness(90%);
}

.segmentedControl + .segmentedControl {
    border-right: 1px solid #ccc;
}

.segmentedControl:first-of-type {
    border-radius: 10px 0 0 10px;
}

.segmentedControl:last-of-type {
    border-radius: 0 10px 10px 0;
}

.segmentedControlSelected {
    background-color: #469f5e;
    border: 1px #469f5e;
    color: #fff;
}
.segmentedControlSelected:hover {
    -webkit-filter: opacity(90%);
            filter: opacity(90%);
}

@media only screen and (min-width: 700px) {
    .segmentedControlContainer {
        justify-content: left;
    }
}

.kioskTerminalSetup {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    z-index: 99999;
}

.kioskTerminalSetupInnerContainer {
    padding: 30px;
}

.kioskTerminalReaderRow {
    display: flex;
    border: 2px solid #888;
    justify-content: space-evenly;
    padding: 20px;
    align-items: center;
}

.kioskTerminalReaderRow + .kioskTerminalReaderRow {
    margin-top: 30px;
}

.kiskoTerminalConnectButton {
    padding: 30px;
    background-color: rgb(0, 114, 19);
    border-radius: 10px;
    color: #fff;
    font-size: 26px;
    transition: 0.3s ease-in-out;
}
.kiskoTerminalConnectButton:active {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
}

@-webkit-keyframes colorAnimation {
    0% {
        background-color: #11aa11d8;
    }
    50% {
        background-color: #11aa11;
    }
    100% {
        background-color: #11aa11d8;
    }
}

@keyframes colorAnimation {
    0% {
        background-color: #11aa11d8;
    }
    50% {
        background-color: #11aa11;
    }
    100% {
        background-color: #11aa11d8;
    }
}

.kiosk-home-actionbar {
    position: absolute;
    bottom: calc(((100vh - 250px) / 2) - 600px);
    left: calc((100vw - 500px) / 2);
    min-width: 500px;
    min-height: 250px;
    -webkit-backdrop-filter: blur(50px) saturate(250%);
            backdrop-filter: blur(50px) saturate(250%);
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 95px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    -webkit-animation: 1.5s ease-in-out colorAnimation infinite;
            animation: 1.5s ease-in-out colorAnimation infinite;
}

@-webkit-keyframes textAnimation {
    0% {
        opacity: 0.6;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.6;
    }
}

@keyframes textAnimation {
    0% {
        opacity: 0.6;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.6;
    }
}

.kiosk-home-touch-icon {
    width: 150px;
    /* animation: 2s ease-in-out textAnimation infinite; */
}

.kiosk-home-actionbar-text {
    color: #fff;
    font-size: 62px;
    font-weight: 600;
    font-family: 'Open Sans';
    margin-top: 20px;
    /* animation: 2s ease-in-out textAnimation infinite; */
}

@media (min-aspect-ratio: 16/9) {
    .kiosk-home-touch-icon {
        width: 50px;
    }
    .kiosk-home-actionbar-text {
        color: #fff;
        font-size: 28px;
        font-family: 'Open Sans';
        margin-top: 30px;
    }
}

.hiddenClickableDiv {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-image: url('/assets/kiosk/corners2.png');
    z-index: 99999;
}

.tabletHomeContainer {
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    /* background-image: url('/assets/photos/ghostkitchens/order_here.jpg'); */
    background-size: cover;
    cursor: pointer;
    background-color: #bee9fd;
}

.tabletHomeImage {
    height: 100vh;
    width: 100vw;
}

.tabletHomeActionBar {
    cursor: pointer;
    position: fixed;
    bottom: 0px;
    left: 0;
    height: 100px;
    width: 100vw;
    /* background-color: #fff; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.75);
}

.actionBarLogo {
    height: 60px;
    margin-left: 40px;
}

.touchToOrderLabel {
    margin-right: 40px;
    font-weight: 900;
    font-size: 42px;
    line-height: 93.69%;
    letter-spacing: -0.03em;
    /* identical to box height, or 35px */
    color: rgb(57, 133, 57);
}

.fullscreen-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -100;
    background-color: #000;
}

.fullscreen-bg__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 300%;
    top: -100%;
}

@media (min-aspect-ratio: 16/9) {
    .fullscreen-bg__video {
        height: 300%;
        top: -100%;
    }
}

@media (max-aspect-ratio: 16/9) {
    .fullscreen-bg__video {
        width: 320%;
        left: -110%;
    }
}

@-webkit-keyframes to-page-container-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes to-page-container-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.to-page-payment-options-container {
    display: flex;
    margin-top: 100px;
}

.to-page-payment-option {
    display: flex;

    flex-direction: column;
    justify-content: space-between;

    height: 400px;
    width: 35vw;
    background-color: #fff;
    border-radius: 50px;

    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
    transition: 0.5s ease;

    border: 20px solid #ccc;
}

.to-page-payment-option:active {
    -webkit-filter: brightness(60%);
            filter: brightness(60%);
}

.to-page-payment-back-button {
    position: absolute;
    bottom: 100px;

    margin-top: 100px;
    background-color: #01161e;
    width: calc(70vw);
    left: 15vw;
    padding: 20px;
    border-radius: 50px;
    text-align: center;

    font-size: 28px;
    font-weight: 700;
    color: #fff;
}

.not-absolute {
    position: relative;
}

.to-page-payment-option-label {
    margin-bottom: 30px;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
}

.to-page-payment-option-image {
    width: calc(100% - 40px);
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    object-fit: contain;
}

.to-page-payment-option + .to-page-payment-option {
    margin-left: 50px;
}

.to-page-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;

    -webkit-animation-name: to-page-container-fade-in;

            animation-name: to-page-container-fade-in;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;

    z-index: 1000;
}

.to-page-container-item {
    bottom: 0;
    height: calc(100vh);

    box-shadow: 0 0 200px rgba(49, 49, 49, 0.897);
}

.to-page-inner-container {
    padding: 30px;
    width: calc(100% - 60px);
    overflow: auto;
    padding-bottom: 600px;
}

.to-page-item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.to-page-header-spacing {
    height: 100px;
}

.to-page-item-name {
    margin-left: 40px;
    margin-right: 40px;
    font-family: 'Open Sans';
    font-size: 36px;
}

.to-page-item-description {
    font-size: 23px;
    color: #555;
    margin-top: 30px;
}

.to-page-item-modifiers {
    margin: 30px;
    margin-top: 100px;
}

.to-page-item-modifier-error {
    font-size: 30px;
    font-family: 'Open Sans';
    color: rgb(187, 0, 0);
}

.to-page-item-one-modifier-group-modifier-container {
    display: flex;
    flex-wrap: wrap;
}

.to-page-item-modifier {
    /* width: 400px; */
    font-size: 30px;
    font-family: 'Open Sans';
    border: 4px solid rgb(224, 224, 224);
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    margin: 3px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;

    display: flex;
    align-items: center;
}

.to-page-item-bottom-container-spacing {
    padding-bottom: 300px;
}

.to-p-i-m-checked {
    background-color: #90323d;
    color: #fff;
    border-color: #90323d;
}

.to-page-item-one-modifier-name {
    margin-left: 30px;
    font-size: 28px;
    font-family: 'Roboto';
    font-weight: 500;
    margin-right: 40px;
    width: 200px;
}

.to-page-item-one-modifier-required {
    margin-top: 10px;
    font-family: 'Open Sans';
    font-size: 20px;
}

.to-page-item-one-modifier-index {
    max-width: 100px;
    max-height: 100px;
    min-width: 100px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #90323d;
    color: #fff;
    border-radius: 100px;
    font-size: 50px;
    font-family: 'Open Sans';
}
.to-page-item-one-modifier {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 100%;
}

.to-page-item-one-modifier + .to-page-item-one-modifier {
    margin-top: 50px;
}

.to-page-item-image {
    min-width: 300px;
    min-height: 300px;
    max-width: 300px;
    max-height: 300px;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
}

.to-container {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    margin-top: 0px;
}
.to-container-left-home-btn {
    padding: 20px;
    width: calc(100% - 80px);
    height: 150px;
    object-fit: contain;
    background: linear-gradient(154.59deg, #04ac01 20%, #005e25 98.12%);
    box-shadow: 0 0 30px rgba(0, 151, 13, 0.5);
    margin: 20px;
    /* margin-bottom: 60px; */
    /* border-radius: 25px; */
}

@-webkit-keyframes itemFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes itemFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes textFadeInFromWhiteToBlack {
    0% {
        color: #fff;
    }
    100% {
        color: #000;
    }
}

@keyframes textFadeInFromWhiteToBlack {
    0% {
        color: #fff;
    }
    100% {
        color: #000;
    }
}

.to-container-menu-name {
    font-size: 48px;
    font-weight: 500;
    color: #333;
    padding-top: 40px;
    padding-bottom: 40px;
    font-family: 'Open Sans';
    font-weight: 700;

    -webkit-animation: 0.3s ease-in textFadeInFromWhiteToBlack;

            animation: 0.3s ease-in textFadeInFromWhiteToBlack;
}
.to-container-menu-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}

@-webkit-keyframes opacityFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes opacityFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.to-container-menu-item {
    display: block;
    width: 190px;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.2);

    padding: 15px;
    display: flex;
    margin: 15px;

    border: 1px solid #ddd;

    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;

    -webkit-animation: 0.3s ease-in opacityFadeIn;

            animation: 0.3s ease-in opacityFadeIn;
    transition: 0.1s ease-in-out;
}

.item-container-sold-out {
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
    border: 1px solid #eee;
}

.kiosk-out-of-stock {
    opacity: 0.3;
}

.to-container-menu-item:active {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}

.to-container-menu-item-photo {
    height: 200px;
    width: 100%;
    max-height: 200px;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    image-rendering: auto;
    object-fit: contain;
    margin-bottom: 20px;
}

.to-container-menu-item-label {
    flex-grow: 1;
    font-size: 20px;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: 'Open Sans';
    padding: 10px;
    padding-bottom: 0;
    border-top: 1px solid #ccc;
    color: #333;
}

.to-page-item-bottom-container {
    position: fixed;
    bottom: 0;
    width: 100vw;
    left: 0;
    height: 700px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fff;
    box-shadow: 0px -10px 30px rgba(0, 0, 0, 0.05);
}

.to-page-item-bottom-quantity-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: 'Open Sans';
    font-size: 38px;

    border-top: 2px solid #eee;
    padding-top: 20px;
    padding-bottom: 10px;
}

.to-quantity {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    min-width: 400px;
    max-width: 400px;
    border: 2px solid #ddd;
    align-items: center;
    border-radius: 13px;
    margin-top: 10px;
    font-family: 'Open Sans';
    font-weight: 700;
}

.to-quantity-minus {
    background-color: #ddd;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 60px;
    line-height: 40px;
    color: #000;
    border-radius: 10px;
    font-weight: 900;

    border-right: 2px solid #ddd;
    transition: 0.1s ease-in-out;
}
.to-quantity-minus:active {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.to-quantity-plus {
    background-color: #ddd;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 60px;
    line-height: 40px;
    color: #000;
    border-radius: 10px;
    font-weight: 900;

    border-left: 2px solid #ddd;
    transition: 0.1s ease-in-out;
}
.to-quantity-plus:active {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.to-item-save {
    display: flex;
    min-width: 100%;
    font-family: 'Open Sans';
}

.to-item-save-button {
    min-width: 40%;
    max-width: 40%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 20px;
    margin-bottom: 10px;

    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #90323d;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    font-size: 30px;
    font-family: 'Open Sans';

    transition: 0.1s ease-in-out;
}
.to-item-save-button:active {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}

.to-item-cancel {
    background-color: rgb(158, 34, 34);
}

.tablet-phone-input {
    font-size: 40px;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
}

@-webkit-keyframes minicartAnimation {
    0% {
        bottom: -300px;
    }
    100% {
        bottom: 0px;
    }
}

@keyframes minicartAnimation {
    0% {
        bottom: -300px;
    }
    100% {
        bottom: 0px;
    }
}

@-webkit-keyframes animatePageFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes animatePageFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.animate-page-fade-in {
    /* animation: 1s ease animatePageFadeIn; */
}

.to-minicart-container {
    height: 500px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: linear-gradient(75deg, #24c3c9 0.27%, #34b823 100.44%);
    background-size: cover;
    /* background: #000; */
    /* background: #1E823C; */
    background: rgb(7, 17, 8);

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.my-order-animate {
    -webkit-animation: 1s ease-in-out minicartAnimation;
            animation: 1s ease-in-out minicartAnimation;
}

.to-container-height-adjust-minicart {
    height: calc(100vh - 300px);
}

.to-minicart-inner-container {
    padding: 20px;
    font-family: 'Open Sans';
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #fff;
    height: 160px;
}

.to-minicart-my-order {
    font-size: 36px;
    font-weight: 600;
}

.to-minicart-my-order-summary {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
}

.to-order-save {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.to-order-save-button {
    min-width: calc(50% - 10px);
    max-width: calc(50% - 10px);

    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0;
}

.to-order-black {
    background-color: #000;
}

.to-page-container-dark {
    background: rgb(0, 38, 7);
    background: #fff;
    color: #fff;
}

.to-page-inner-container-dark {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #000;
}

.to-page-review-logo {
    margin-top: 150px;
    width: 400px;
}

.to-page-review-heading {
    margin-top: 50px;
    font-family: 'Open Sans';
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 50px;
    color: #000;
}

.to-page-review-nuvei-warning {
    margin-top: 50px;
    opacity: 60%;
    font-family: 'Open Sans';
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color: #000;
}

.to-page-review-items {
    width: 800px;
    border: 2px solid #000;
    border-radius: 30px;
    padding: 30px;
    background-color: #fff;
}

.to-page-review-item-name {
    font-family: 'Open Sans';
    color: #222;
    font-size: 28px;
    font-weight: 600;
}

.kiosk-discount-modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    background-color: rgba(255, 255, 255, 0.8);
    z-index: 999;
}

.kiosk-discount-modal-window {
    width: calc(80vw - 64px);
    left: 10vw;
    top: 250px;
    position: absolute;
    background-color: #fff;

    padding: 32px;

    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 99px #00000052;
    border-radius: 36px;
}
.kiosk-discount-modal-text-field {
    font-family: 'Poppins';
    font-weight: 400;
    color: #222;
    border: 0;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    font-size: 36px;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 10px solid #dbdbdb;
}

.kiosk-discount-modal-button-container {
    display: flex;
    justify-content: space-between;
}

.kiosk-discount-modal-button {
    background: #e2e2e2 0% 0% no-repeat padding-box;
    border-radius: 11px;
    opacity: 1;

    width: 330px;

    text-align: left;
    font: normal normal bold 28px/37px Segoe UI;
    letter-spacing: 0px;

    opacity: 1;
    padding: 22px;
    padding-left: 32px;
}

.kiosk-discount-modal-keyboard {
    position: absolute;
    top: 800px;
    width: 100%;
}

.kiosk-discount-modal-keyboard-background {
    position: absolute;
    top: 800px;
    background-color: #fff;
    height: 100vh;
    width: 100vw;
}

.modal-button-blue {
    color: #568eff;
}

.modal-button-red {
    color: #e62626;
}

.kiosk-discount-modal-body {
    margin-top: 72px;
}

.kiosk-discount-modal-alert {
    font: normal normal 600 22px/30px Segoe UI;
    letter-spacing: 0px;
    color: #4e4e4e;
    opacity: 1;
}

.kiosk-discount-modal-title {
    font: normal normal bold 36px/48px Segoe UI;
    letter-spacing: 0px;
    color: #4e4e4e;
    opacity: 1;
}

.kiosk-discount-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.no-border {
    border: 0;
}

.to-page-review-modifier {
    color: #000;
    margin-top: 10px;
    font-size: 28px;
    font-family: 'Open Sans';
}

.to-page-review-modifier + .to-page-review-modifier {
    margin-top: 0;
}

.to-page-review-item-container {
    width: 100%;
    margin-bottom: 0;
}

.to-page-review-item-container + .to-page-review-item-container {
    border-top: 2px solid #999;
    padding-top: 20px;
}

.to-page-review-items + .to-page-review-items {
    margin-top: 20px;
}

.to-page-review-items:last-of-type {
    padding-bottom: 50px;
}

.to-page-review-item-button-container {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.to-page-review-item-button {
    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #01161e;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    font-size: 30px;
    font-family: 'Open Sans';
    transition: 0.1s ease-in-out;
}
.to-page-review-item-button:active {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}

.to-page-review-quantity {
    margin-top: 0;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Open Sans';
}

.to-page-order-success {
    font-family: 'Open Sans';
    color: #000;
    font-size: 40px;
    width: 600px;
    text-align: center;
    margin: 20px;
    margin-top: 60px;
}

.to-page-order-number {
    padding: 20px;
    color: #fff;
    background-color: #90323d;
    border-radius: 10px;
}

.to-page-order-number {
    text-align: center;
    font-family: 'Roboto Mono';
    font-size: 100px;
    font-weight: 700;
    margin-top: 40px;
}

.to-page-table-number {
    font-size: 40px;
    font-weight: 700;
    background-color: #fff;
    color: #90323d;
    padding: 10px;
    border-radius: 100px;
    padding-left: 25px;
    padding-right: 25px;
}

.topHeaderBarImage {
    height: 200px;
    width: 100%;
    object-fit: cover;
    object-position: 0% 0%;
    border: 0;
    padding: 0;
    margin: 0;
}

.brand-select-header-combined {
    /* background: linear-gradient(180deg, #0C2830 18.23%, rgba(12, 40, 48, 0) 100%); */
    /* background-color: #0C2830; */
    /* background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, #dfdfdf 100%); */
}

.kiosk-header {
    height: 140px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* background-color: #fff; */
    /* margin-bottom: 10px; */

    background-color: #fff;
}

.kiosk-header-logo {
    height: 95px;
    margin-left: 25px;
    margin-top: 5px;
}

.kiosk-brand-select-mini {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: auto;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.to-container-left-menu:first-of-type {
    margin-left: 65px;
}
.to-container-left-menu:last-of-type {
    margin-right: 65px;
}

.to-container-left-menu {
    margin: 10px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 180px;
    display: flex;
    justify-content: center;
    position: relative;
}

.to-container-left-menu-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-size: cover;
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
    transition: 0.1s ease-in-out;
    border: 15px solid #fff;
}

.to-container-left-menu-img:active {
}

.to-container-left-menu-img-coming-soon:active {
    width: 150px;
    height: 150px;
    border: 15px solid #ff7272;
}

.to-menu-selected {
    width: 150px;
    height: 150px;
    border: 15px solid #a0c5a0;
}

.passwordLabel {
    font-size: 36px;
    margin: 10px;
}

.passwordContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.passwordInput {
    font-size: 36px;
    border: 1px solid #000;
}

.passwordSubmitButton {
    font-size: 36px;
    margin: 10px;
}

.backToOrderingButton {
    font-size: 36px;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 100px;
    background: none;
    border: none;
    border-radius: 10px;
    background-color: #840032;
    color: #fff;
    padding-left: 40px;
    padding-right: 40px;
}

@-webkit-keyframes slideFromLeft {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideFromLeft {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes tapAnimation {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    25% {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
    75% {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes tapAnimation {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    25% {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
    75% {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.kiosk-category-sidebar {
    width: 130px;
    /* border: 1px solid #E1E1E1; */
    padding: 15px;
    border-radius: 10px;

    margin: 5px;
    transition: 0.1s;

    /* animation: 0.3s ease-in opacityFadeIn; */
}

.kiosk-category-sidebar:active {
    -webkit-animation: 0.1s ease-in-out tapAnimation;
            animation: 0.1s ease-in-out tapAnimation;
}

.kiosk-category-sidebar:first-of-type {
    margin-top: 60px;
}
.kiosk-category-sidebar:last-of-type {
    margin-bottom: 75px;
}

.kiosk-category {
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
    /* transition: 1s ease-in-out slideFromLeft; */
    transition: 0.5s ease-in-out;
    background-color: #fff;
}

.kiosk-category-selected {
    background-color: #a0c5a0;
}

.kiosk-category-sidebar-image {
    min-width: 130px;
    min-height: 130px;
    max-height: 130px;
    max-width: 130px;
    object-fit: cover;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: #fff;
}
.kiosk-category-sidebar-line {
    height: 1px;
    background-color: #e1e1e1;
}

.kiosk-category-sidebar-text {
    text-align: center;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 7.5px;
    font-weight: 600;
    font-family: 'Open Sans';
    background-color: #fff;
}

.to-container-menu-item-price {
    font-size: 18px;
    font-weight: 400;
    margin-top: 5px;
}

.to-v2-button {
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #840032;
    color: #fff;
    font-family: 'Lato';
    font-size: 26px;
    font-weight: 700;

    flex-direction: column;
    border-radius: 10px;

    transition: 0.1s ease-in-out;
}

.to-v2-button:active {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}

.to-v2-button-icons {
    height: 60px;
    width: 60px;
    margin-bottom: 20px;
}

.to-v2-button-icon-sm {
    height: 40px;
    width: 40px;
    margin-bottom: 20px;
}

.v2-button-cancel {
    background-color: #ffffff28;
    color: #fff;
}

.to-container-back-button-row {
    display: flex;
    justify-content: flex-end;
    width: calc(100vw);
    margin-bottom: 50px;
}

.to-container-back-button {
    padding: 15px;
    padding-left: 90px;
    padding-right: 90px;
    background-color: #d68c45;
    color: #fff;
    font-size: 28px;

    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    transition: 0.1s ease-in-out;
}
.to-container-back-button:active {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}

.brand-left-chevron {
    position: absolute;
    height: 185px;

    background-color: rgba(255, 255, 255, 0.8);

    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
}

.brand-right-chevron {
    position: absolute;
    height: 185px;

    background-color: rgba(255, 255, 255, 0.8);

    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;

    transition: 0.3s ease-in-out;
}

.brand-left-chevron-icon {
    width: 20px;
    opacity: 0.7;
}

.to-page-item-modifier-selector {
    width: 25px;
    height: 25px;
}

.modifier-label {
    font-size: 18px;
}

.to-container-left {
    min-width: 200px;
    max-width: 200px;
    /* background-color: #eee; */

    /* border-right: px solid #999; */
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.15);

    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 900px);
    overflow: auto;

    border-radius: 10px 10px 10px 10px;
}

.to-container-right {
    padding: 20px;

    height: calc(100vh - 879px);
    overflow: auto;

    padding-top: 0;
    padding-bottom: 0;
}

.to-page-item-header-fixed {
    position: fixed;
    top: 0;
    width: calc(100% - 80px);
    padding-left: 40px;
    padding-right: 40px;
    left: 0;
    box-shadow: 0 5px 75px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    z-index: 10;
}

.to-page-item-header-fixed-spacing {
    height: 300px;
}

@media (min-aspect-ratio: 16/9) {
    .to-minicart-my-order {
        font-size: 26px;
    }
    .to-minicart-my-order-summary {
        font-size: 26px;
    }
    .to-minicart-container {
        height: 150px;
        padding: 10px;
    }
    .to-minicart-inner-container {
        height: 130px;
        padding: 0;
    }
    .to-v2-button-icon-sm {
        margin-bottom: 10px;
    }
    .kiosk-header-logo {
        height: 60px;
    }
    .kiosk-header {
        height: 90px;
    }
    .to-container-left-menu-img {
        height: 90px;
        width: 90px;
    }

    .brand-left-chevron {
        height: 130px;
    }
    .brand-right-chevron {
        height: 130px;
    }
    .to-container {
        margin-top: 10px;
    }
    .kiosk-brand-select-mini {
        margin-bottom: 10px;
    }
    .to-container-left {
        max-height: 675px;
        min-height: 675px;
        min-width: 175px;
        max-width: 175px;
    }
    .to-container-right {
        max-height: 675px;
        min-height: 675px;
    }
    .brand-left-chevron-icon {
        width: 15px;
    }
    .kiosk-category-sidebar {
        width: 130px;
        margin: 0;
    }
    .kiosk-category-sidebar-image {
        min-height: 130px;
        max-height: 130px;
        min-width: 130px;
        max-width: 130px;
    }

    .kiosk-category-sidebar-text {
        max-width: 120px;
    }
    .kiosk-category-sidebar-line {
        max-width: 120px;
    }
    .kiosk-category-sidebar-text {
        font-size: 15px;
        padding-top: 5px;
        padding-bottom: 10px;
    }
    .to-container-menu-item-photo {
        height: 100px;
    }
    .to-container-menu-item-label {
        font-size: 16px;
    }
    .to-container-menu-item-price {
        font-size: 16px;
    }
    .to-container-left {
        min-width: 525px;
        max-width: 525px;
    }
    .category-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 75px;
        margin-bottom: 75px;
    }
    .kiosk-category-sidebar:first-of-type {
        margin-top: 0;
    }
    .kiosk-category-sidebar:last-of-type {
        margin-bottom: 0;
    }
    .to-container-back-button-row {
        margin-bottom: 10px;
    }
    .to-container-back-button {
        font-size: 18px;
        padding: 10px;
        padding-left: 45px;
        padding-right: 45px;
    }

    .to-page-inner-container {
        display: flex;
        justify-content: space-between;
    }
    .to-page-item-name {
        font-size: 26px;
    }
    .to-page-item-modifiers {
        overflow: auto;
        margin: 0;
        padding-top: 60px;
        padding-bottom: 60px;
        margin-left: 100px;
    }
    .to-page-item-one-modifier {
        justify-content: flex-start;
    }
    .to-page-item-one-modifier-index {
        min-height: 50px;
        max-height: 50px;
        min-width: 50px;
        min-height: 50px;
        font-size: 25px;
    }
    .to-page-item-one-modifier-name {
        font-size: 28px;
    }
    .to-page-item-modifier {
        width: auto;
    }
    .to-page-item-modifier-selector {
        height: 15px;
        width: 15px;
    }
    .modifier-label {
        font-size: 20px;
    }
    .to-page-item-one-modifier-group-modifier-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .to-page-item-bottom-quantity-container {
        font-size: 26px;
    }
    .to-page-review-logo {
        margin-top: 50px;
        width: 200px;
    }
    .to-page-review-heading {
        font-size: 25px;
    }
    .to-page-review-nuvei-warning {
        font-size: 15px;
    }
    .to-page-review-item-button {
        font-size: 20px;
        padding-left: 25px;
        padding-right: 25px;
    }
    .to-page-payment-options-container {
        margin-top: 50px;
    }
    .to-page-payment-option-image {
        width: 250px;
    }
    .to-page-payment-option {
        height: auto;
        width: auto;
    }
    .to-page-payment-option-label {
        font-size: 30px;
        margin-top: 10px;
    }
}

@-webkit-keyframes idle-pop-in {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.7);
                transform: scale(0.7);
    }
    50% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes idle-pop-in {
    0% {
        opacity: 0;
        -webkit-transform: scale(0.7);
                transform: scale(0.7);
    }
    50% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@-webkit-keyframes paymentErrorPing {
    0% {
        background-color: #ffa600;
    }
    50% {
        background-color: #ff0000;
    }
    100% {
        background-color: #ffa600;
    }
}

@keyframes paymentErrorPing {
    0% {
        background-color: #ffa600;
    }
    50% {
        background-color: #ff0000;
    }
    100% {
        background-color: #ffa600;
    }
}

.kiosk-logo {
    position: fixed;
    bottom: 30px;
    right: 0;
    z-index: 99999999;
    width: 380px;
    opacity: 0.6;
}

.paymentMethodError {
    position: fixed;
    display: block;
    top: 10px;
    right: 10px;
    z-index: 9999999;
    padding: 20px;
    border-radius: 50px;
    font-weight: 500;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
}

.paymentMethodErrorCircle {
    min-width: 30px;
    min-height: 30px;
    border-radius: 30px;
    background-color: rgb(219, 81, 46);
    margin-right: 10px;
    -webkit-animation: 1s ease-in-out paymentErrorPing infinite;
            animation: 1s ease-in-out paymentErrorPing infinite;
}

.idlePromptContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;

    background-color: rgba(0, 0, 0, 0.5);
}

.hide {
    display: none;
}

.idlePrompt {
    padding: 30px;
    text-align: center;
    background-color: #fff;
    box-shadow: 10px 10px 80px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    -webkit-animation: 0.5s ease-in-out idle-pop-in;
            animation: 0.5s ease-in-out idle-pop-in;
    align-items: center;
}

.idlePrompt p {
    font-size: 36px;
    font-weight: 600;
    color: #d68c45;
}

.idlePrompt section {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100px;
    min-height: 100px;
    padding: 20px;
    margin: 20px;
    font-size: 64px;
    font-weight: 800;
    border: 13px solid #d68c45;
    border-radius: 100px;
    color: #d68c45;
}

.idlePromptButtonContainer {
    display: flex;
    margin-top: 2em;
    align-items: center;
    justify-content: center;
}

.idlePromptButton {
    width: 280px;
    background-color: #d68c45;
    margin: 20px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 15px;
    border-radius: 5px;
    font-size: 30px;
    font-weight: 500;
    color: #fff;
    transition: 0.05s ease-in-out;
}

.idlePromptButton:active {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}

.ItemDescriptionRow {
    padding: 15px 20px 15px 20px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}
.ItemSubSelectionRow {
    padding: 10px 20px 10px 20px;
    border-bottom: 1px;
    border-color: rgb(226, 226, 226);
    border-bottom-style: solid;
    cursor: pointer;
    transition: 0.1s ease-in;
}
.ItemSubSelectionRow:hover {
    background-color: #fff;
}
.ItemPhotoContainer {
    min-height: 40vh;
    max-height: 40vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(
            132.01deg,
            #ffffff -10.69%,
            rgba(0, 0, 0, 0.52) -10.68%,
            rgba(0, 0, 0, 0) 35.4%,
            rgba(0, 0, 0, 0) 35.4%
        ),
        linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 71.29%,
            rgba(44, 44, 44, 0.51) 86.49%,
            rgba(52, 52, 52, 0.67) 100%
        );
}
.ItemImage {
    object-fit: cover;
    background-repeat: no-repeat;
    z-index: 1;
    /* backdrop-filter: saturate(200%) blur(25px) brightness(90%) contrast(100%); */
}
/* .ItemImageBackgroundColoring {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 40vh;
    max-height: 40vh;
    width: 100vw;
    filter: blur(100px);
} */
.CloseItemButton {
    padding-left: 20px;
    padding-top: 40px;
    color: white;
    z-index: 1;
    cursor: pointer;
}
.ItemHeader {
    font-size: 18;
    font-family: 'Poppins';
    font-weight: 700;
    padding: 20px;
    color: white;
    z-index: 1;
}
.ItemDescription {
    font-size: 13px;
    font-family: 'Poppins';
    font-weight: 400;
}
.ItemPriceTag {
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 400;
    color: white;
    background-color: #20b04b;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 3px 5px 3px 5px;
    width: 70px;
    margin-bottom: 5px;
}
.JustifiedRowEnds {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.SelectRowHeader {
    font-size: 15px;
    font-family: 'Poppins';
    font-weight: 600;
    color: rgb(36, 36, 36);
}
.SelectRowStatus {
    font-size: 12px;
    font-family: 'Poppins';
    font-weight: 400;
    color: #242424;
}
.ItemQuantityText {
    padding: 0px 10px 0px 10px;
    font-size: 24;
    font-family: 'Roboto';
    font-weight: 700;
    color: rgb(78, 78, 78);
}
.ModifierQuantityText {
    padding: 0px 5px 0px 5px;
    font-size: 24;
    font-family: 'Roboto';
    font-weight: 700;
    color: rgb(78, 78, 78);
}
.ModifierQuantityTextKiosk {
    padding: 0px 5px 0px 5px;
    font-size: 22px;
    font-family: 'Roboto';
    font-weight: 700;
    color: rgb(255, 255, 255);
}
.ModifierQuantityContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 15px;
}
.ModifierQuantityContainerKiosk {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 8px;
    margin-left: 15px;
}
.FabText {
    color: white;
    font-size: 13;
    font-family: 'Poppins';
    font-weight: 700;
    padding: 0px 7px 0px 5px;
}
.FabTextDisabled {
    color: rgb(180, 180, 180);
    font-size: 13;
    font-family: 'Poppins';
    font-weight: 700;
    padding: 0px 7px 0px 5px;
}
.FabContainer {
    position: fixed;
    right: 20px;
    bottom: calc(env(safe-area-inset-bottom) + 120px);
    z-index: 999;
}
.content {
    background-color: #fff;
}

.QuantitySetting {
    margin-bottom: 50px;
}

.InstructionInput {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #e5e5e5;
    border: 1px solid white;
    border-radius: 15px;
    height: 20px;
    padding: 10px 10px;
    font-size: 12px;
}

.InstructionContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 60px;
    margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
    .ItemHeader {
        font-size: 48px;
    }
    .ItemDescription {
        font-size: 16px;
    }
    .ItemImage {
        object-fit: contain;
        background-color: #ffffff;
    }
    .InstructionInput {
        width: 90%;
        height: 30px;
        border-radius: 20px;
        font-size: 14px;
        padding: 10px 30px;
    }
    .SelectRowHeader {
        font-size: 16px;
    }
}

.HeaderText {
    font-family: 'Poppins';
    font-size: 14;
    font-weight: 700;
    color: white;
}
.HeaderContainer {
    background-color: rgb(32, 32, 32);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 40px 16px 5px 16px;
}
.HeaderConfirmTextWhite {
    font-family: 'Poppins';
    font-size: 13;
    font-weight: 700;
    color: white;
    cursor: pointer;
}
.HeaderConfirmTextDisabled {
    font-family: 'Poppins';
    font-size: 13;
    font-weight: 700;
    color: white;
    opacity: 30%;
}
.ModifierRow {
    padding: 10px 0px 10px 10px;
    border-bottom: 1px;
    border-color: #fff;
    border-bottom-style: solid;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: 0.1s ease-in;
}
.ModifierRow:hover {
    background-color: rgb(243, 243, 243);
    cursor: pointer;
}
.ModifierDetails {
    background-color: #fff;
    width: 100%;
}
.SettingGroup {
    /* width: 100%; */
    background-color: white;
}
.SettingHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f5f5f7;
    cursor: pointer;
}
.SettingHeader:hover {
    background-color: #e9e9e9;
}
.CenterVertically {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.SubHeaderContainerText {
    padding: 40px 0px 16px 16px;
    font-family: 'Poppins';
    font-size: 13;
    font-weight: 700;
    border-bottom: 1px;
    border-color: rgb(226, 226, 226);
    border-bottom-style: solid;
}
.ModifierRowText {
    font-family: 'Poppins';
    font-size: 12px;
    font-weight: rgb(49, 49, 49);
}
.SelectButtonContainer {
    padding: 3px 16px 0px 16px;
}
.SpecialInstructionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 24px 32px 0px 32px;
}

@media only screen and (min-width: 768px) {
    .ModifierRowText {
        font-size: 16px;
    }
}

.tipModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
}

.tipModalContainer {
    background-color: #f8f8f8;
    padding: 60px;
    border-radius: 28px;
}

.tipModalContainerCancelBtn {
    display: flex;
    justify-content: flex-end;
}

.tipModalContainerHeader {
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    padding: 10px;
}

.tipModalContainerDescription {
    font-weight: 500;
    font-size: 23px;
    padding: 10px;
}

.tipModalOptionsContainer {
    padding: 10px;
    display: flex;
    padding-top: 30px;
}

.tipModalOption {
    background: #6084ff;
    box-shadow: 0px 4px 150px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    -webkit-filter: drop-shadow(0px 4px 100px rgba(0, 0, 0, 0.16));
            filter: drop-shadow(0px 4px 100px rgba(0, 0, 0, 0.16));
    width: 168px;
    height: 168px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tipModalOption + .tipModalOption {
    margin-left: 30px;
}

.tipModalOption-percentage {
    font-size: 48px;
    color: #fff;
    font-weight: 700;
}

.tipModalOption-amount {
    color: #fff;
    margin-top: 10px;
    opacity: 0.8;
}

.kiosk-keyboard {
    width: 300px;
}

.kiosk-phonenumber-input {
    border: 0;
    border-color: #fff;
    width: 300px;
    padding: 10px;
    font-size: 36px;
    background-color: #f7f7f7;
    border-bottom: 5px solid #dadada;
    margin-bottom: 10px;
    border-radius: 5px;
    text-align: center;
    font-weight: 500;
}

.kiosk-pn-row {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.brand-select-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 30px;
    /* margin-top: 2px; */
}

.brand-select-header {
    margin: 30px;
    font-size: 63px;
    font-family: 'Roboto';
    color: rgb(64, 83, 64);
}

.brand-select-logo {
    width: 150px;
    height: 150px;

    object-fit: contain;

    margin: 10px;
    margin-bottom: 30px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    /* border: 1px solid #c2c2c2; */

    border-radius: 50%;

    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
    /* transition: 0.05s ease-in-out; */
}

.allergy-notice-modal-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.3);
}
.allergy-notice-modal {
    padding: 30px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #fff;
    max-width: 600px;
    margin-top: -250px;

    color: #333;
}
.allergy-notice-modal-close {
}
.allergy-notice-modal-header {
    display: flex;
    justify-content: space-between;
}
.allergy-notice-modal-title {
    font-weight: 600;
    font-size: 30px;
}
.allergy-notice-modal-body {
    margin-top: 25px;
}

.category-chevron {
    position: absolute;
    height: 80px;
    width: 200px;
    margin-top: -1px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(255, 255, 255, 0.9);

    transition: 0.3s ease-in;

    border-radius: 0 10px 0 0;
}

.brand-coming-soon {
    position: absolute;
    font-size: 13px;
    font-weight: 600;
    background-color: #2d4964;
    color: #fff;

    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 25px;
    z-index: 1;

    margin-left: 20px;
    margin-top: 130px;
}

.allergies-msg {
    margin-left: 30px;
    margin-right: 30px;
    padding: 20px;
    box-shadow: 0 5px 10px 0px #ccc;
    border-radius: 10px;
    display: inline-block;
}

.allergies-msg-container {
    display: flex;
    justify-content: flex-end;
}

.category-chevron-icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 20px;
    opacity: 0.6;
}

.category-chevron-down {
    margin: 0;
    position: absolute;
    bottom: 520px;
    z-index: 999;

    border-radius: 0 0 10px 0;
}

.paymentTerminalDisconnectedPrompt {
    position: fixed;
    height: 80vh;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 38px;
    font-weight: 700;
    color: rgb(180, 26, 26);
}

.category-chevron-icon-down {
    -webkit-transform: rotate(270deg);
            transform: rotate(270deg);
    width: 20px;
}

@media (min-aspect-ratio: 16/9) {
    .brand-select-header {
        font-size: 36px;
        text-align: center;
    }
    .brand-select-logo {
        width: calc(10vw - 50px);
        height: calc(10vw - 50px);
    }
    .brand-coming-soon {
        font-size: 15px;
        padding: 13px;
        padding-left: 18px;
        padding-right: 18px;
    }
    .category-chevron {
        width: 350px;
    }
    .category-chevron-icon {
        width: 10px;
    }
    .category-chevron-down {
        bottom: 160px;
    }
}

.kiosk-upsell-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #00000099 0% 0% no-repeat padding-box;
}

.kiosk-upsell-modal {
    width: calc(100% - 10%);
    background: #f1f1f1 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 99px #00000029;
    border: 1px solid #707070;
    border-radius: 28px;
    margin-bottom: 200px;
}

.kiosk-upsell-inner-container {
    padding: 80px 40px 48px 40px;
}

.kiosk-upsell-modal-header {
    text-align: center;
    font: normal normal bold 36px/44px Montserrat;
    letter-spacing: 0px;
    color: #3d3d3d;
    margin-bottom: 60px;
}

.kiosk-upsell-items {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
}

.kiosk-upsell-individual-item {
    width: calc(33% - 15px);
    background-color: #fff;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 60px #00000029;
    border-radius: 10px;
}

.kiosk-upsell-ii-inner-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.kiosk-upsell-ii-brand-logo {
    width: 85px;
    height: 85px;
    object-fit: contain;
    border-radius: 50%;
    margin-bottom: 5px;
}

.kiosk-upsell-ii-name {
    text-align: center;
    font: normal normal bold 16px/19px Montserrat;
    letter-spacing: 0px;
    color: #3d3d3d;
    margin-top: 15px;
    margin-bottom: 30px;
    height: 40px;
}

.kiosk-upsell-ii-price {
    font: normal normal bold 15px/19px Montserrat;
    letter-spacing: 0px;
    color: #7b7b7b;
}

.kiosk-upsell-ii-image {
    width: 160px;
    height: 160px;
    object-fit: contain;
}

.kiosk-upsell-ii-button {
    background: #6084ff 0% 0% no-repeat padding-box;
    border-radius: 6px;
    width: 100%;
    margin-top: 20px;

    text-align: center;
    font: normal normal bold 18px/22px Montserrat;
    letter-spacing: 0px;
    color: #ffffff;
    padding-top: 15px;
    padding-bottom: 15px;
}

.kiosk-upsell-ii-button-dark {
    background: #232c4c 0% 0% no-repeat padding-box;
}

.kiosk-upsell-ii-button + .kiosk-upsell-ii-button {
    margin-top: 10px;
}

.kiosk-upsell-no-thanks {
    display: inline-block;
    margin-top: 40px;
    font: normal normal bold 26px/32px Montserrat;
    letter-spacing: 0px;
    color: #6084ff;
    border-bottom: 5px solid #6084ff;
}

.kiosk-upsell-no-thanks-container {
    display: flex;
    justify-content: center;
}

.kiosk-upsell-carousel {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.kiosk-upsell-chevron {
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 25px #00000029;
    border-radius: 50px;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upsell-chevron-flipped {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.upsell-chevron-grayed {
    opacity: 0;
}

.KioskErrorBoundaryPage {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.KioskErrorBoundaryContainer {
    max-width: 75vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.KioskErrorTitle {
    font-size: 90px;
    font-family: 'Roboto';
}

.KioskRestartButton {
    font-size: 50px;
    padding: 30px 35px;
    font-weight: 400;
    border: 3px solid lightgrey;
    border-radius: 30px;
    margin-bottom: 30px;
    cursor: pointer;
}

.KioskRestartCountDown {
    font-size: 30px;
    margin-bottom: 70px;
}

.KioskRestartButton:hover {
    background-color: lightgrey;
    color: white;
}

.KioskErrorMessage {
    color: grey;
    font-size: 20px;
}

@media only screen and (min-width: 700px) {
    .KioskError {
        font-size: 60px;
        font-family: 'Roboto';
    }
}

.to-page-maintenance {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}

.locationSelectorButton {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 40px;
    height: 40px;
    /* background: #fff; */
    cursor: pointer;
    z-index: 99999;
}

.locationSelectorContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 99999;
}

.location {
    padding: 10px;
    border: 1px solid #fff;
    margin: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

.selectLocationButton {
    padding: 5px;
    background-color: #fff;
    color: #000;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    cursor: pointer;
}

.fullscreen-bg__video-wall {
    width: 100%;
    left: 0;
}

.videoContainer {
    position: fixed;
    z-index: 999;
    background-color: #000;
    width: 100vw;
    height: 100vh;
}

.tv-wall-notifications {
    position: fixed;
    top: 10px;
    left: 10px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #888;
    border-radius: 10px;
    min-width: 60px;
    z-index: 99999;
    text-align: center;
    cursor: pointer;
}

.videoContainerEmpty {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: #000;
    width: 100vw;
    height: 100vh;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.curtains {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    -webkit-animation: 0.5s ease-out fadeOut;
            animation: 0.5s ease-out fadeOut;
}

.hide-curtains {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 999;
}

.downloadProgress {
    background-color: rgba(18, 18, 18, 0.8);
    position: fixed;
    top: 10px;
    left: 10px;
    width: calc(100vw - 20px);
    border-radius: 10px;
    z-index: 99999;
    max-height: 70vh;
    overflow-y: auto;
}

.downloadProgress-item {
    color: #fff;
    padding: 10px;
    font-family: 'Roboto Mono';
    font-size: 13px;
}

.progressBar {
    border-radius: 10px;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.innerProgressBar {
    background-color: rgb(52, 196, 83);
    height: 5px;
    border-radius: 10px 10px 10px 10px;
}

.hcf-container {
    padding: 20px;
}

.hcf-title {
    font-size: 32px;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
}

.hcf-logo {
    margin: 20px;
    width: calc(100% - 40px);
}

.hcf-error {
    color: rgb(177, 4, 4);
}

.loadingScreenTopContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.loadingScreenContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vh;
}

.mini-cart {
    position: -webkit-sticky;
    position: sticky;
    bottom: 29px;
    background-color: #20b04b;
    color: #fff;
    cursor: pointer;
    z-index: 999;
    margin-top: 56px;
}

.mini-cart-inner-container {
    padding: 13px 25px 10px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.mini-cart-bottom-spacing {
    height: calc(env(safe-area-inset-bottom));
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;
    width: 100vw;
    left: 0;
    background-color: #fff;
    z-index: 9998;
}

.MuiSelect-icon {
    color: #fff !important;
}

.mini-cart-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: calc(100vw - 2rem - 60px - 1rem);
}

.mini-cart-icon-wrapper {
    position: absolute;
    right: 25px;
    width: 35px;
    height: 35px;
}



.mini-cart-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@-webkit-keyframes cartTutorialFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes cartTutorialFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.mini-cart-fade {
    height: 200px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.5) 100%
    );
    position: fixed;
    width: 100vw;
    bottom: 60px;
    pointer-events: none;
}

.mini-cart-add-to-order-notif {
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.48) 100%
    );
    z-index: 9999999;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    -webkit-animation: 1s ease-in cartTutorialFadeIn;
            animation: 1s ease-in cartTutorialFadeIn;
}

.mini-cart-ready-to-pay {
    left: 0;
    justify-content: flex-start;
}

.mini-cart-ato-prompt {
    margin-right: 10px;
    margin-bottom: calc(30px + env(safe-area-inset-bottom));
    height: 75px;
    cursor: pointer;
    -webkit-animation: 1s ease-in cartTutorialFadeIn;
            animation: 1s ease-in cartTutorialFadeIn;
}

.mini-cart-rtp-prompt {
    margin: 20px;
    margin-bottom: calc(65px + env(safe-area-inset-bottom));
    height: 96px;
    cursor: pointer;
    -webkit-animation: 1s ease-in cartTutorialFadeIn;
            animation: 1s ease-in cartTutorialFadeIn;
}

.round-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem;
    flex-basis: 0;
    padding-bottom: 10px;
}

.round-img-coming-soon {
    -webkit-filter: saturate(0%);
            filter: saturate(0%);
    opacity: 0.8;
}

.round-container.medium {
    max-width: 25vw;
}

.round-container.small {
    max-width: 20vw;
}

.round-label {
    font-family: Poppins;
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    margin-top: 10px;
    text-align: center;
    word-break: break-word;
    max-width: calc(100vw * 0.25);
}

.round-image {
    border-radius: 50%;
    object-fit: cover;
    background-color: white;
}


.round-comp-not-in-stock .round-image {
    opacity: 0.5;
}

.round-comp-not-in-stock .round-label {
    opacity: 0.5;
}

.out-of-stock-text {
    position: absolute;
    color: black;
    opacity: 0.7;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-top: -10px;
}


@media only screen and (min-width: 768px) {
    .round-label {
        font-size: 13px;
        max-width: 196px;
    }
}

.menuContainer {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, 25vw);
    justify-content: space-between;
    margin-top: 30px;
    padding-bottom: 60px;
}

@media only screen and (min-width: 1024px) {
    .menuContainer {
        grid-template-columns: repeat(auto-fill, 20vw);
    }
}

@media only screen and (min-width: 1440px) {
    .menuContainer {
        grid-template-columns: repeat(auto-fill, 15vw);
    }
}

@media only screen and (min-width: 2560px) {
    .menuContainer {
        grid-template-columns: repeat(auto-fill, 10vw);
    }
}

.logoAndPhoneCallContainer {
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-left: 2%;
}

.topHeaderTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 20px;
    color: #050606;
    margin-left: 13px;
    padding-right: 10px;

    -webkit-text-size-adjust: auto;

       -moz-text-size-adjust: auto;

            text-size-adjust: auto;
    width: 100%;
}

.v2HeaderGKLogo {
    max-width: 60vw;
    height: 100%;
}

@media only screen and (min-width: 768px) {
    .v2HeaderGKLogo {
        max-height: 30px;
    }
}

.dineSelectHeader {
    width: 100vw;
    background-color: #f8f8f8;
}

.dineSelectHeaderInnerContainer {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dineSelectLeft {
    display: flex;
    align-items: center;
}

.dineSelectUserName {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #1c4055;
    margin-right: 10px;
}

.dineSelectType {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    /* identical to box height */
    color: #727272;
}

.dineSelectEditButton {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    /* identical to box height */
    color: #0885bb;
}

.languageSwitchContainer {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
}

.tooltipLabelFix {
    word-break: normal;
}

.MuiSpeedDialAction-staticTooltipLabel {
    width: -webkit-max-content;
    width: max-content;
}

.v2CampaignBlock {
    height: 200px;
    width: 260px;
    background-color: #eee;
    border-radius: 9px;
    display: flex;
    font-family: 'Poppins';
    cursor: pointer;
}

.v2CampaignBlockContainer {
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    flex-wrap: nowrap;
    padding-top: 30px;
    padding-bottom: 30px;
    color: #444;
}

.v2CampaignBlockLabel {
    font-weight: 700;
    font-size: 11px;
    margin-bottom: 8px;
}

.v2CampaignBlockName {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120.5%;
}

.v2CampaignBlockInnerContainer {
    padding: 15px;
    width: 100%;
}

@media only screen and (min-width: 768px) {
    .v2CampaignBlockContainer {
        flex-direction: row;
        flex-wrap: wrap;
        overflow-y: unset;
    }
}

.dineInExistingOrderHeader {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    /* identical to box height */
    letter-spacing: 0.03em;
    color: #000000;
    padding-left: 20px;
    padding-top: 20px;
}
.dineInExistingOrderProgressBarContainer {
    display: flex;
    justify-content: center;
    padding: 20px;
    padding-top: 0;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ripple div {
    position: absolute;
    border: 4px solid #fff;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
            animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
    -webkit-animation-delay: -0.5s;
            animation-delay: -0.5s;
}
@-webkit-keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}
@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

.MuiSelect-icon {
    color: #fff !important;
}

.box1 {
    position: relative;
    box-sizing: border-box;
}

.box2 {
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
}

.box3 {
    align-items: center;
    display: flex;
    flex: 1 1;
    -webkit-box-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 2px 8px;
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

.dropoffInput {
    border: 0;
    width: 100%;
    font-size: 16px;
}
.inputSubmit {
    text-transform: none;
    background-color: #20b04b;
    color: white;
    font-weight: 700;
    padding: 10px 20px 10px 20px;
    border-radius: 10000px;
    border: 0;
    font-size: 16px;
}

.whiteBackground {
    background-color: white;
}

.modalTop {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;
}

.modalContent {
    padding: 10px;
    background-color: #fff;
}

textarea:focus,
input:focus {
    outline: none;
}

.dineInCartButtons {
    margin: 10px;
    width: calc(100% - 20px);
    position: fixed;
    bottom: 20px;
    left: 0;
    background-color: #fff;
    border-top: 1px solid #e6e6e6;
}
.languagePickerContainer {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 1rem;
}

.languagePickerContainer .MuiSelect-select.MuiSelect-select {
    padding-right: 30px !important;
}

.guestName {
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
}

.guestName input {
    /* width: 100%; */
    border: none;
    font-size: 16px;
}

.guestName label {
    flex-basis: 50%;
}

.radioButtons {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
}

.phoneInput {
    padding: 0px 0px 0px 10px;
    margin: 0px;
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    --PhoneInputInput-text-align: left;
}

@media only screen and (max-width: 768px) {
    .guestName {
        flex-direction: column;
        grid-gap: 0;
        gap: 0;
    }
    .deliveryForm label {
        margin: 0;
        padding: 0;
    }
}

.iconStyle {
    font-size: 2rem;
    flex-shrink: 0;
}

.input-container {
    display: flex;
    flex: 1 1;
    flex-basis: 240px;
    flex-shrink: 0;
    align-items: center;
    margin: 5px 0 15px 0;
}

#card-number {
    font-family: 'Montserrat';
    background-color: transparent;
    border-width: 0px;
    padding: 0.5rem;
    font-size: 0.8rem;
}

#card-expiry {
    font-family: 'Montserrat';
    background-color: transparent;
    border-width: 0px;
    padding: 0.5rem;
    font-size: 0.8rem;
}

#card-cvv {
    font-family: 'Montserrat';
    background-color: transparent;
    border-width: 0px;
    padding: 0.5rem;
    font-size: 0.8rem;
}

@media only screen and (max-width: 710px) {
    #last-row-media {
        margin: 5px 0 15px 0;
    }
    .input-container {
        margin: 10px 0 5px 0;
    }
}

#apple-pay-button {
     margin-top: 1rem;
     align-self: 'center';
     --apple-pay-button-width: 66vw;
     --apple-pay-button-height: 40px;
     --apple-pay-button-border-radius: 5px;
     --apple-pay-button-padding: 0px 0px;
     --apple-pay-button-box-sizing: border-box;
     --apple-pay-button-font-size: 12px;
 }

.categoryTitleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 10px 5px 20px;
}

.categoryItemContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: scroll;
    overflow-y: hidden;
    padding-left: 20px;
    padding-bottom: 5px;
    flex-basis: 0;
}

.categoryItemLabel {
    color: #20b04b;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 2px;
}

.menuCategory:first-of-type {
    margin-top: 20px;
}

@media only screen and (min-width: 768px) {
    .categoryItemContainer {
        overflow: hidden;
    }
}

.menuHoursButton {
    display: inline-block;
    padding: 15px;
    margin: 15px;
    background-color: #eee;
    border-radius: 100px;
    font-weight: 600;
    cursor: pointer;
}
.menuHoursButton + .menuHoursButton {
    margin-left: 0;
}
.menuHoursRow {
    display: flex;
    flex-wrap: wrap;
}

.singleCategoryContainer {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, 25vw);
    justify-content: center;
    margin-top: 30px;
}

.singleCategoryMenuDescription {
    margin: 20px;
    margin-bottom: 0;
    color: #555;
}

@media only screen and (min-width: 1024px) {
    .singleCategoryContainer {
        grid-template-columns: repeat(auto-fill, 20vw);
    }
}

@media only screen and (min-width: 1440px) {
    .singleCategoryContainer {
        grid-template-columns: repeat(auto-fill, 15vw);
    }
}

@media only screen and (min-width: 2560px) {
    .singleCategoryContainer {
        grid-template-columns: repeat(auto-fill, 10vw);
    }
}

.toolBarContainer {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}

.toolBarSearchTextFieldContainer {
    display: flex;
    flex-grow: 10;
    margin-top: 6px;
}

.searchResultContainer {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, 25vw);
    justify-content: center;
    margin-top: 30px;
}

@media only screen and (min-width: 1024px) {
    .searchResultContainer {
        grid-template-columns: repeat(auto-fill, 20vw);
    }
}

@media only screen and (min-width: 1440px) {
    .searchResultContainer {
        grid-template-columns: repeat(auto-fill, 15vw);
    }
}

@media only screen and (min-width: 2560px) {
    .searchResultContainer {
        grid-template-columns: repeat(auto-fill, 10vw);
    }
}
.iconContainer {
    display: flex;
    align-items: center;
}

* {
    font-family: 'DM Sans', sans-serif;
}

.v2CampaignDetailsName {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 119.5%;
    margin-top: 48px;
}

.v2CampaignDetailText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    margin-top: 30px;
}

:root {
    --min-height: 100vh;
}

.v2RootNavbar {
    z-index: 999999;
    position: fixed;
    bottom: 0;
    width: 100vw;
}

.v2RootBody {
    background-color: #f5f5f7;
    min-height: 100vh;
    min-height: var(--min-height);
    display: flex;
    flex-direction: column;
}

.pom-container {
    position: -webkit-sticky;
    position: sticky;
    bottom: env(safe-area-inset-bottom);
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    position: fixed;
    bottom: 0;
    z-index: 999;
    height: 29px;
    width: 100%;
}

.pom-logo {
    height: 18px;
    padding-top: 3px;
    opacity: 1;
    width: 100%;
}

.pom-inset-bottom {
    position: fixed;
    bottom: 0;
    height: env(safe-area-inset-bottom);
    background-color: #fff;
}

.tableSelectContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    padding: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}

.tableModalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1;
}

.tableSelectWelcomeHeader {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
    color: #20b04b;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.tableSelectWelcomeDescription {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #454545;
    margin: 10px;
    max-width: 60%;
}

.tableSelectInputContainer {
    width: 160px;
    box-shadow: 0px 6.32386px 20.7784px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-top: 20px;
}

.tableSelectInputContainerSafari {
    transform: scale(0.5);
    -webkit-transform: scale(0.5, 0.5);
}

.tableSelectInputHeader {
    background: #20b04b;
    padding: 20px;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    color: #fff;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 27px;
    line-height: 33px;
}

.TableSelectInputFieldContainer {
    background-color: #fff;
    padding: 20px;
    display: flex;
    justify-content: center;
    border-radius: 0px 0px 10px 10px;
}

.tableSelectInputField {
    border: 0;
    border-bottom: 1.22685px solid #bfbfbf;
    width: 108px;
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 72px;
    line-height: 116px;
    letter-spacing: -0.03em;
    color: #1c4055;
    -webkit-appearance: none;
}

.tableSelectNextButton {
    background: #1c4055;
    width: calc(160px - 40px);
    border-radius: 30px;
    padding: 20px;
    display: flex;
    justify-content: center;
    font-family: Poppins;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
}

.tableSelectNextButtonDisabled {
    background-color: #d8d8d8;
    transition: 0.3s ease-in-out;
}

.guestSelectGroupContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.guestSelectGuestContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px;
    align-items: center;
}

.guestSelectEditTableBtn {
    /* background-color: #DBDBDB; */
    color: #103c54;
    padding: 5px;
    font-size: 11px;
    border-radius: 3px;
    display: inline-block;
    margin-left: 10px;
}

.guestSelectCircle {
    width: 96px;
    height: 96px;
    background-color: #dbdbdb;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 36px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
}

.guestSelectName {
    margin-top: 10px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
}

.addGuestModal {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #4f4f4f;
}

.addGuestModalContainer {
    min-width: 250px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
}

.addGuestInput {
    background: #ffffff;
    border: 1px solid #ececec;
    border-radius: 10px;
    width: 160px;
    text-align: center;
    padding: 5px;

    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* identical to box height */

    color: #1c4055;
}

.dineSelectRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
}

.dineSelectPrimaryButton {
    min-width: 80px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
}

.dineSelectPrimaryButton + .dineSelectPrimaryButton {
    margin-left: 10px;
}

.dineSelectPrimaryImage {
    width: 60px;
    height: 60px;
}

.dineSelectPrimaryText {
    color: #1c4055;
    margin-top: 20px;
}

.fof-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
}

.fof-number {
    font-size: 96px;
    font-weight: 700;
    opacity: 0.3;
}

.fof-text {
    font-size: 24px;
    opacity: 0.67;
    text-align: center;
}

.fof-logo {
    margin-top: 120px;
    width: 150px;
}

.foh-container {
    position: fixed;
    bottom: 60px;
    right: 0;
    z-index: 999999999;
    margin: 10px;
    padding: 15px;
    background-color: blue;
    color: #fff;
    border-radius: 50px;
}

.foh-container-expanded {
    position: fixed;
    z-index: 99999;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    overflow-y: auto;
}

