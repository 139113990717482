.KioskErrorBoundaryPage {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.KioskErrorBoundaryContainer {
    max-width: 75vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.KioskErrorTitle {
    font-size: 90px;
    font-family: 'Roboto';
}

.KioskRestartButton {
    font-size: 50px;
    padding: 30px 35px;
    font-weight: 400;
    border: 3px solid lightgrey;
    border-radius: 30px;
    margin-bottom: 30px;
    cursor: pointer;
}

.KioskRestartCountDown {
    font-size: 30px;
    margin-bottom: 70px;
}

.KioskRestartButton:hover {
    background-color: lightgrey;
    color: white;
}

.KioskErrorMessage {
    color: grey;
    font-size: 20px;
}

@media only screen and (min-width: 700px) {
    .KioskError {
        font-size: 60px;
        font-family: 'Roboto';
    }
}
