@keyframes to-page-container-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.to-page-payment-options-container {
    display: flex;
    margin-top: 100px;
}

.to-page-payment-option {
    display: flex;

    flex-direction: column;
    justify-content: space-between;

    height: 400px;
    width: 35vw;
    background-color: #fff;
    border-radius: 50px;

    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);
    transition: 0.5s ease;

    border: 20px solid #ccc;
}

.to-page-payment-option:active {
    filter: brightness(60%);
}

.to-page-payment-back-button {
    position: absolute;
    bottom: 100px;

    margin-top: 100px;
    background-color: #01161e;
    width: calc(70vw);
    left: 15vw;
    padding: 20px;
    border-radius: 50px;
    text-align: center;

    font-size: 28px;
    font-weight: 700;
    color: #fff;
}

.not-absolute {
    position: relative;
}

.to-page-payment-option-label {
    margin-bottom: 30px;
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
}

.to-page-payment-option-image {
    width: calc(100% - 40px);
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    object-fit: contain;
}

.to-page-payment-option + .to-page-payment-option {
    margin-left: 50px;
}

.to-page-container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;

    animation-name: to-page-container-fade-in;
    animation-duration: 1s;

    z-index: 1000;
}

.to-page-container-item {
    bottom: 0;
    height: calc(100vh);

    box-shadow: 0 0 200px rgba(49, 49, 49, 0.897);
}

.to-page-inner-container {
    padding: 30px;
    width: calc(100% - 60px);
    overflow: auto;
    padding-bottom: 600px;
}

.to-page-item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.to-page-header-spacing {
    height: 100px;
}

.to-page-item-name {
    margin-left: 40px;
    margin-right: 40px;
    font-family: 'Open Sans';
    font-size: 36px;
}

.to-page-item-description {
    font-size: 23px;
    color: #555;
    margin-top: 30px;
}

.to-page-item-modifiers {
    margin: 30px;
    margin-top: 100px;
}

.to-page-item-modifier-error {
    font-size: 30px;
    font-family: 'Open Sans';
    color: rgb(187, 0, 0);
}

.to-page-item-one-modifier-group-modifier-container {
    display: flex;
    flex-wrap: wrap;
}

.to-page-item-modifier {
    /* width: 400px; */
    font-size: 30px;
    font-family: 'Open Sans';
    border: 4px solid rgb(224, 224, 224);
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    margin: 3px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;

    display: flex;
    align-items: center;
}

.to-page-item-bottom-container-spacing {
    padding-bottom: 300px;
}

.to-p-i-m-checked {
    background-color: #90323d;
    color: #fff;
    border-color: #90323d;
}

.to-page-item-one-modifier-name {
    margin-left: 30px;
    font-size: 28px;
    font-family: 'Roboto';
    font-weight: 500;
    margin-right: 40px;
    width: 200px;
}

.to-page-item-one-modifier-required {
    margin-top: 10px;
    font-family: 'Open Sans';
    font-size: 20px;
}

.to-page-item-one-modifier-index {
    max-width: 100px;
    max-height: 100px;
    min-width: 100px;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #90323d;
    color: #fff;
    border-radius: 100px;
    font-size: 50px;
    font-family: 'Open Sans';
}
.to-page-item-one-modifier {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 100%;
}

.to-page-item-one-modifier + .to-page-item-one-modifier {
    margin-top: 50px;
}

.to-page-item-image {
    min-width: 300px;
    min-height: 300px;
    max-width: 300px;
    max-height: 300px;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
}

.to-container {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    margin-top: 0px;
}
.to-container-left-home-btn {
    padding: 20px;
    width: calc(100% - 80px);
    height: 150px;
    object-fit: contain;
    background: linear-gradient(154.59deg, #04ac01 20%, #005e25 98.12%);
    box-shadow: 0 0 30px rgba(0, 151, 13, 0.5);
    margin: 20px;
    /* margin-bottom: 60px; */
    /* border-radius: 25px; */
}

@keyframes itemFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes textFadeInFromWhiteToBlack {
    0% {
        color: #fff;
    }
    100% {
        color: #000;
    }
}

.to-container-menu-name {
    font-size: 48px;
    font-weight: 500;
    color: #333;
    padding-top: 40px;
    padding-bottom: 40px;
    font-family: 'Open Sans';
    font-weight: 700;

    animation: 0.3s ease-in textFadeInFromWhiteToBlack;
}
.to-container-menu-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
}

@keyframes opacityFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.to-container-menu-item {
    display: block;
    width: 190px;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.2);

    padding: 15px;
    display: flex;
    margin: 15px;

    border: 1px solid #ddd;

    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;

    animation: 0.3s ease-in opacityFadeIn;
    transition: 0.1s ease-in-out;
}

.item-container-sold-out {
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.05);
    border: 1px solid #eee;
}

.kiosk-out-of-stock {
    opacity: 0.3;
}

.to-container-menu-item:active {
    transform: scale(1.1);
}

.to-container-menu-item-photo {
    height: 200px;
    width: 100%;
    max-height: 200px;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    image-rendering: auto;
    object-fit: contain;
    margin-bottom: 20px;
}

.to-container-menu-item-label {
    flex-grow: 1;
    font-size: 20px;
    font-weight: 900;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: 'Open Sans';
    padding: 10px;
    padding-bottom: 0;
    border-top: 1px solid #ccc;
    color: #333;
}

.to-page-item-bottom-container {
    position: fixed;
    bottom: 0;
    width: 100vw;
    left: 0;
    height: 700px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fff;
    box-shadow: 0px -10px 30px rgba(0, 0, 0, 0.05);
}

.to-page-item-bottom-quantity-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: 'Open Sans';
    font-size: 38px;

    border-top: 2px solid #eee;
    padding-top: 20px;
    padding-bottom: 10px;
}

.to-quantity {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    min-width: 400px;
    max-width: 400px;
    border: 2px solid #ddd;
    align-items: center;
    border-radius: 13px;
    margin-top: 10px;
    font-family: 'Open Sans';
    font-weight: 700;
}

.to-quantity-minus {
    background-color: #ddd;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 60px;
    line-height: 40px;
    color: #000;
    border-radius: 10px;
    font-weight: 900;

    border-right: 2px solid #ddd;
    transition: 0.1s ease-in-out;
}
.to-quantity-minus:active {
    transform: scale(1.2);
}

.to-quantity-plus {
    background-color: #ddd;
    padding: 20px;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 60px;
    line-height: 40px;
    color: #000;
    border-radius: 10px;
    font-weight: 900;

    border-left: 2px solid #ddd;
    transition: 0.1s ease-in-out;
}
.to-quantity-plus:active {
    transform: scale(1.2);
}

.to-item-save {
    display: flex;
    min-width: 100%;
    font-family: 'Open Sans';
}

.to-item-save-button {
    min-width: 40%;
    max-width: 40%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 20px;
    margin-bottom: 10px;

    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #90323d;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    font-size: 30px;
    font-family: 'Open Sans';

    transition: 0.1s ease-in-out;
}
.to-item-save-button:active {
    transform: scale(0.9);
}

.to-item-cancel {
    background-color: rgb(158, 34, 34);
}

.tablet-phone-input {
    font-size: 40px;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
}

@keyframes minicartAnimation {
    0% {
        bottom: -300px;
    }
    100% {
        bottom: 0px;
    }
}

@keyframes animatePageFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.animate-page-fade-in {
    /* animation: 1s ease animatePageFadeIn; */
}

.to-minicart-container {
    height: 500px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: linear-gradient(75deg, #24c3c9 0.27%, #34b823 100.44%);
    background-size: cover;
    /* background: #000; */
    /* background: #1E823C; */
    background: rgb(7, 17, 8);

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}
.my-order-animate {
    animation: 1s ease-in-out minicartAnimation;
}

.to-container-height-adjust-minicart {
    height: calc(100vh - 300px);
}

.to-minicart-inner-container {
    padding: 20px;
    font-family: 'Open Sans';
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #fff;
    height: 160px;
}

.to-minicart-my-order {
    font-size: 36px;
    font-weight: 600;
}

.to-minicart-my-order-summary {
    font-size: 30px;
    display: flex;
    justify-content: space-between;
}

.to-order-save {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.to-order-save-button {
    min-width: calc(50% - 10px);
    max-width: calc(50% - 10px);

    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0;
}

.to-order-black {
    background-color: #000;
}

.to-page-container-dark {
    background: rgb(0, 38, 7);
    background: #fff;
    color: #fff;
}

.to-page-inner-container-dark {
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #000;
}

.to-page-review-logo {
    margin-top: 150px;
    width: 400px;
}

.to-page-review-heading {
    margin-top: 50px;
    font-family: 'Open Sans';
    font-size: 50px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 50px;
    color: #000;
}

.to-page-review-nuvei-warning {
    margin-top: 50px;
    opacity: 60%;
    font-family: 'Open Sans';
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color: #000;
}

.to-page-review-items {
    width: 800px;
    border: 2px solid #000;
    border-radius: 30px;
    padding: 30px;
    background-color: #fff;
}

.to-page-review-item-name {
    font-family: 'Open Sans';
    color: #222;
    font-size: 28px;
    font-weight: 600;
}

.kiosk-discount-modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    background-color: rgba(255, 255, 255, 0.8);
    z-index: 999;
}

.kiosk-discount-modal-window {
    width: calc(80vw - 64px);
    left: 10vw;
    top: 250px;
    position: absolute;
    background-color: #fff;

    padding: 32px;

    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 99px #00000052;
    border-radius: 36px;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
.kiosk-discount-modal-text-field {
    font-family: 'Poppins';
    font-weight: 400;
    color: #222;
    border: 0;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    font-size: 36px;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 10px solid #dbdbdb;
}

.kiosk-discount-modal-button-container {
    display: flex;
    justify-content: space-between;
}

.kiosk-discount-modal-button {
    background: #e2e2e2 0% 0% no-repeat padding-box;
    border-radius: 11px;
    opacity: 1;

    width: 330px;

    text-align: left;
    font: normal normal bold 28px/37px Segoe UI;
    letter-spacing: 0px;

    opacity: 1;
    padding: 22px;
    padding-left: 32px;
}

.kiosk-discount-modal-keyboard {
    position: absolute;
    top: 800px;
    width: 100%;
}

.kiosk-discount-modal-keyboard-background {
    position: absolute;
    top: 800px;
    background-color: #fff;
    height: 100vh;
    width: 100vw;
}

.modal-button-blue {
    color: #568eff;
}

.modal-button-red {
    color: #e62626;
}

.kiosk-discount-modal-body {
    margin-top: 72px;
}

.kiosk-discount-modal-alert {
    font: normal normal 600 22px/30px Segoe UI;
    letter-spacing: 0px;
    color: #4e4e4e;
    opacity: 1;
}

.kiosk-discount-modal-title {
    font: normal normal bold 36px/48px Segoe UI;
    letter-spacing: 0px;
    color: #4e4e4e;
    opacity: 1;
}

.kiosk-discount-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.no-border {
    border: 0;
}

.to-page-review-modifier {
    color: #000;
    margin-top: 10px;
    font-size: 28px;
    font-family: 'Open Sans';
}

.to-page-review-modifier + .to-page-review-modifier {
    margin-top: 0;
}

.to-page-review-item-container {
    width: 100%;
    margin-bottom: 0;
}

.to-page-review-item-container + .to-page-review-item-container {
    border-top: 2px solid #999;
    padding-top: 20px;
}

.to-page-review-items + .to-page-review-items {
    margin-top: 20px;
}

.to-page-review-items:last-of-type {
    padding-bottom: 50px;
}

.to-page-review-item-button-container {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.to-page-review-item-button {
    display: flex;
    align-items: center;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #01161e;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    font-size: 30px;
    font-family: 'Open Sans';
    transition: 0.1s ease-in-out;
}
.to-page-review-item-button:active {
    transform: scale(0.9);
}

.to-page-review-quantity {
    margin-top: 0;
    font-size: 30px;
    font-weight: 600;
    font-family: 'Open Sans';
}

.to-page-order-success {
    font-family: 'Open Sans';
    color: #000;
    font-size: 40px;
    width: 600px;
    text-align: center;
    margin: 20px;
    margin-top: 60px;
}

.to-page-order-number {
    padding: 20px;
    color: #fff;
    background-color: #90323d;
    border-radius: 10px;
}

.to-page-order-number {
    text-align: center;
    font-family: 'Roboto Mono';
    font-size: 100px;
    font-weight: 700;
    margin-top: 40px;
}

.to-page-table-number {
    font-size: 40px;
    font-weight: 700;
    background-color: #fff;
    color: #90323d;
    padding: 10px;
    border-radius: 100px;
    padding-left: 25px;
    padding-right: 25px;
}

.topHeaderBarImage {
    height: 200px;
    width: 100%;
    object-fit: cover;
    object-position: 0% 0%;
    border: 0;
    padding: 0;
    margin: 0;
}

.brand-select-header-combined {
    /* background: linear-gradient(180deg, #0C2830 18.23%, rgba(12, 40, 48, 0) 100%); */
    /* background-color: #0C2830; */
    /* background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, #dfdfdf 100%); */
}

.kiosk-header {
    height: 140px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* background-color: #fff; */
    /* margin-bottom: 10px; */

    background-color: #fff;
}

.kiosk-header-logo {
    height: 95px;
    margin-left: 25px;
    margin-top: 5px;
}

.kiosk-brand-select-mini {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: auto;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.to-container-left-menu:first-of-type {
    margin-left: 65px;
}
.to-container-left-menu:last-of-type {
    margin-right: 65px;
}

.to-container-left-menu {
    margin: 10px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 180px;
    display: flex;
    justify-content: center;
    position: relative;
}

.to-container-left-menu-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-size: cover;
    box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
    transition: 0.1s ease-in-out;
    border: 15px solid #fff;
}

.to-container-left-menu-img:active {
}

.to-container-left-menu-img-coming-soon:active {
    width: 150px;
    height: 150px;
    border: 15px solid #ff7272;
}

.to-menu-selected {
    width: 150px;
    height: 150px;
    border: 15px solid #a0c5a0;
}

.passwordLabel {
    font-size: 36px;
    margin: 10px;
}

.passwordContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.passwordInput {
    font-size: 36px;
    border: 1px solid #000;
}

.passwordSubmitButton {
    font-size: 36px;
    margin: 10px;
}

.backToOrderingButton {
    font-size: 36px;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 100px;
    background: none;
    border: none;
    border-radius: 10px;
    background-color: #840032;
    color: #fff;
    padding-left: 40px;
    padding-right: 40px;
}

@keyframes slideFromLeft {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes tapAnimation {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(0.9);
    }
    75% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}

.kiosk-category-sidebar {
    width: 130px;
    /* border: 1px solid #E1E1E1; */
    padding: 15px;
    border-radius: 10px;

    margin: 5px;
    transition: 0.1s;

    /* animation: 0.3s ease-in opacityFadeIn; */
}

.kiosk-category-sidebar:active {
    animation: 0.1s ease-in-out tapAnimation;
}

.kiosk-category-sidebar:first-of-type {
    margin-top: 60px;
}
.kiosk-category-sidebar:last-of-type {
    margin-bottom: 75px;
}

.kiosk-category {
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
    /* transition: 1s ease-in-out slideFromLeft; */
    transition: 0.5s ease-in-out;
    background-color: #fff;
}

.kiosk-category-selected {
    background-color: #a0c5a0;
}

.kiosk-category-sidebar-image {
    min-width: 130px;
    min-height: 130px;
    max-height: 130px;
    max-width: 130px;
    object-fit: cover;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: #fff;
}
.kiosk-category-sidebar-line {
    height: 1px;
    background-color: #e1e1e1;
}

.kiosk-category-sidebar-text {
    text-align: center;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 7.5px;
    font-weight: 600;
    font-family: 'Open Sans';
    background-color: #fff;
}

.to-container-menu-item-price {
    font-size: 18px;
    font-weight: 400;
    margin-top: 5px;
}

.to-v2-button {
    height: 100%;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #840032;
    color: #fff;
    font-family: 'Lato';
    font-size: 26px;
    font-weight: 700;

    flex-direction: column;
    border-radius: 10px;

    transition: 0.1s ease-in-out;
}

.to-v2-button:active {
    transform: scale(0.9);
}

.to-v2-button-icons {
    height: 60px;
    width: 60px;
    margin-bottom: 20px;
}

.to-v2-button-icon-sm {
    height: 40px;
    width: 40px;
    margin-bottom: 20px;
}

.v2-button-cancel {
    background-color: #ffffff28;
    color: #fff;
}

.to-container-back-button-row {
    display: flex;
    justify-content: flex-end;
    width: calc(100vw);
    margin-bottom: 50px;
}

.to-container-back-button {
    padding: 15px;
    padding-left: 90px;
    padding-right: 90px;
    background-color: #d68c45;
    color: #fff;
    font-size: 28px;

    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    transition: 0.1s ease-in-out;
}
.to-container-back-button:active {
    transform: scale(0.9);
}

.brand-left-chevron {
    position: absolute;
    height: 185px;

    background-color: rgba(255, 255, 255, 0.8);

    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
}

.brand-right-chevron {
    position: absolute;
    height: 185px;

    background-color: rgba(255, 255, 255, 0.8);

    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;

    transition: 0.3s ease-in-out;
}

.brand-left-chevron-icon {
    width: 20px;
    opacity: 0.7;
}

.to-page-item-modifier-selector {
    width: 25px;
    height: 25px;
}

.modifier-label {
    font-size: 18px;
}

.to-container-left {
    min-width: 200px;
    max-width: 200px;
    /* background-color: #eee; */

    /* border-right: px solid #999; */
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.15);

    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 900px);
    overflow: auto;

    border-radius: 10px 10px 10px 10px;
}

.to-container-right {
    padding: 20px;

    height: calc(100vh - 879px);
    overflow: auto;

    padding-top: 0;
    padding-bottom: 0;
}

.to-page-item-header-fixed {
    position: fixed;
    top: 0;
    width: calc(100% - 80px);
    padding-left: 40px;
    padding-right: 40px;
    left: 0;
    box-shadow: 0 5px 75px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    z-index: 10;
}

.to-page-item-header-fixed-spacing {
    height: 300px;
}

@media (min-aspect-ratio: 16/9) {
    .to-minicart-my-order {
        font-size: 26px;
    }
    .to-minicart-my-order-summary {
        font-size: 26px;
    }
    .to-minicart-container {
        height: 150px;
        padding: 10px;
    }
    .to-minicart-inner-container {
        height: 130px;
        padding: 0;
    }
    .to-v2-button-icon-sm {
        margin-bottom: 10px;
    }
    .kiosk-header-logo {
        height: 60px;
    }
    .kiosk-header {
        height: 90px;
    }
    .to-container-left-menu-img {
        height: 90px;
        width: 90px;
    }

    .brand-left-chevron {
        height: 130px;
    }
    .brand-right-chevron {
        height: 130px;
    }
    .to-container {
        margin-top: 10px;
    }
    .kiosk-brand-select-mini {
        margin-bottom: 10px;
    }
    .to-container-left {
        max-height: 675px;
        min-height: 675px;
        min-width: 175px;
        max-width: 175px;
    }
    .to-container-right {
        max-height: 675px;
        min-height: 675px;
    }
    .brand-left-chevron-icon {
        width: 15px;
    }
    .kiosk-category-sidebar {
        width: 130px;
        margin: 0;
    }
    .kiosk-category-sidebar-image {
        min-height: 130px;
        max-height: 130px;
        min-width: 130px;
        max-width: 130px;
    }

    .kiosk-category-sidebar-text {
        max-width: 120px;
    }
    .kiosk-category-sidebar-line {
        max-width: 120px;
    }
    .kiosk-category-sidebar-text {
        font-size: 15px;
        padding-top: 5px;
        padding-bottom: 10px;
    }
    .to-container-menu-item-photo {
        height: 100px;
    }
    .to-container-menu-item-label {
        font-size: 16px;
    }
    .to-container-menu-item-price {
        font-size: 16px;
    }
    .to-container-left {
        min-width: 525px;
        max-width: 525px;
    }
    .category-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 75px;
        margin-bottom: 75px;
    }
    .kiosk-category-sidebar:first-of-type {
        margin-top: 0;
    }
    .kiosk-category-sidebar:last-of-type {
        margin-bottom: 0;
    }
    .to-container-back-button-row {
        margin-bottom: 10px;
    }
    .to-container-back-button {
        font-size: 18px;
        padding: 10px;
        padding-left: 45px;
        padding-right: 45px;
    }

    .to-page-inner-container {
        display: flex;
        justify-content: space-between;
    }
    .to-page-item-name {
        font-size: 26px;
    }
    .to-page-item-modifiers {
        overflow: auto;
        margin: 0;
        padding-top: 60px;
        padding-bottom: 60px;
        margin-left: 100px;
    }
    .to-page-item-one-modifier {
        justify-content: flex-start;
    }
    .to-page-item-one-modifier-index {
        min-height: 50px;
        max-height: 50px;
        min-width: 50px;
        min-height: 50px;
        font-size: 25px;
    }
    .to-page-item-one-modifier-name {
        font-size: 28px;
    }
    .to-page-item-modifier {
        width: auto;
    }
    .to-page-item-modifier-selector {
        height: 15px;
        width: 15px;
    }
    .modifier-label {
        font-size: 20px;
    }
    .to-page-item-one-modifier-group-modifier-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }
    .to-page-item-bottom-quantity-container {
        font-size: 26px;
    }
    .to-page-review-logo {
        margin-top: 50px;
        width: 200px;
    }
    .to-page-review-heading {
        font-size: 25px;
    }
    .to-page-review-nuvei-warning {
        font-size: 15px;
    }
    .to-page-review-item-button {
        font-size: 20px;
        padding-left: 25px;
        padding-right: 25px;
    }
    .to-page-payment-options-container {
        margin-top: 50px;
    }
    .to-page-payment-option-image {
        width: 250px;
    }
    .to-page-payment-option {
        height: auto;
        width: auto;
    }
    .to-page-payment-option-label {
        font-size: 30px;
        margin-top: 10px;
    }
}
