.kioskTerminalSetup {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    z-index: 99999;
}

.kioskTerminalSetupInnerContainer {
    padding: 30px;
}

.kioskTerminalReaderRow {
    display: flex;
    border: 2px solid #888;
    justify-content: space-evenly;
    padding: 20px;
    align-items: center;
}

.kioskTerminalReaderRow + .kioskTerminalReaderRow {
    margin-top: 30px;
}

.kiskoTerminalConnectButton {
    padding: 30px;
    background-color: rgb(0, 114, 19);
    border-radius: 10px;
    color: #fff;
    font-size: 26px;
    transition: 0.3s ease-in-out;
}
.kiskoTerminalConnectButton:active {
    transform: scale(0.8);
}

@keyframes colorAnimation {
    0% {
        background-color: #11aa11d8;
    }
    50% {
        background-color: #11aa11;
    }
    100% {
        background-color: #11aa11d8;
    }
}

.kiosk-home-actionbar {
    position: absolute;
    bottom: calc(((100vh - 250px) / 2) - 600px);
    left: calc((100vw - 500px) / 2);
    min-width: 500px;
    min-height: 250px;
    backdrop-filter: blur(50px) saturate(250%);
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 95px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    animation: 1.5s ease-in-out colorAnimation infinite;
}

@keyframes textAnimation {
    0% {
        opacity: 0.6;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.6;
    }
}

.kiosk-home-touch-icon {
    width: 150px;
    /* animation: 2s ease-in-out textAnimation infinite; */
}

.kiosk-home-actionbar-text {
    color: #fff;
    font-size: 62px;
    font-weight: 600;
    font-family: 'Open Sans';
    margin-top: 20px;
    /* animation: 2s ease-in-out textAnimation infinite; */
}

@media (min-aspect-ratio: 16/9) {
    .kiosk-home-touch-icon {
        width: 50px;
    }
    .kiosk-home-actionbar-text {
        color: #fff;
        font-size: 28px;
        font-family: 'Open Sans';
        margin-top: 30px;
    }
}

.hiddenClickableDiv {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100vw;
    height: 100vh;
    background-image: url('/assets/kiosk/corners2.png');
    z-index: 99999;
}
