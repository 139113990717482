.singleCategoryContainer {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, 25vw);
    justify-content: center;
    margin-top: 30px;
}

.singleCategoryMenuDescription {
    margin: 20px;
    margin-bottom: 0;
    color: #555;
}

@media only screen and (min-width: 1024px) {
    .singleCategoryContainer {
        grid-template-columns: repeat(auto-fill, 20vw);
    }
}

@media only screen and (min-width: 1440px) {
    .singleCategoryContainer {
        grid-template-columns: repeat(auto-fill, 15vw);
    }
}

@media only screen and (min-width: 2560px) {
    .singleCategoryContainer {
        grid-template-columns: repeat(auto-fill, 10vw);
    }
}
