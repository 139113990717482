.kiosk-keyboard {
    width: 300px;
}

.kiosk-phonenumber-input {
    border: 0;
    border-color: #fff;
    width: 300px;
    padding: 10px;
    font-size: 36px;
    background-color: #f7f7f7;
    border-bottom: 5px solid #dadada;
    margin-bottom: 10px;
    border-radius: 5px;
    text-align: center;
    font-weight: 500;
}

.kiosk-pn-row {
    display: flex;
    flex-direction: column;
    align-items: center;
}
