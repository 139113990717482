.textBox {
    /* To override styling */
    background: none;
    border: none;
    outline: none;
    box-sizing: border-box;

    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 20px;
    margin-left: 20px;

    border: 1.2px solid #eee;
    box-sizing: border-box;

    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #758688;
    padding: 15px;
    padding-top: 16px;
    padding-bottom: 16px;

    background-color: #eee;
    transition: 0.3s ease;

    border-radius: 5px;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.textBox:focus {
    background-color: #fff;
    border: 1.2px solid #a9b3b5;
    box-sizing: border-box;
}
