.segmentedControlContainer {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    cursor: pointer;
}

.timePickerContainer {
    /* padding: 20px; */
    margin-top: 20px;
}

.dateTimePickerRow {
    display: flex;
    justify-content: space-between;
}

.segmentedControl {
    flex-grow: 1;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    font-weight: 600;
    color: #333;
    box-sizing: border-box;

    border-right: 0;

    transition: 0.3s ease;

    height: 41px;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.segmentedControl:hover {
    background-color: #469f5e;
    border-color: #469f5e;
    color: #fff;
}

.segmentedControl:active {
    filter: brightness(90%);
}

.segmentedControl + .segmentedControl {
    border-right: 1px solid #ccc;
}

.segmentedControl:first-of-type {
    border-radius: 10px 0 0 10px;
}

.segmentedControl:last-of-type {
    border-radius: 0 10px 10px 0;
}

.segmentedControlSelected {
    background-color: #469f5e;
    border: 1px #469f5e;
    color: #fff;
}
.segmentedControlSelected:hover {
    filter: opacity(90%);
}

@media only screen and (min-width: 700px) {
    .segmentedControlContainer {
        justify-content: left;
    }
}
