.spacing38 {
    height: 38px;
}

.spacing60 {
    height: 60px;
}
.spacing128 {
    height: 128px;
}

.v3Header {
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    width: auto;
    justify-content: center;
    backdrop-filter: blur(15px) saturate(350%);
    background-color: rgba(255, 255, 255, 0.8);
}

.v3HeaderInnerContainer {
    padding: 20px;
    max-width: 1200px;
    display: flex;
    align-items: center;
    flex: 1;
}

.v3HeaderLogo {
    height: 30px;
    display: flex;
}

.v3HeaderSplitRow {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.v3HeaderRow {
    display: flex;
    align-items: center;
}

.v3HeaderLink {
    margin-left: 30px;
    font-weight: 500;
    margin-right: 30px;
    font-family: 'Inter';
    color: #4d4d4d;
    cursor: pointer;
}

.v3HeaderLink:hover {
    color: #0451cb;
}

/*for texts part*/
.v3HeaderProductContent {
    border-radius: 10px;
    transition: 0.1s ease-in;
    padding-bottom: 10px;
}

.v3HeaderProductContent .v3HeaderProductSplitViewSide {
    padding-left: 12px;
    display: flex;
    flex-direction: column;
    width: 70%;
    color: #333333;
    transition: 0.1s ease-in-out;
}
.v3HeaderProductContent:hover {
    background-color: #ECF8FF;

}
.v3HeaderProductContent .v3HeaderProductSplitViewRight {
    font-size: 14px;
    float: left;
    display: flex;
    padding-top: 8px;
    align-content: center;
    width: 60px;
    justify-content: center;
}

/* the dropdown product element*/

.v3HeaderDropdownProductImg {
    margin: auto;
    width: auto;
    height: auto;
}

.v3HeaderDropdownProductTag {
    font-size: 14px;
    font-weight: 700;
    font-weight: bold;
    text-align: left;
    vertical-align: top;
}

.v3HeaderDropdownProductDescr {
    font-size: 14px;
    text-align: left;
    vertical-align: top;
}

.v3HeaderLink:hover {
    color: #0451cb;
}

.arrow {
    border: solid #4d4d4d;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-left: 10px;
    margin-bottom: 2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

/*
add dropdown for header products section here
hide by default, show when hover
*/

.v3HeaderDropdownSpace {
    height: 24px;
}
.v3HeaderDropdownBg {
    margin: auto;
    width: 100vw;
    cursor: pointer;
    background: #ffffff;
    box-shadow: 0px 7px 16px rgb(0 0 0 / 16%);
}


.v3HeaderDropdownContent {
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.v3HeaderDropdownHide {
    visibility: hidden;
    left: 0;
    position: fixed;
    opacity: 0;

    transition: height 0.2s, opacity 0.2s, visibility 0.2s ease-in;
    -webkit-transition: height 0.2s, opacity 0.2s, visibility 0.2s ease-in;
}

.v3HeaderDropdownShow {
    left: 0;
    position: fixed;
    visibility: visible;

    display: flex;
    flex-direction: column;
    opacity: 1;
    height: auto;

    transition: height 0.2s, opacity 0.2s, visibility 0.2s ease-in-out;
    -webkit-transition: height 0.2s, opacity 0.2s, visibility 0.2s ease-in-out;
}

.v3HeaderDropdownRow {
    padding-bottom: 16px;
    display: flex;
    max-width: 650px;
    flex-direction: row;
    column-gap: 45px;
    margin: auto;
    padding-left: 26px;
}

.v3HeaderProductContent {
    display: flex;
    flex: 1;
    justify-content: center;
    width: auto;
    margin-left: auto;
    margin-right: auto;
}

/*for texts part*/
.v3HeaderProductSplitViewSide {
    padding-top: 7px;
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    column-gap: 7px;
    width: 210px;
    color: #333333;
}
.v3HeaderProductSplitViewRight {
    font-size: 14px;
    float: left;
    display: flex;
    padding-top: 8px;
    vertical-align: top;
    align-content: center;
}

/* the dropdown product element*/

.v3HeaderDropdownProductTag {
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    vertical-align: top;
    padding-bottom: 7px;
}

.v3HeaderDropdownProductDscr {
    font-size: 14px;
    text-align: left;
    vertical-align: top;
    max-width: 250px;
    font-family: 'Inter';
}

.v3SectionContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.v3ContentContainer {
    max-width: 1100px;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    /*padding-bottom: 100px;*/
}

.v3HeroContainer {
    width: auto;
    max-width: 100%;
    background-image: url(/assets/landing/v3/hero-decor.svg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% 0%;
}

.v3HeroSplit {
    display: flex;
    align-items: center;
    width: auto;
    width: auto;
    padding: 0 3%;
    max-width: 1230px;
    margin: auto;
}

.v3HeroSplitSide {
    flex: 1;
}
.v3HeroTextSide {
    flex: 1;
    padding-left: 32px;
}

.v3HeroText {
    font-size: 38px;
    font-weight: 700;
    color: #232323;
    padding-top: 8%;
}

.v3HeroDescription {
    font-size: 18px;
    font-weight: 500;
    color: #525252;
    font-family: 'Inter';
    width: 80%;
}

.v3ButtonContainer {
    display: inline-block;
    color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: linear-gradient(
        119.65deg,
        #6bbecb 6.31%,
        #4770db 35.28%,
        #4151cc 63.28%,
        #1b42a7 95.14%
    );
    border-radius: 10px;
    font-family: 'Circular Std';
    font-weight: 500;
    cursor: pointer;
}

.v3ButtonInnerConatiner {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.v3ButtonChevron {
    margin-left: 10px;
}

.v3HighlightImage {
    width: 100%;
    image-rendering: -webkit-optimize-contrast;
}

.v3CenteredTagline {
    text-align: center;
    font-size: 16px;
    color: #4d4d4d;
}

.v3CenteredHeader {
    text-align: center;
    font-size: 36px;
    color: #232323;
    font-weight: 600;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 20px;
}

.v3CenteredDescription {
    font-family: 'Inter';
    font-size: 24px;
    max-width: 60%;
    color: #4d4d4d;
    text-align: center;
    margin: auto;
}

.v3BrandsList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.v3BrandLogo {
    width: 138px;
    max-height: 50px;
    object-fit: contain;
    image-rendering: -webkit-optimize-contrast;
    margin: 35px;
}

.v3HomepageBackground {
    background: url('/assets/landing/v3/desktop-landing-bg.png') no-repeat;
    background-size: cover;
    background-position: 0% 0%;
}

.v3TabbedContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.v3TabLabelContainer {
    display: flex;
}

.v3TabLabel {
    display: inline-block;
    color: #969696;
    font-size: 18px;
    font-weight: 500;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: 0.1s ease-in-out;
    cursor: pointer;
    margin: 5px;
}

.v3TabLabelSelected {
    background: #daddf1;
    border-radius: 10px;
    color: #4051cb;
}

.v3ProductSplitView {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10%;
    max-width: 805px;
    width: auto;
    margin: auto;
    height: 500px;
}

.v3ProductSplitViewSide {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
}

.v3ProductSplitViewRight {
    align-items: center;
    padding-right: 10%;
}

.v3LandingProductImage {
    width: 193px;
    margin: auto;
    image-rendering: -webkit-optimize-contrast;
}

.v3LandingProductTagline {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 12px;
}

.v3LandingProductName {
    font-size: 32px;
    font-weight: 600;
    padding-bottom: 14px;
}

.v3Landingv3LandingProductDescription {
    font-family: 'Inter';
    font-size: 16px;
    color: #555;
    padding-bottom: 10px;
    /* width: 80%; */
}

.v3LandingProductLink {
    color: #4051cb;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    text-align: left;
}

.v3LandingProductChevron {
    width: 7px;
    margin-left: 3px;
}

.v3ProductHidden {
    display: none;
}

.v3StoreHighlightSpacing {
    height: 130px;
}

.v3ReviewManyContainer {
    display: flex;
    align-items: center;
}

.v3ReviewContainer {
    padding: 30px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.1);
}

.v3ReviewStar {
    width: 16px;
}

.v3ReviewPerson {
    display: flex;
    align-items: center;
}

.v3ReviewPosition {
    color: #8a8a8a;
}

.v3ReviewPersonDescription {
    font-family: 'Inter';
    margin-left: 10px;
}

.v3ReviewPersonImage {
    width: 60px;
}

.v3ReviewName {
    font-weight: 600;
}

.v3ReviewText {
    color: #8a8a8a;
    margin-top: 5px;
    margin-bottom: 5px;
}

.v3ReviewContainer {
    margin: 10px;
}

.v3IntegrationsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.v3IntegrationPartner {
    height: 64px;
    image-rendering: -webkit-optimize-contrast;
}

.v3ReadyToGetStartedContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.v3ReadyHeader {
    font-size: 48px;
    font-weight: 600;
}

.v3Footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.v3FooterContainer {
    max-width: 1200px;
    flex: 1;
    padding-left: 30px;
    padding-right: 30px;
}

.v3FooterBorder {
    border-bottom: 1px solid #dbdbdb;
}

.v3FooterRow {
    display: flex;
}

.v3FooterGroup {
    margin: 20px;
    margin-right: 60px;
    margin-left: 0;
}

.v3FooterGroupHeader {
    font-size: 10px;
    color: #9f9f9f;
}

.v3FooterLink {
    font-size: 16px;
    color: #232323;
    margin-top: 8px;
    margin-bottom: 8px;
}

.v3CopyrightFooter {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #9f9f9f;
    font-size: 13px;
}

.v3HeaderProductIcon {
    width: 33px;
    object-fit: contain;
}

.websiteHidden {
    display: none;
}
.v3RatingStars {
    display: flex;
    flex-direction: row;
}
.mobileWidth {
    width: 100%;
    image-rendering: -webkit-optimize-contrast;
}

@media only screen and (max-width: 850px) {
    .spacing38 {
        height: 19px;
    }
    .spacing60 {
        height: 32px;
    }
    .spacing128 {
        height: 64px;
    }
    .mobileHidden {
        display: none;
    }
    .websiteHidden {
        display: flex;
    }
    .mobileWidth {
        width: 80%;
    }
    .v3HamburgerSpace {
        height: 30px;
    }
    .v3HeaderDropdownSpace {
        display: none;
    }

    .v3HeaderLink {
        font-weight: 500;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 38px;
        font-size: 18px;
        padding-left: 38px;
        color: #4d4d4d;
        position: relative;
        padding: 28px 38px 28px 38px;
    }
    .arrow {
        border: solid #4d4d4d;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        margin-left: 10px;
        margin-bottom: 2px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    .v3HeaderDropdownHide {
        transition: height 0.6s, opacity 0.2s, visibility 0.4s ease-in-out;
        -webkit-transition: height 0.6s, opacity 0.2s,
            visibility 0.3s ease-in-out;
    }

    .v3HeaderDropdownShow {
        transition: height 0.6s, opacity 0.2s, visibility 0.4s ease-in-out;
        -webkit-transition: height 0.6s, opacity 0.2s,
            visibility 0.3s ease-in-out;
    }

    .v3HamburgerContainer {
        position: fixed;
        margin: auto;
    }
    .v3HamburgerToggle {
        top: 0;
        right: 0;
        display: fixed;
    }
    .v3HamburgerDropdown {
        display: relative;
        width: 100vw;
        height: auto;
        margin: auto;
    }
    .v3HeaderDropdownShow {
        left: 0;
        position: relative;
        visibility: visible;

        display: flex;
        flex-direction: column;
        opacity: 1;
        height: auto;

        transition: height 0.5s, opacity 0.5s, visibility 0.5s ease-in-out;
        -webkit-transition: height 0.5s, opacity 0.5s,
            visibility 0.5s ease-in-out;
    }

    .v3HamDropdownBg {
        width: 100vw;
        right: 0;
        background-color: #ffffff;
        box-shadow: 0px 7px 16px rgb(0 0 0 / 16%);
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 20px;
    }
    .v3HamDropdownContent {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
    .v3HeaderDropdown {
        position: relative;
    }
    .v3HeaderDropdownContent {
        padding: 0px;
    }
    .v3HeaderDropdownRow {
        padding-bottom: 0px;
        margin: 0;
        padding-left: 5px;
        flex-direction: column;
        max-width: 300px;
    }
    .v3HeaderProductContent {
        padding-bottom: 16px;
        padding-left: 26px;
    }
    /*for texts part*/
    .v3HeaderProductContent .v3HeaderProductSplitViewSide {
        padding-left: 26px;
        display: flex;
        flex-direction: column;
        width: 70%;
        color: #333333;
    }
    .v3HeroSplit {
        flex-direction: column;
        padding: 10px;
    }
    .v3HeroText {
        font-size: 32px;
        text-align: center;
        margin-bottom: 20px;
        line-height: 1em;
        font-weight: 900;
        letter-spacing: -0.01em;
    }
    .v3HeroDescription {
        font-size: 18px;
        text-align: center;
        font-family: 'Inter';
        width: 100%;
        margin-bottom: 20px;
    }
    .v3HeaderDropdownBg {
        box-shadow: none;
    }
    .v3LandingProductLink {
        text-align: center;
    }
    .v3RatingStars {
        display: flex;
        flex-direction: row;
    }
    .v3HeroContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 100%;
        width: auto;
        padding: 6%;
        background-image: url('/assets/landing/v3/hero-decor-mobile.svg');
        background-size: cover;
        background-position: 50% 0%;
    }
    .v3HomepageBackground {
        background: url('/assets/landing/v3/homepage-mobile-bg.png') no-repeat;
        background-size: 100%;
    }
    .v3ContentContainer {
        padding: 0;
    }
    .v3HeroSplitSide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .v3HeroTextSide {
        display: flex;
        padding-left: 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .v3CenteredHeader {
        font-size: 28px;
        margin: auto;
        align-items: center;
        margin-left: 20px;
        margin-right: 20px;
    }
    .v3BrandLogo {
        image-rendering: -webkit-optimize-contrast;
        margin: 10px;
        width: 80px;
    }
    .v3BrandsList {
        margin-top: 20px;
        margin-bottom: 20px;
        justify-content: space-evenly;
    }
    .v3CenteredDescription {
        font-size: 16px;
        font-family: 'Inter';
        max-width: 91%;
    }

    .v3ButtonContainer {
        justify-content: center;
    }

    .v3TabLabelContainer {
        overflow-x: auto;
        width: 100vw;
    }
    .v3StoreHighlightSpacing {
        height: 60px;
    }
    .v3ProductSplitView {
        flex-direction: column-reverse;
        max-width: auto;
        align-items: center;
        margin: auto;
        padding: 0px 8%;
        height: auto;
    }
    .v3ProductSplitViewSide {
        align-items: center;
        margin: auto;
        padding: 0;
    }
    .v3ProductSplitViewRight {
        padding-right: 0;
        margin: 10%;
    }
    .v3ReviewManyContainer {
        flex-direction: row;
        overflow-x: auto;
        width: 100vw;
    }

    .v3ReviewContainer {
        min-width: 60%;
        margin-left: 12%;
        margin-right: 12%;
    }
    .v3FooterRow {
        flex-direction: column;
    }
    .v3LandingProductTagline {
        color: #4d4d4d;
        margin: auto;
        text-align: center;
    }
    .v3LandingProductName {
        align-items: center;
        text-align: center;
        margin: auto;
    }
    .v3Landingv3LandingProductDescription {
        font-size: 14px;
        color: #4d4d4d;
        text-align: center;
        font-family: 'Inter';
        width: auto;
        padding-bottom: 16px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .v3TabLabel {
        white-space: nowrap;
    }
    .v3TabLabel:first-of-type {
        margin-left: 30px;
    }
    .v3TabLabel:last-of-type {
        margin-right: 30px;
    }
    .v3IntegrationsContainer {
        display: flex;
        flex-wrap: nowrap;
        justify-content: start;
        max-width: 90%;
        overflow-x: auto;
        width: 100vw;
        margin: auto;
    }

    .v3ReadyHeader {
        font-size: 30px;
        text-align: center;
    }
    .v3LandingProductImage {
        width: 100%;
        image-rendering: -webkit-optimize-contrast;
    }
}
