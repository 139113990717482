.dineSelectRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
}

.dineSelectPrimaryButton {
    min-width: 80px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9f9f9;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.12);
    border-radius: 10px;
}

.dineSelectPrimaryButton + .dineSelectPrimaryButton {
    margin-left: 10px;
}

.dineSelectPrimaryImage {
    width: 60px;
    height: 60px;
}

.dineSelectPrimaryText {
    color: #1c4055;
    margin-top: 20px;
}
