:root {
    --min-height: 100vh;
}

.v2RootNavbar {
    z-index: 999999;
    position: fixed;
    bottom: 0;
    width: 100vw;
}

.v2RootBody {
    background-color: #f5f5f7;
    min-height: var(--min-height);
    display: flex;
    flex-direction: column;
}
