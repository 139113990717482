.cssload-container {
    position: relative;
    margin: 50px;
}

.cssload-whirlpool,
.cssload-whirlpool::before,
.cssload-whirlpool::after {
    position: absolute;
    top: 50%;
    left: 50%;
    border: 1px solid rgb(204, 204, 204);
    border-left-color: rgb(0, 0, 0);
    border-radius: 749px;
    -o-border-radius: 749px;
    -ms-border-radius: 749px;
    -webkit-border-radius: 749px;
    -moz-border-radius: 749px;
}

.cssload-whirlpool {
    margin: -19px 0 0 -19px;
    height: 37px;
    width: 37px;
    animation: cssload-rotate 1900ms linear infinite;
    -o-animation: cssload-rotate 1900ms linear infinite;
    -ms-animation: cssload-rotate 1900ms linear infinite;
    -webkit-animation: cssload-rotate 1900ms linear infinite;
    -moz-animation: cssload-rotate 1900ms linear infinite;
}

.cssload-whirlpool::before {
    content: '';
    margin: -17px 0 0 -17px;
    height: 33px;
    width: 33px;
    animation: cssload-rotate 1900ms linear infinite;
    -o-animation: cssload-rotate 1900ms linear infinite;
    -ms-animation: cssload-rotate 1900ms linear infinite;
    -webkit-animation: cssload-rotate 1900ms linear infinite;
    -moz-animation: cssload-rotate 1900ms linear infinite;
}

.cssload-whirlpool::after {
    content: '';
    margin: -22px 0 0 -22px;
    height: 42px;
    width: 42px;
    animation: cssload-rotate 3800ms linear infinite;
    -o-animation: cssload-rotate 3800ms linear infinite;
    -ms-animation: cssload-rotate 3800ms linear infinite;
    -webkit-animation: cssload-rotate 3800ms linear infinite;
    -moz-animation: cssload-rotate 3800ms linear infinite;
}

@keyframes cssload-rotate {
    100% {
        transform: rotate(360deg);
    }
}

@-o-keyframes cssload-rotate {
    100% {
        -o-transform: rotate(360deg);
    }
}

@-ms-keyframes cssload-rotate {
    100% {
        -ms-transform: rotate(360deg);
    }
}

@-webkit-keyframes cssload-rotate {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes cssload-rotate {
    100% {
        -moz-transform: rotate(360deg);
    }
}
