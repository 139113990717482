.v2CampaignDetailsName {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 119.5%;
    margin-top: 48px;
}

.v2CampaignDetailText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    margin-top: 30px;
}
