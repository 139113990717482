@import url('https://fonts.googleapis.com/css?family=Poppins');
.round-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.5rem;
    flex-basis: 0;
    padding-bottom: 10px;
}

.round-img-coming-soon {
    filter: saturate(0%);
    opacity: 0.8;
}

.round-container.medium {
    max-width: 25vw;
}

.round-container.small {
    max-width: 20vw;
}

.round-label {
    font-family: Poppins;
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    margin-top: 10px;
    text-align: center;
    word-break: break-word;
    max-width: calc(100vw * 0.25);
}

.round-image {
    border-radius: 50%;
    object-fit: cover;
    background-color: white;
}


.round-comp-not-in-stock .round-image {
    opacity: 0.5;
}

.round-comp-not-in-stock .round-label {
    opacity: 0.5;
}

.out-of-stock-text {
    position: absolute;
    color: black;
    opacity: 0.7;
    font-size: 20px;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-top: -10px;
}


@media only screen and (min-width: 768px) {
    .round-label {
        font-size: 13px;
        max-width: 196px;
    }
}
