hr {
    width: 66vw;
    height: 2px;
    color: grey;
    background-color: grey;
    border-width: 0px;
}

.column-container {
    display: flex;
    flex-direction: column;
}

.row-container {
    display: flex;
    flex-direction: row;
}

.center {
    align-items: center;
    /* justify-content: center; */
}

.justify-center {
    justify-content: center;
}

.cccCard {
    flex: 3;
}

.cccRowCard {
    margin: 1vw;
    width: 25vw;
}

.payLayout {
    height: 100vh;
    width: 100vw;
    background-color: #f2f2f2;
}
.paymentText {
    font-size: 2.5rem;
    text-align: start;
    font-weight: 500;
    margin: 1rem;
    width: 66vw;
    color: '#999999';
}

#ccnumber input {
    font-family: 'Montserrat';
    background-color: transparent;
    border-width: 0px;
    padding: 0.5rem;
    font-size: 0.8rem;
}

#ccexp input {
    font-family: 'Montserrat';
    background-color: transparent;
    border-width: 0px;
    padding: 0.5rem;
    font-size: 0.8rem;
}

#cvv input {
    font-family: 'Montserrat';
    background-color: transparent;
    border-width: 0px;
    padding: 0.5rem;
    font-size: 0.8rem;
}

#googlepaybutton {
    align-self: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 66vw;
}

#applepaybutton {
    margin-top: 1rem;
    align-self: 'center';
    width: 66vw;
}
