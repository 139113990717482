.oneCampaignPage {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.oneCampaignContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85vw;
}

.chooseItemContainer {
    margin-bottom: 30px;
}

.campaignButtonContainer {
    margin-top: 50px;
    margin-bottom: 15vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.campaignInfo {
    min-width: 50vw;
    width: 100%;
    margin: 30px 0;
}

.campaignTitle {
    font-size: 25px;
}

.campaignDescription {
    font-size: 14px;
}

.campaignItem {
    min-width: calc(50vw - 15px);
    width: calc(80vw - 15px);
}

.campaignButton {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(18, 18, 18);
    color: #fff;
    padding: 17.5px 0;
    border: 0;
    font-size: 18px;
    font-weight: 400;
    margin-top: 25px;
}

.oneCampaignInteractiveComponents {
    min-width: calc(50vw - 15px);
    width: 100%;
}

.campaignButtonDisabled {
    background-color: rgb(200, 200, 200);
    color: grey;
}

.campaignItemModalContainer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10vh 10vw;
    z-index: 9999;
    background-color: white;
    overflow: auto;
}

.campaignItemModalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9998;
    background-color: rgba(0, 0, 0, 0.35);
}

.campaignModalButtons {
    display: flex;
}

.Collapsible {
    min-width: calc(50vw - 15px);
    margin-bottom: 15px;
    border: 0;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    background-color: inherit;
}

.Collapsible__trigger {
    font-size: 16px;
}

.campaignItemFlexImage {
    max-width: 70px;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    /* background: image('/assets/noimage.jpg'); */
}

.campaignItemContainer {
    width: calc(100%-30px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid #eee;
    cursor: pointer;
    min-height: 80px;
    padding: 5px 10px 5px 15px;
    margin-bottom: 5px;
}

.campaignItemHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.campaignItemName {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
}

.campaignItemDescription {
    font-size: 15px;
    font-weight: 400;
    font-family: 'Roboto';
    margin-top: 5px;
}

.campaignItemRight {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.campaignItemPrice {
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 400;
    margin-top: 8px;
}

.chooseItemMessage {
    font-weight: 600;
    margin-bottom: 10px;
}

.itemSelectionBlock {
    widows: calc(100%-30px);
    display: flex;
    justify-content: space-between;
    padding: 20px 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    border: 2px solid grey;
}

.itemSelectionBlock:hover {
    cursor: pointer;
}

.itemSelectionTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.itemSelectionIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.itemSelectionIcon {
    height: 20px;
}

.campaignItemGroupModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    padding: 7.5vh 7.5vw;
    z-index: 999;
    background-color: white;
    overflow: auto;
}

.campaignModalCloseButton {
    position: absolute;
    top: 2vh;
    right: 6vw;
    font-size: 30px;
}

.campaignModalGoBackButton {
    position: absolute;
    top: 3vh;
    left: 6vw;
    font-size: 10px;
    height: 10px;
}

.campaignModalDescription {
    margin-bottom: 30px;
}

@media only screen and (min-width: 700px) {
    .oneCampaignContainer {
        width: 70%;
        max-width: 700px;
    }

    .Collapsible {
        margin-bottom: 30px;
        background-color: inherit;
    }

    .campaignButton {
        margin-bottom: 15vh;
    }
}
