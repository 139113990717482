/* Closed.js */

.closedContainer {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.closedText {
    text-align: center;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 26px;
    padding: 20px;
}

.closedLogo {
    width: 70%;
}

/* Override Banner Colors */

/* .Toastify__toast-container {
    z-index: 999;
} */

@media only screen and (max-width: 700px) {
    .Toastify__toast-container--top-right {
        width: calc(100% - 40px) !important;
        margin: 20px !important;
        border-radius: 10px !important;
    }
}

.toastCheckmark {
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    margin: 5px;
    margin-right: 15px;
}

.toastSuccessContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Toastify__toast-body {
    padding: 10px;
    text-align: left;
    font-family: 'Roboto';
    font-weight: 400;
}

.Toastify__toast--default {
    background: #fff;
    color: #aaa;
}

.Toastify__toast--info {
    background: #3498db;
}

.Toastify__toast--success {
    backdrop-filter: blur(10px) saturate(250%);
    background: rgba(66, 143, 40, 0.9) !important;
}

.Toastify__toast--warning {
    padding: 5px;
    background: #f1c40f !important;
}

.Toastify__toast--error {
    padding: 5px;
    background: #8d071d !important;
}

/* Override Phone Input */

.PhoneInput {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;
}

.PhoneInputInput {
    /* The phone number input stretches to fill all empty space */
    flex: 1;
    /* The phone number input should shrink
	   to make room for the extension input */
    min-width: 0;
    padding: 3px;
    font-size: 20px;
    height: 30px;
    text-align: center;
    border: none;
    background-color: inherit;
}

/* Pay.js */

.youPayContainer {
    padding: 20px;
}

.youPayLabel {
    font-style: normal;
    font-size: 24px;
    line-height: 44px;
    /* identical to box height */
    color: #2b2b2b;
    letter-spacing: -0.05em;
}

.youPayPrice {
    font-style: normal;
    font-size: 48px;
    line-height: 58px;
    letter-spacing: -0.05em;
    color: #444;
    /* identical to box height */
}

.applePayContainer {
}

.applePayLabel {
    padding-bottom: 10px;
}

.payAtCounterContainer {
    position: relative;
    bottom: env(safe-area-inset-bottom);
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}

.payAtCounterText {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #252525;
    margin-bottom: 18px;
    max-width: 200px;
}

.payAtCounterButton {
    background: #121212;
    border-radius: 4px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    color: #fff;
    width: calc(100% - 40px);
    margin-bottom: 20px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s ease;
}

.payAtCounterButton:hover {
    opacity: 0.8;
}

/* Override toast */

.Toastify__toast--success {
    padding: 5px;
}

.Toastify__toast--warning {
    padding: 5px;
}

/* AddedToOrder.js */

.addedToOrderDismissPrompt {
    position: absolute;
    bottom: 50px;
    margin-top: 20px;
    color: #999;
}

/* PaymentComplete*/

.startNewOrderButton {
    background-color: #3bad4d;
    color: #fff;
    width: 100%;
    height: 100px;
    font-size: 30px;
    font-weight: 600;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    bottom: 0;
    left: 0;
    cursor: pointer;
}

.textReceiptSubmit {
    text-align: center;
    width: 100%;
    background: #d03838;
    border-radius: 4px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    color: #ffffff;
    cursor: pointer;
}

.textReceiptSubmitDisabled {
    background: #cbcbcb;
    color: #000;
    cursor: default;
}

.paymentCompleteCodeBold {
    font-weight: 700;
    font-size: 36px;
    color: #333;
    font-family: 'Roboto Mono';
    font-style: normal;
    font-weight: normal;
    font-size: 64px;
    line-height: 80px;
    /* identical to box height */
    color: #3c3c3c;
}

.paymentCompleteThankYou {
    font-style: normal;
    font-size: 36px;
    line-height: 44px;
    /* identical to box height */
    color: #222;
    margin-bottom: 40px;
}

.phoneNumberNewFeature {
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    background-color: rgb(47, 153, 47);
    color: #fff;
    margin-right: 5px;
}

.paymentCompleteContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-bottom: 120px;
}

.hiltonCompleteMsg {
    margin-bottom: 40px;
    font-size: 18px;
}

.hilton-complete-msg-bold {
    font-weight: 800;
}

.paymentCompleteCheckmark {
    width: 60px;
}

.paymentCompleteText {
    color: #758688;
}

.paymentCompleteOrderReadyTime {
    padding: 20px;
    margin-bottom: 30px;
    background-color: #eee;
    font-weight: 600;
    text-align: center;
}

.paymentCompleteDescription {
    color: #758688;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #3f3f3f;
}

.phoneNumberPrompt {
    text-align: center;
    color: #333;
    font-size: 16px;
    padding: 30px 50px 10px 50px;
}

.textReceiptContainer {
    background-color: #f3f3f3;
    /* position: fixed; */
    /* bottom: env(safe-area-inset-bottom); */
    padding: 20px;
    width: calc(100% - 40px);
}

.textReceiptNewLabel {
    display: inline-block;
    background-color: #d03838;
    padding: 2px;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    color: #ffffff;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

.textReceiptTitle {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: #1b1b1b;
    margin-top: 7px;
    margin-bottom: 7px;
}

.textReceiptDescription {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #1b1b1b;
}

/* PaymentMethods.js */

.paymentContainer {
    margin: 20px;
}

.paymentInstructionsLabel {
    margin: 20px;
    font-family: 'Lato';
    font-size: 18px;
    font-weight: 600;
    color: #747474;
}

.creditCardContainer {
}

.paymentSubmitButton {
    border-radius: 5px;
    margin-top: 10px;
    background: #121212;
    font-family: Roboto;
    color: #ffffff;
    border: 0;
    padding: 12px 12px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    width: 100%;
}

.paymentSubmitButtonGreen {
    font-family: Poppins;
    background-color: #20b04b;
}
.paymentSubmitButtonDisabled {
    background-color: #f0f0f0;
    color: #999;
}
.paymentSubmitButtonAddMore {
    background-color: #f0f0f0;
    color: #333;
}

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

/* complete.js */

.completeContainer {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.completeLogo {
    width: 40%;
    margin-bottom: 30px;
}

.completeThankYouContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 30px;
    padding-right: 30px;
}

.completeThankYouText {
    text-align: center;
    font-family: 'Lato';
    color: #758688;
    font-style: italic;
    font-size: 18px;
    margin: 5px;
}

/*finalizeorderbutton.js*/

.editTakeoutButtonContainer {
    width: 100%;
}
.renderCashDiscountMessage {
    text-align: center;
    position: fixed;
    bottom: 60px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    background-color: rgb(44, 179, 150);
    color: #fff;
}

.editTakoutLabel {
    margin-left: 20px;
    font-weight: 600;
}

.editTakeoutTimeButton {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    border: 1px solid #ddd;
    padding: 20px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #fff;
    color: #000;
    font-weight: 500;
    cursor: pointer;
    text-align: center;
    transition: 0.3s ease;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    border: 1px solid #aaa;
}
.editTakeoutTimeButton:hover {
    background-color: #eee;
}
.editTakeoutTimeButton:active {
    background-color: #ddd;
}

.finalizeOrderShoppingIcon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.finalizeItemLabel {
    font-weight: 400;
    color: #424242;
}

.finalizeNuveiWarning {
    font-weight: 700;
    color: #424242;
}

.floatingNotificationContainer {
    background-color: '#000';
    position: fixed;
    bottom: 60px;
    display: flex;
    width: 100%;
    z-index: 999;
}

.floatingNotification {
    display: block;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 10px;
}

.floatButtonRightArrow {
    height: 8px;
    margin-left: 15px;
}

.floatButtonPlus {
    filter: brightness(1000%);
    height: 16px;
}

.floatingButtonItemCount {
    color: #fff;
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 600;
    width: 100px;
    text-align: right;
}

.floatingButtonRight {
    margin-right: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
}

/*finalizeorder.js*/

.finalizeNoOrdersContainer {
    width: 100%;
}

.finalizeNoOrdersPrompt {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    margin-right: 30px;
    font-weight: 500;
    font-size: 24px;
    color: #393939;
}

.finalizeAddItemsButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    border: 2px solid #555;
    color: #555;
    font-weight: 700;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    height: 50px;
    align-items: center;
    transition: 0.3s ease;
}

.finalizeAddItemsButton:hover {
    background-color: #eee;
}

.tipContainer {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
}

.tipTitle {
    color: #758688;
    font-weight: 600;
}

.tipButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    margin-top: 15px;
    flex-wrap: wrap;
}

.tipButton {
    display: block;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border: 1px solid #758688;
    color: #758688;
    margin-right: 10px;
    margin-bottom: 10px;
    transition: 0.5s ease;
    cursor: pointer;
    border-radius: 5px;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tipButton:hover {
    /* background-color: #ddd; */
}

.tipButtonActive {
    display: block;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #758688;
    border: 1px solid rgba(0, 0, 0, 0);
    color: #fff;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.orderTotalDivider {
    display: flex;
    background-color: #eee;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.orderTotalRow {
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
    justify-content: space-between;
    font-family: 'Roboto';
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    align-items: center;
}

.orderTotalLabel {
    font-weight: 400;
    color: #333;
    display: flex;
    align-items: center;
}

.orderTotalPrice {
    font-weight: 400;
    color: #333;
}

.youPay {
    font-weight: 700;
}

.rewardItemPrompt {
    /* width: 80%; */
    width: 90%;
    min-height: 30px;
    padding: 15px 17.5px;
    font-size: 15px;
    border-radius: 7px;
    background-color: #469f5e;
    margin-bottom: 7.5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.rewardItemPrompt:hover {
    cursor: pointer;
}

.rewardItemPromptContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    color: white;
}

.rewardItemReminderModal {
    position: absolute;
    top: 37.5%;
    left: 15%;
    right: 15%;
    bottom: 37.5%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: white;
    z-index: 999;
    overflow: auto;
    border: none;
    border-radius: 8px;
}

.rewardItemReminderOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
}

.rewardReminderMessage {
    padding: 0 30px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: 'Cormorant Garamond';
    font-weight: 500;
}

.rewardReminderButtonContainer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
}

.rewardReminderButton {
    font-family: 'Cormorant Garamond';
    background-color: transparent;
    height: 100%;
    font-size: 20px;
    font-weight: 500;
    border: none;
}

.rewardReminderButton:hover {
    cursor: pointer;
}

.rewardReminderButton--proceed {
    color: green;
}

.rewardReminderButton--cancel {
    color: red;
}

/* OrderComplete.js */

.checkCircleOutline {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding: 0rem 1rem 2rem 1rem;
}

/* Tablet UI */

@media only screen and (min-width: 700px) {
    .orderTotalLabel {
        font-size: 20px;
    }
    .orderTotalPrice {
        font-size: 20px;
    }
    .textReceiptContainer {
        width: calc(50%);
    }
    .textReceiptAndPaymentComplete {
        display: flex;
        justify-content: space-between;
    }
}

.bold {
    font-weight: 500;
}

.finalizeOrderContainer {
    padding-left: 20px;
    padding-right: 20px;
}

.finalizeOrderTitle {
    font-size: 24px;
    font-family: 'Roboto';
    font-weight: 600;
    color: #333;
    margin-top: 30px;
    margin-bottom: 30px;
}

.finalizeItemContainer {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    width: 100%;
}

.finalizeItemRow {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.finalizeItemModifier {
    font-family: 'Roboto';
    margin-left: 25px;
    font-size: 15px;
    font-weight: 400;

    margin-top: 5px;
    margin-bottom: 5px;
}

.finalizeItemOptions {
    display: flex;
    margin-top: 10px;
    flex-direction: row;
    justify-content: space-between;
}

.finalizeItemOptions-delete {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease;
    font-size: 12px;
    font-weight: 500;
    color: #555;
}
.finalizeItemOptions-delete:hover {
    background-color: #777;
    color: #fff;
}
.finalizeItemOptions-quantity {
    display: flex;
    margin-left: 20px;
    align-items: center;
    font-size: 12px;
}
.finalizeItemOptions-quantity-minus {
    margin-left: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease;
}
.finalizeItemOptions-quantity-minus:hover {
    background-color: #777;
    color: #fff;
}
.finalizeItemOptions-quantity-value {
    padding: 10px;
}
.finalizeItemOptions-quantity-plus {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease;
}
.finalizeItemOptions-quantity-plus:hover {
    background-color: #777;
    color: #fff;
}
.finalizeOrderMessage {
    padding: 20px;
    font-style: italic;
    color: #555;
    text-align: center;
}

.finalizeOrderPaySummaryContainer {
    width: 100%;
}

.finalizeOrderLoading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.55);
}

.finalizeItemDelete {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-left: 10px;
    border: 1px solid #fff;
    cursor: pointer;
}

.finalizeItemDelete:hover {
    border: 1px solid #333;
}

.finalizeItemDeleteConfirm {
    width: 65px;
    height: 30px;
    margin-right: 10px;
}

.finalizeItemPrice {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    color: #424242;
    margin-left: 10px;
}

/* ItemDetail.js */

.itemPhoto {
    max-height: 300px;
    object-fit: cover;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

@media only screen and (min-width: 700px) {
    .itemPhoto {
        background-size: contain;
        max-height: 300px;
    }
}

.itemMultiplierContainer {
    touch-action: manipulation;
    position: fixed;
    backdrop-filter: blur(10px) saturate(250%);
    background-color: rgba(255, 255, 255, 0.7);
    bottom: 60px;
    margin-bottom: env(safe-area-inset-bottom);
    width: 100vw;
    display: flex;
    justify-content: space-between;
    border: 2px solid #758688;
    align-items: center;
}

.itemMultiplerMinus {
    font-family: 'Roboto';
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 28px;
    border-right: 2px solid #758688;
    color: #758688;
    transition: 0.2s;
    cursor: pointer;
    transition: 0.3s ease;
}

.itemMultiplerMinus:hover {
    background-color: #eee;
}

/* .itemMultiplerMinus:hover, .itemMultiplerMinus:active {
    background-color: #ddd;
} */

.itemMultiplierAmount {
    color: #758688;
    font-size: 22px;
    font-weight: 700;
}

.itemMultiplierPlus {
    font-family: 'Roboto';
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 2px solid #758688;
    color: #758688;
    font-weight: 700;
    font-size: 24px;
    cursor: pointer;
    transition: 0.3s ease;
}

.itemMultiplierPlus:hover {
    background-color: #eee;
}

/* .itemMultiplierPlus:hover, .itemMultiplierPlus:active {
    background-color: #ddd;
    color: #fff;
} */

.floatingButton {
    position: fixed;
    bottom: env(safe-area-inset-bottom);
    width: 100vw;
    height: 60px;
    background-color: #758688;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    cursor: pointer;
}

.floatingButtonHalfRight {
    width: 60%;
    left: 40%;
    justify-content: flex-end;
}
.floatButtonHalfLeft {
    width: 40%;
    left: 0;
    background-color: #555;
    box-sizing: border-box;
    /* border-right: 1px solid #ccc; */
}

.floatingButtonBag {
    width: 25px;
    height: 25px;
    margin-right: 10px;
}

.floatingButtonSafeArea {
    position: fixed;
    height: env(safe-area-inset-bottom);
    width: 100vw;
    bottom: 0;
    backdrop-filter: blur(3px) saturate(250%);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.floatingButtonLabel {
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin-left: 20px;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.floatingButtonAlt {
    position: fixed;
    bottom: 60px;
    width: 100%;
    height: 60px;
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.floatingButtonAltLow {
    bottom: 0px;
}

.floatingButtonLabelAlt {
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 600;
    color: #333;
    padding-left: 20px;
}

.center {
    text-align: center;
}

.itemModifierGroupContainer {
    margin: 20px;
    margin-top: 0;
}

.itemModifierGroupName {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.itemModifierRadioRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s ease;
}

.itemModifierRadioSelected {
    background-color: rgb(48, 48, 48);
    color: #fff;
    border-color: rgb(48, 48, 48);
}

.itemModifierRequiredAccent {
    color: red;
}

.itemModifierRadio {
}

.itemModifierRadio:active {
}

radio {
    cursor: pointer;
}

.itemModifierLabel {
    margin-left: 10px;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
}

.itemModifierDescriptionLabel {
    font-size: 15px;
    font-weight: 400;
    margin-top: 5px;
}

.itemDetailsContainer {
    margin-top: 30px;
    padding-left: 20px;
    margin-right: 30px;
}

.headerRow {
    /* display: flex; */
    justify-content: space-between;
    align-items: center;
}

.itemDetailName {
    display: block;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 22px;
    padding-right: 10px;
}

.itemDetailPrice {
    font-family: 'Roboto';
    font-size: 17px;
    font-weight: 400;
}

.itemDetailDescription {
    margin-top: 10px;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
}

.itemSpecialInstructionsContainer {
    margin: 20px;
}

.itemSpecialInstructionsLabel {
}

.itemSpecialInstructionsTextField {
    font-size: 18px;
    font-family: 'Roboto';
    padding: 10px;
    width: calc(100% - 20px);
    margin-top: 10px;
    border-radius: 5px;
    border: 0;
    background-color: #f7f7f7;
    border-bottom: 5px solid #dadada;
    transition: 0.3s ease;
}

.itemSpecialInstructionsTextField:hover {
    border-bottom: 5px solid #999999;
}

.itemSpecialInstructionsTextField:focus {
    border-bottom: 5px solid #999999;
}

.purchaseAndGetCampaignItems {
    font-family: 'Roboto';
    margin-left: 25px;
    font-size: 15px;
    font-weight: 400;

    margin-top: 5px;
    margin-bottom: 5px;
}

/* - Change Quantity */

.changeQuantityContainer {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    margin-right: 20px;
    border: 1px black solid;
    align-items: center;
    justify-content: space-between;
}

.cqLeft {
    padding-left: 15px;
    font-family: 'Lato';
    font-weight: 600;
}

.cqRight {
    display: flex;
    flex-direction: row;
}

.cqMinusContainer {
    width: 40px;
    height: 40px;
    border-left: 1px solid black;
    display: flex;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 30px;
    justify-content: center;
    align-items: center;
}

.cqPlusContainer {
    width: 40px;
    height: 40px;
    border-left: 1px solid black;
    justify-content: center;
    align-items: center;
}

.cqLabel {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 28px;
}

/* OneMenu.js */

@keyframes animateBlurIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.menuHoursContainer {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 99999;

    backdrop-filter: blur(15px) saturate(150%);
    animation: 0.3s ease-in animateBlurIn;
}

.menuHours {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.5);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #444;
    padding: 20px;
    margin: 20px;
    margin-top: 60px;
    margin-bottom: 60px;
    overflow-y: auto;
}

.menuHoursHours {
    width: 100%;
}

.menuHourInterval {
    margin-left: 20px;
}

.menuHoursTitle {
    width: 100%;
    font-size: 26px;
    font-weight: 600;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 20px;
}

.menuHourRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 250px;
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
}
.menuHourRow + .menuHourRow {
    border-top: 1px solid #eee;
}
.menuHourRow:last-of-type {
    margin-bottom: 20px;
}
.menuHourRow-bold {
    background-color: #e2e2e2;
    /* border-radius: 50px; */
}
.menuHourRow-bold:first {
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}
.menuHourRow-bold:last-of-type {
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

.menuHoursFloatRight {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Category.js */

.categoryHeader {
    padding-top: 15px;
    padding-bottom: 35px;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 36px;
    color: #758688;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.categoryHeaderDescriptionContainer {
    font-weight: 700;
    color: #555;
    margin: 10px 0;
    font-size: 18px;
}

.categoryHeaderMenuHours {
    display: inline-block;
    border: 1px solid #fff;
    color: #fff;
    background-color: #0071e3;
    font-size: 14px;
    font-family: 'Roboto';
    font-weight: 500;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 10px;
    border-radius: 100px;
    cursor: pointer;
    margin: 5px;
}

.categoryHeaderMenuHoursContainer {
    display: flex;
}

.categoryHeaderMenuHours-light {
    background-color: #eee;
    color: #000;
}

.categoryLabel {
    font-family: 'Lato';
    font-weight: 700;
    color: #212529;
}

.items {
    padding-bottom: 80px;
}

.itemFlexContainer {
    display: flex;
    width: 100%;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.itemFlexImage {
    max-width: 75px;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    /* background: image('/assets/noimage.jpg'); */
}

.itemDetailsColumn {
    padding: 20px;
    padding-top: 13px;
    padding-bottom: 13px;
}

.itemContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.itemFlexContainer + .itemFlexContainer {
    border-top: 0;
}

.itemHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.itemName {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
}

.itemDescription {
    font-size: 15px;
    font-weight: 400;
    font-family: 'Roboto';
    margin-top: 5px;
}

.itemRight {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.itemPrice {
    font-family: 'Roboto';
    font-size: 15px;
    font-weight: 400;
    margin-top: 8px;
}

/* RestaurantMenus.js */

.menuTitle {
    padding-left: 20px;
    margin-right: 30px;
    font-family: 'Roboto';
    font-size: 24px;
    color: #758688;
    margin-top: 50px;
    margin-bottom: 30px;
}

.menuButton {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 13px;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.menuButton + .menuButton {
    border-top: 0;
}

.menuButtonHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.menuButtonLabel {
    font-family: 'Roboto';
    color: #000;
    font-size: 16px;
    font-weight: 400;
}

.menuButtonRightArrow {
    height: 7px;
}

.category {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    color: #758688;
}

.categoryDescription {
    font-weight: 400;
    font-family: 'Roboto';
    color: #555;
    margin-top: 10px;
    margin-bottom: 10px;
}

.itemMultiplier {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.paymentContainerDesktop {
    width: 100%;
}

.itemDetailMobileBottomPaddingFix {
    padding-bottom: 140px;
}

@media only screen and (min-width: 700px) {
    .itemDetailMobileBottomPaddingFix {
        padding: 0;
    }
    .categoryHeader {
        text-align: center;
    }
    .itemFlexImage {
        border-radius: 5px;
    }
    .menuButton {
        max-width: 700px;
        width: 700px;
        padding: 20px;
        border: 1px solid #ccc;
        border-radius: 10px 10px 0px 0px;
        transition: 0.3s ease;
    }
    .menuButton:hover {
        background-color: #eee;
    }
    .menuButton + .menuButton {
        /* border: 1px solid #ccc; */
        border-radius: 0px 0px 0px 0px;
    }
    .menuButton:last-of-type {
        border-radius: 0px 0px 10px 10px;
    }
    .menuButton:last-of-type.menuButton:first-of-type {
        border-radius: 10px 10px 10px 10px;
    }
    .menuButtonContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .itemFlexContainer {
        max-width: 700px;
        width: 700px;
        padding: 5px;
        border: 1px solid #ccc;
        border-radius: 10px 10px 0px 0px;
        transition: 0.3s ease;
    }
    .itemFlexContainer:hover {
        background-color: #eee;
    }
    .itemFlexContainer + .itemFlexContainer {
        border-radius: 0;
    }
    .itemFlexContainer:last-of-type {
        border-radius: 0px 0px 10px 10px;
    }
    .itemFlexContainer:last-of-type.itemFlexContainer:first-of-type {
        border-radius: 10px 10px 10px 10px;
    }
    .itemPhoto {
        width: 50vw;
        min-height: calc(100vh - 120px);
        max-width: 600px;
        background-size: cover;
        background-position: 50% 50%;
    }
    .itemDetailOverflow {
        margin-left: 10px;
        padding-bottom: 50px;
        height: calc(100vh - 140px);
        overflow: auto;
    }
    .itemDetailsDesktopContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .itemDetailsContainer {
        max-width: 300px;
        width: 300px;
    }
    .itemModifierGroupContainer {
        width: 300px;
        max-width: 300px;
    }
    .itemMultiplierContainer {
        max-width: 300px;
        position: inherit;
        margin-top: 20px;
        margin-bottom: 40px;
    }
    .finalizeOrderBackground {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 150px;
    }
    .finalizeOrderContainer {
        min-width: 700px;
        width: 700px;
        padding: 0;
        margin: 0;
    }
    .finalizeNoOrdersContainer {
        min-width: 700px;
        width: 700px;
    }
    .paymentContainerDesktop {
        min-width: 700px;
        max-width: 700px;
    }
    .payAtCounterContainer {
        max-width: 700px;
        min-width: 700px;
    }
    .itemMultiplierContainer {
        border-radius: 10px;
    }
    .itemMultiplerMinus {
        border-radius: 10px 0px 0px 10px;
    }
    .itemMultiplierPlus {
        border-radius: 0px 10px 10px 0px;
    }

    .editTakeoutTimeButton {
        width: calc(700px - 60px);
        margin: 0;
        margin-top: 20px;
    }
    .editTakoutLabel {
        margin: 0;
        color: #555;
    }

    .editTakeoutButtonContainer {
        min-width: 700px;
        max-width: 700px;
    }

    .tipContainer {
        min-width: 700px;
        width: 700px;
    }
    .finalizeOrderPaySummaryContainer {
        min-width: 700px;
        width: 700px;
        width: auto;
    }

    .headerRow {
        padding-top: 60px;
    }

    .rewardReminderMessage {
        padding: 0 40px;
        font-size: 25px;
    }

    .rewardReminderButtonContainer {
        width: 80%;
    }

    .rewardReminderButton {
        font-family: 'Cormorant Garamond';
        background-color: transparent;
        height: 100%;
        font-size: 30px;
        font-weight: 600;
        border: none;
    }

    .rewardItemReminderModal {
        position: absolute;
        top: 37.5%;
        left: 22.5%;
        right: 22.5%;
        bottom: 37.5%;
    }
}
