.pdSectionContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.pdHeroContainer {
    width: calc(100vw - 60px);
    background-image: url('/assets/landing/v3/hero-decor.svg');
    background-size: cover;
    font-family: 'Circular Std';
    background-position: 0% 0%;
    padding-left: 30px;
    padding-right: 30px;
}

.pdHeroSplit {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1200px;
    padding: 20px;
    margin: auto;
}

.pdHeroSplitSide {
    flex: 1;
    margin-left: auto;
    margin-right: auto;
    padding-left: 8%;
}
.pdHeroTagline {
    font-size: 16px;
    font-weight: 500;
    color: #4d4d4d;
}

.pdHeroText {
    font-size: 38px;
    font-weight: 700;
    color: #232323;
    line-height: 1em;
    margin-top: 10px;
    margin-bottom: 20px;
}

.pdHeroDescr {
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    color: #525252;
    padding-bottom: 25px;
}
.pdHeroImg {
    margin-left: auto;
    margin-right: auto;
    image-rendering: -webkit-optimize-contrast;
}

.ProductSplitView {
    display: flex;
    max-width: 1040px;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: auto;
}
/* reverse when is  mobile mode */
.ProductSplitViewRWM {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1040px;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.ProductSplitViewSide {
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 50%;
    margin: auto;
}

.ProductSplitViewRight {
    display: flex;
    justify-content: center;
    width: auto;
    margin: auto;
}

.ProductImage {
    max-width: 450px;
    max-height: 750px;
    object-position: 50% 50%;
    margin: auto;
    image-rendering: -webkit-optimize-contrast;
}

.v3LandingProductName {
    font-size: 28px;
    font-weight: 600;
    color: #232323;
}

.v3LandingProductDescription {
    font-family: Inter;
    font-size: 16px;
    color: #333333;
    width: 84%;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 500;
}

.ProductLink {
    color: #4051cb;
    font-size: 16px;
    margin-top: 10px;
    text-align: left;
}

.ProductChevron {
    width: 7px;
}

.CheckoutOthersContainer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 24px;
}

.CheckoutContent {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: auto;
}

.CheckoutImg {
    align-items: center;
    image-rendering: -webkit-optimize-contrast;
    width: 388px;
}

.CheckoutTag {
    padding-top: 8px;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
}
.CheckoutDescrp {
    font-size: 16px;
    color: #4d4d4d;
    text-align: left;
    margin: auto;
    max-width: 390px;
    margin-top: 10px;
}

.ProductBackground {
    background: url('/assets/landing/v3/productPages/pd-bg.svg') no-repeat;
    background-size: cover;
    background-position: 0% 0%;
    background-size: 100%;
}

.ReadySectionBackground {
    background: url('/assets/landing/v3/productPages/ready-section-bg.svg')
        no-repeat;
    background-size: cover;
    background-position: 0% 0%;
    background-size: 100%;
}

@media only screen and (max-width: 850px) {
    .pdSectionContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .pdHeroContainer {
        width: calc(100% - 20px);
        padding: 10px;
        background-image: url('/assets/landing/v3/hero-decor-mobile.svg');
        background-position: 50% 0%;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .pdHeroSplit {
        display: flex;
        flex-direction: column;
        padding: 0px 0px 32px;
        align-items: center;
    }

    .pdHeroSplitSide {
        display: contents;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0px;
    }
    .pdHeroTagline {
        font-weight: 400;
        font-size: 16px;
        text-align: center;
    }

    .pdHeroText {
        font-size: 28px;
        text-align: center;
        color: #232323;
        width: 80%;
        padding-bottom: 20px;
    }

    .pdHeroDescr {
        font-family: Inter;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        width: 84%;
        color: #4d4d4d;
        padding-bottom: 28px;
    }

    .ProductSplitView {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 600px;
        margin: auto;
    }
    .ProductSplitViewRWM {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        margin: auto;
    }

    .ProductSplitViewSide {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        max-width: 100%;
        margin: auto;
    }

    .ProductSplitViewRight {
        justify-content: center;
        align-items: center;
        margin-right: auto;
        margin-left: auto;
        padding: 20px;
        margin: auto;
    }

    .ProductImage {
        width: auto;
        align-items: center;
        max-width: 100%;
    }

    .v3LandingProductName {
        text-align: center;
        font-size: 28px;
        font-weight: 600;
        max-width: 90%;
        padding-bottom: 12px;
    }

    .v3LandingProductDescription {
        font-family: Inter;
        color: #4d4d4d;
        text-align: center;
        font-size: 14px;
        max-width: 85%;
        align-items: center;
        padding-bottom: 12px;
    }

    .ProductLink {
        color: #4051cb;
        font-weight: 600;
        font-size: 16px;
        margin-top: 10px;
        text-align: center;
        padding-bottom: 20px;
    }

    .ProductChevron {
        width: 7px;
    }

    .CheckoutOthersContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }

    .CheckoutContent {
        width: 75%;
    }

    .CheckoutImg {
        align-items: center;
        width: 100%;
    }

    .CheckoutTag {
        padding-top: 8px;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
    }
    .CheckoutDescrp {
        font-family: Inter;
        font-size: 16px;
        color: #4d4d4d;
        align-items: center;
        text-align: center;
    }

    .ProductBackground {
        background: url('/assets/landing/v3/pd-mobile-bg.png') no-repeat;
        background-size: 100%;
    }

    .ReadySectionBackground {
        background: url('/assets/landing/v3/productPages/ready-section-bg.svg')
            no-repeat;
        background-size: 100%;
    }

    .pdHeroImg {
        margin-left: auto;
        margin-right: auto;
        width: 70%;
    }
}
