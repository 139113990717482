.MuiSelect-icon {
    color: #fff !important;
}

.box1 {
    position: relative;
    box-sizing: border-box;
}

.box2 {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
}

.box3 {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 2px 8px;
    -webkit-overflow-scrolling: touch;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}

.dropoffInput {
    border: 0;
    width: 100%;
    font-size: 16px;
}
.inputSubmit {
    text-transform: none;
    background-color: #20b04b;
    color: white;
    font-weight: 700;
    padding: 10px 20px 10px 20px;
    border-radius: 10000px;
    border: 0;
    font-size: 16px;
}

.whiteBackground {
    background-color: white;
}

.modalTop {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem 0rem 1rem 0rem;
}

.modalContent {
    padding: 10px;
    background-color: #fff;
}

textarea:focus,
input:focus {
    outline: none;
}

.dineInCartButtons {
    margin: 10px;
    width: calc(100% - 20px);
    position: fixed;
    bottom: 20px;
    left: 0;
    background-color: #fff;
    border-top: 1px solid #e6e6e6;
}
.languagePickerContainer {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    padding-right: 1rem;
}

.languagePickerContainer .MuiSelect-select.MuiSelect-select {
    padding-right: 30px !important;
}

.guestName {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.guestName input {
    /* width: 100%; */
    border: none;
    font-size: 16px;
}

.guestName label {
    flex-basis: 50%;
}

.radioButtons {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
}

.phoneInput {
    padding: 0px 0px 0px 10px;
    margin: 0px;
    border-color: rgb(204, 204, 204);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    --PhoneInputInput-text-align: left;
}

@media only screen and (max-width: 768px) {
    .guestName {
        flex-direction: column;
        gap: 0;
    }
    .deliveryForm label {
        margin: 0;
        padding: 0;
    }
}
