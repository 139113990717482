.dineInExistingOrderHeader {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    /* identical to box height */
    letter-spacing: 0.03em;
    color: #000000;
    padding-left: 20px;
    padding-top: 20px;
}
.dineInExistingOrderProgressBarContainer {
    display: flex;
    justify-content: center;
    padding: 20px;
    padding-top: 0;
}
