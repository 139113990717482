.mini-cart {
    position: sticky;
    bottom: 29px;
    background-color: #20b04b;
    color: #fff;
    cursor: pointer;
    z-index: 999;
    margin-top: 56px;
}

.mini-cart-inner-container {
    padding: 13px 25px 10px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.mini-cart-bottom-spacing {
    height: calc(env(safe-area-inset-bottom));
    position: sticky;
    bottom: 0px;
    width: 100vw;
    left: 0;
    background-color: #fff;
    z-index: 9998;
}

.MuiSelect-icon {
    color: #fff !important;
}

.mini-cart-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: calc(100vw - 2rem - 60px - 1rem);
}

.mini-cart-icon-wrapper {
    position: absolute;
    right: 25px;
    width: 35px;
    height: 35px;
}



.mini-cart-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@keyframes cartTutorialFadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.mini-cart-fade {
    height: 200px;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.5) 100%
    );
    position: fixed;
    width: 100vw;
    bottom: 60px;
    pointer-events: none;
}

.mini-cart-add-to-order-notif {
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.48) 100%
    );
    z-index: 9999999;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    animation: 1s ease-in cartTutorialFadeIn;
}

.mini-cart-ready-to-pay {
    left: 0;
    justify-content: flex-start;
}

.mini-cart-ato-prompt {
    margin-right: 10px;
    margin-bottom: calc(30px + env(safe-area-inset-bottom));
    height: 75px;
    cursor: pointer;
    animation: 1s ease-in cartTutorialFadeIn;
}

.mini-cart-rtp-prompt {
    margin: 20px;
    margin-bottom: calc(65px + env(safe-area-inset-bottom));
    height: 96px;
    cursor: pointer;
    animation: 1s ease-in cartTutorialFadeIn;
}
