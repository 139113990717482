.pageTitle {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    color: #758688;

    padding-left: 20px;
    margin-right: 30px;
    margin-top: 25px;
    margin-bottom: 25px;
}

.pageDescription {
    font-family: 'Lato';

    padding-left: 20px;
    margin-right: 30px;

    margin-top: 40px;
    margin-bottom: 40px;

    font-size: 16px;
    color: #758688;
    font-style: italic;
}
