@keyframes healthFormFadeIn {
    0% {
        opacity: 0;
        backdrop-filter: saturate(100%) blur(0px);
    }
    100% {
        opacity: 1;
        backdrop-filter: saturate(150%) blur(15px);
    }
}
.healthAndSafetyBackground {
    position: fixed;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    z-index: 9998;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    backdrop-filter: saturate(150%) blur(15px);
    background-color: rgba(0, 0, 0, 0.4);

    animation: 1s ease healthFormFadeIn;
}
.healthAndSafetyContainer {
    position: fixed;
    z-index: 9999;
    max-width: 600px;

    background-color: #fff;
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    margin-top: 25px;
    width: calc(100% - 60px);
    /* height: calc(100% - 100px); */
    border-radius: 20px;
    padding-bottom: 50px;
}

.healthAndSafetyHeader {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.has-left {
    display: flex;
    align-items: center;
}
.has-heart {
    width: 23px;
}
.has-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 106.69%;
    /* or 19px */
    color: #d7443e;
    margin-left: 5px;
}
.has-header-button {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 106.69%;

    display: inline-block;
    width: 50px;
    /* identical to box height, or 16px */

    text-align: center;

    color: #0085ff;
    cursor: pointer;
}

.has-description {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 125.19%;
    /* or 18px */

    text-align: center;
    letter-spacing: 0.04em;

    color: #000000;

    padding: 20px;
}

.has-description-long {
    margin-top: 20px;
    white-space: pre-wrap;
    text-align: left;
}

.scrollFormContainer {
    overflow-y: scroll;
    height: 400px;
}

.has-form-input-container {
    padding: 20px;
    padding-right: 10px;
    width: 100%;
}
.has-form-input-container + .has-form-input-container {
    padding-left: 0;
}
.has-form-input {
    min-width: 0px;
    width: calc(100% - 40px);
    padding: 15px;
    background: #f7f7f7;
    border-radius: 5px;
    border: 0;
    margin-top: 5px;
    font-size: 18px;

    border-bottom: 5px solid #dadada;
}

.has-form-input + .has-form-input {
    width: calc(100% - 40px);
}

.has-form-multiple {
    display: flex;
}

.PhoneInputCountrySelectArrow {
    visibility: hidden;
}

.errorMessage {
    color: red;
    margin: 0;
    padding: 0;
    margin-left: 20px;
}

.privacyLabel {
    color: #0085ff;
    cursor: pointer;
}

.privacyPolicyContainer {
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 14px;
}

.has-thank-you {
    font-size: 36px;
    font-weight: 100;
    font-family: 'Roboto';
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
}

.has-thank-you-image {
    background-image: url('/assets/landing/covid-art.jpg');
    width: 100%;
    height: 300px;
    background-position: 50% 20%;
    background-size: cover;
}

.PhoneInputInput {
    min-width: 50px;
    width: 50px;
}

.healthSpinner {
    width: 25px;
    height: 25px;
    margin-left: 25px;
    position: relative;
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #999;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 1s infinite ease-in-out;
    animation: sk-bounce 1s infinite ease-in-out;
}

.double-bounce2 {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }
    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

.al {
    text-align: left;
}
.ar {
    text-align: right;
}
