.welcome {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    margin-top: 70px;
    margin-bottom: 70px;
}
.welcome-greeting {
    font-family: 'Playfair Display';
    font-style: italic;
    font-size: 18px;
    color: #758688;
}
.welcome-name {
    font-family: 'Roboto';
    font-size: 18px;
    color: #758688;
    font-weight: 300;
    margin-top: 8px;
}

.guestHomeContainer {
    background-color: #24212d;
    padding-bottom: 20px;
}

.guestHomeButtonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.creditCardContainer {
    margin-top: 80px;
    display: flex;
    justify-content: center;
}

.paymentMethodIcons {
    height: 30px;
}

.guestHomeHeaderImage {
    width: 100%;
    border-radius: 12px;
}

.homeHeaderContainer {
    margin: 20px;
}
