.backButtonLabel {
    font-size: 20px;
    color: #758688;
    cursor: pointer;
}

.imgLogo {
    height: 30px;
    width: 50vw;
    object-fit: contain;
    object-position: 0% 50%;
    cursor: pointer;
}

.imgLogoLg {
    height: 90px;
}

.backButtonContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.backButtonLogoHilton {
    justify-content: center;
}

.guestNameRow {
    margin: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.guestNameLabel {
    font-weight: 400;
    font-size: 18px;
    font-weight: 300;
    color: #758688;
}

.guestNameIcon {
    margin-left: 7px;
    height: 15px;
}

.thinBackButton {
    height: 8px;
    cursor: pointer;
}

.fixedHeader {
    width: 100%;
    position: fixed;
    top: 0px;

    backdrop-filter: blur(25px) saturate(250%);
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 999;
}

.header {
    padding: 17px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dark {
    background-color: #1c1c1e;
    color: #fefefe;
}

.logo {
    font-family: 'Asap';
    font-weight: 700;
    font-size: 29px;
    text-transform: uppercase;
}

.backBtn {
    margin-right: 15px;
    cursor: pointer;
}

.tableCodeBackBtn {
    display: inline-block;
    margin-right: 15px;
    border: 1px solid #b2b2b2;
    border-radius: 11px;
    padding: 8px;
    padding-left: 15px;
    color: #1a1a1a;
    transition: 0.1s;
}

.tableCodeBackBtnDark {
    color: #fefefe;
}

.tableCodeBackBtn:hover {
    opacity: 0.5;
}

.tableCodeText {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    font-family: 'Roboto';
    font-weight: bold;
    font-size: 13px;
    text-decoration: none;
}

.guestNameTextDark {
    color: #fefefe;
}

.guestNameText {
    margin-left: 10px;
    margin-right: 5px;
    font-size: 14px;
    font-weight: 700;
}

.menuSandwichBtn {
    float: right;
    margin: 5px;
}

.restartOrderButton {
    display: absolute;
    top: 0;
    right: 0;
    background-color: #3bad4d;
    padding: 10px;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    border-radius: 100px;
    cursor: pointer;
    transition: 0.5s ease;
}
.restartOrderButton:hover {
    opacity: 0.8;
}
