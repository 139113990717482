.menuHoursButton {
    display: inline-block;
    padding: 15px;
    margin: 15px;
    background-color: #eee;
    border-radius: 100px;
    font-weight: 600;
    cursor: pointer;
}
.menuHoursButton + .menuHoursButton {
    margin-left: 0;
}
.menuHoursRow {
    display: flex;
    flex-wrap: wrap;
}
