.iconStyle {
    font-size: 2rem;
    flex-shrink: 0;
}

.input-container {
    display: flex;
    flex: 1;
    flex-basis: 240px;
    flex-shrink: 0;
    align-items: center;
    margin: 5px 0 15px 0;
}

#card-number {
    font-family: 'Montserrat';
    background-color: transparent;
    border-width: 0px;
    padding: 0.5rem;
    font-size: 0.8rem;
}

#card-expiry {
    font-family: 'Montserrat';
    background-color: transparent;
    border-width: 0px;
    padding: 0.5rem;
    font-size: 0.8rem;
}

#card-cvv {
    font-family: 'Montserrat';
    background-color: transparent;
    border-width: 0px;
    padding: 0.5rem;
    font-size: 0.8rem;
}

@media only screen and (max-width: 710px) {
    #last-row-media {
        margin: 5px 0 15px 0;
    }
    .input-container {
        margin: 10px 0 5px 0;
    }
}

#apple-pay-button {
     margin-top: 1rem;
     align-self: 'center';
     --apple-pay-button-width: 66vw;
     --apple-pay-button-height: 40px;
     --apple-pay-button-border-radius: 5px;
     --apple-pay-button-padding: 0px 0px;
     --apple-pay-button-box-sizing: border-box;
     --apple-pay-button-font-size: 12px;
 }
