.tabletHomeContainer {
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    /* background-image: url('/assets/photos/ghostkitchens/order_here.jpg'); */
    background-size: cover;
    cursor: pointer;
    background-color: #bee9fd;
}

.tabletHomeImage {
    height: 100vh;
    width: 100vw;
}

.tabletHomeActionBar {
    cursor: pointer;
    position: fixed;
    bottom: 0px;
    left: 0;
    height: 100px;
    width: 100vw;
    /* background-color: #fff; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.75);
}

.actionBarLogo {
    height: 60px;
    margin-left: 40px;
}

.touchToOrderLabel {
    margin-right: 40px;
    font-weight: 900;
    font-size: 42px;
    line-height: 93.69%;
    letter-spacing: -0.03em;
    /* identical to box height, or 35px */
    color: rgb(57, 133, 57);
}

.fullscreen-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    z-index: -100;
    background-color: #000;
}

.fullscreen-bg__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 300%;
    top: -100%;
}

@media (min-aspect-ratio: 16/9) {
    .fullscreen-bg__video {
        height: 300%;
        top: -100%;
    }
}

@media (max-aspect-ratio: 16/9) {
    .fullscreen-bg__video {
        width: 320%;
        left: -110%;
    }
}
