.toolBarContainer {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}

.toolBarSearchTextFieldContainer {
    display: flex;
    flex-grow: 10;
    margin-top: 6px;
}

.searchResultContainer {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, 25vw);
    justify-content: center;
    margin-top: 30px;
}

@media only screen and (min-width: 1024px) {
    .searchResultContainer {
        grid-template-columns: repeat(auto-fill, 20vw);
    }
}

@media only screen and (min-width: 1440px) {
    .searchResultContainer {
        grid-template-columns: repeat(auto-fill, 15vw);
    }
}

@media only screen and (min-width: 2560px) {
    .searchResultContainer {
        grid-template-columns: repeat(auto-fill, 10vw);
    }
}
.iconContainer {
    display: flex;
    align-items: center;
}
