.dineSelectHeader {
    width: 100vw;
    background-color: #f8f8f8;
}

.dineSelectHeaderInnerContainer {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dineSelectLeft {
    display: flex;
    align-items: center;
}

.dineSelectUserName {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #1c4055;
    margin-right: 10px;
}

.dineSelectType {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    /* identical to box height */
    color: #727272;
}

.dineSelectEditButton {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    /* identical to box height */
    color: #0885bb;
}
