.campaignItemModifierGroupContainer {
    width: 100%;
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.campaignItemModifierGroupName {
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 20px;
}

.campaignItemModifierRequiredAccent {
    color: red;
}

.campaignItemModifierRadioRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s ease;
}

.campaignItemModifierLabel {
    margin-left: 10px;
    font-family: 'Roboto';
    font-size: 18px;
    font-weight: 400;
}

.campaignItemModifierDescriptionLabel {
    font-size: 15px;
    font-weight: 400;
    margin-top: 5px;
}

.campaignItemSpecialInstructionsTextField {
    width: calc(100% - 20px);
    font-size: 18px;
    font-family: 'Roboto';
    margin-top: 10px;
    border-radius: 5px;
    padding: 10px;
    border: 0;
    background-color: #f7f7f7;
    border-bottom: 5px solid #dadada;
    transition: 0.3s ease;
}

.campaignItemSpecialInstructionsTextField:hover {
    border-bottom: 5px solid #999999;
}

.campaignItemSpecialInstructionsTextField:focus {
    border-bottom: 5px solid #999999;
}

.campaignItemPhoto {
    width: 100%;
    object-fit: cover;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

.campaignItemDetailOverflow {
    margin-left: 10px;
    padding-bottom: 50px;
    height: calc(100vh - 140px);
    overflow: auto;
}

.campaignItemDetailsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.campaignHeaderRow {
    width: 85%;
}

.campaignItemDetailName {
    display: block;
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 22px;
    margin: 0;
    margin: 20px 0 10px 0;
}

.campaignItemDetailPrice {
    font-family: 'Roboto';
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 5px;
}

.campaignItemDetailDescription {
    width: 85%;
    margin-bottom: 10px;
    font-size: 17px;
    font-family: 'Roboto';
    font-weight: 400;
}

.campaignModalButtonContainer {
    width: 100%;
    align-self: center;
    margin-top: 25px;
    display: flex;
    justify-content: center;
}

.campaignItemSpecialInstructionsContainer {
    width: 100%;
    margin-top: 20px;
}

.ModifierGroupsContainer {
    width: 100%;
}

@media only screen and (min-width: 700px) {
    .campaignItemPhoto {
        background-size: contain;
    }

    .campaignItemDetailsContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 500px;
    }
}
