.TwoFAContainer {
    font-family: 'Roboto';
    font-weight: 300;
    padding-top: 20px;
    padding-bottom: 20px;
}

.TwoFALabelContainer {
    text-align: center;
    font-size: 12px;
    padding-bottom: 20px;
}

.TwoFAResendContainer {
    margin-top: 20px;
    text-align: center;
    font-size: 16px;
    text-decoration: underline;
    font-weight: 500;
    color: #0066ff;
    cursor: pointer;
}

.TwoFAResendContainer:active {
    text-decoration: none !important;
}

.TwoFAInputField {
    width: calc(100% - 20px);
    border: 0;
    background-color: #f7f7f7;
    border-bottom: 5px solid #dadada;
    font-family: 'Roboto Mono';
    letter-spacing: 0.5em;
    font-size: 26px;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
}
.TwoFAInputField:hover {
    border-bottom: 5px solid #8d8d8d;
}
.TwoFAInputField:focus {
    border-bottom: 5px solid #8d8d8d;
}

.AuthCodeContainer {
    margin-bottom: 5px;
}

.FailureBorder {
    animation: wiggle 1s 1;
}

.FailureBorder input {
    border-bottom: 5px solid #d9534f !important;
}

@keyframes wiggle {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}

.SuccessBorder input {
    border: 1px solid #28a745 !important;
}
