@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap');
.ItemDescriptionRow {
    padding: 15px 20px 15px 20px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
}
.ItemSubSelectionRow {
    padding: 10px 20px 10px 20px;
    border-bottom: 1px;
    border-color: rgb(226, 226, 226);
    border-bottom-style: solid;
    cursor: pointer;
    transition: 0.1s ease-in;
}
.ItemSubSelectionRow:hover {
    background-color: #fff;
}
.ItemPhotoContainer {
    min-height: 40vh;
    max-height: 40vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(
            132.01deg,
            #ffffff -10.69%,
            rgba(0, 0, 0, 0.52) -10.68%,
            rgba(0, 0, 0, 0) 35.4%,
            rgba(0, 0, 0, 0) 35.4%
        ),
        linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 71.29%,
            rgba(44, 44, 44, 0.51) 86.49%,
            rgba(52, 52, 52, 0.67) 100%
        );
}
.ItemImage {
    object-fit: cover;
    background-repeat: no-repeat;
    z-index: 1;
    /* backdrop-filter: saturate(200%) blur(25px) brightness(90%) contrast(100%); */
}
/* .ItemImageBackgroundColoring {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 40vh;
    max-height: 40vh;
    width: 100vw;
    filter: blur(100px);
} */
.CloseItemButton {
    padding-left: 20px;
    padding-top: 40px;
    color: white;
    z-index: 1;
    cursor: pointer;
}
.ItemHeader {
    font-size: 18;
    font-family: 'Poppins';
    font-weight: 700;
    padding: 20px;
    color: white;
    z-index: 1;
}
.ItemDescription {
    font-size: 13px;
    font-family: 'Poppins';
    font-weight: 400;
}
.ItemPriceTag {
    font-size: 16px;
    font-family: 'Poppins';
    font-weight: 400;
    color: white;
    background-color: #20b04b;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 3px 5px 3px 5px;
    width: 70px;
    margin-bottom: 5px;
}
.JustifiedRowEnds {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.SelectRowHeader {
    font-size: 15px;
    font-family: 'Poppins';
    font-weight: 600;
    color: rgb(36, 36, 36);
}
.SelectRowStatus {
    font-size: 12px;
    font-family: 'Poppins';
    font-weight: 400;
    color: #242424;
}
.ItemQuantityText {
    padding: 0px 10px 0px 10px;
    font-size: 24;
    font-family: 'Roboto';
    font-weight: 700;
    color: rgb(78, 78, 78);
}
.ModifierQuantityText {
    padding: 0px 5px 0px 5px;
    font-size: 24;
    font-family: 'Roboto';
    font-weight: 700;
    color: rgb(78, 78, 78);
}
.ModifierQuantityTextKiosk {
    padding: 0px 5px 0px 5px;
    font-size: 22px;
    font-family: 'Roboto';
    font-weight: 700;
    color: rgb(255, 255, 255);
}
.ModifierQuantityContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 15px;
}
.ModifierQuantityContainerKiosk {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 8px;
    margin-left: 15px;
}
.FabText {
    color: white;
    font-size: 13;
    font-family: 'Poppins';
    font-weight: 700;
    padding: 0px 7px 0px 5px;
}
.FabTextDisabled {
    color: rgb(180, 180, 180);
    font-size: 13;
    font-family: 'Poppins';
    font-weight: 700;
    padding: 0px 7px 0px 5px;
}
.FabContainer {
    position: fixed;
    right: 20px;
    bottom: calc(env(safe-area-inset-bottom) + 120px);
    z-index: 999;
}
.content {
    background-color: #fff;
}

.QuantitySetting {
    margin-bottom: 50px;
}

.InstructionInput {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #e5e5e5;
    border: 1px solid white;
    border-radius: 15px;
    height: 20px;
    padding: 10px 10px;
    font-size: 12px;
}

.InstructionContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 60px;
    margin-bottom: 10px;
}

@media only screen and (min-width: 768px) {
    .ItemHeader {
        font-size: 48px;
    }
    .ItemDescription {
        font-size: 16px;
    }
    .ItemImage {
        object-fit: contain;
        background-color: #ffffff;
    }
    .InstructionInput {
        width: 90%;
        height: 30px;
        border-radius: 20px;
        font-size: 14px;
        padding: 10px 30px;
    }
    .SelectRowHeader {
        font-size: 16px;
    }
}
